<div class="mb-5">
  <div class="inline-block w-full">
    <div class="relative z-[1]">
      <!-- Progress Bar -->
      <div
        class="absolute top-[30px] -z-[1] m-auto h-1 w-[25%] bg-primary transition-[width] ltr:left-0 rtl:right-0"
        [ngClass]="{
          'w-[25%]': activeTab === 1,
          'w-[75%]': activeTab === 2
        }"
      ></div>

      <!-- Step Indicators -->
      <ul class="mb-5 grid grid-cols-2">
        <li class="mx-auto">
          <a
            href="javascript:;"
            class="mx-auto flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
            [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 1 }"
            (click)="prevStep()"
          >
            <!-- Custom Icon for Step 1 -->
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M12 8v4"></path>
              <path d="M12 16h.01"></path>
            </svg>
          </a>
          <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 1 }">{{ "office-branch.guid_section" | translate }}</span>
        </li>

        <li class="mx-auto">
          <a
            href="javascript:;"
            class="mx-auto flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
            [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 2 }"
            (click)="nextStep()"
          >
            <!-- Custom Icon for Step 2 -->
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12l5 5L20 7"></path>
            </svg>
          </a>
          <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 2 }">{{ "office-branch.eturist_information" | translate }}</span>
        </li>
      </ul>
    </div>

    <!-- Step Content -->
    <div>
      <div class="mb-5" *ngIf="activeTab === 1">
        <form [formGroup]="guidForm" (ngSubmit)="validateGUID()">
          <div [ngClass]="guidForm.controls['guid'].touched ? (guidForm.controls['guid'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
            <label for="guid">{{ "office-branch.enter_guid" | translate }}</label>
            <input id="guid" formControlName="guid" class="form-input" placeholder="{{ 'office-branch.enter_guid' | translate }}" />

            <ng-container *ngIf="guidForm.controls['guid'].touched && guidForm.controls['guid'].errors">
              <p class="mt-1 text-danger">
                <small>{{ "office-branch.please_provide_guid" | translate }}</small>
              </p>
            </ng-container>
          </div>

          <div [ngClass]="guidForm.controls['jmbg'].touched ? (guidForm.controls['jmbg'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
            <label for="jmbg">{{ "office-branch.enter_jmbg" | translate }}</label>
            <input id="jmbg" formControlName="jmbg" class="form-input" placeholder="{{ 'office-branch.enter_jmbg' | translate }}" />

            <ng-container *ngIf="guidForm.controls['jmbg'].touched && guidForm.controls['jmbg'].errors">
              <p class="mt-1 text-danger">
                <small>{{ "office-branch.please_provide_jmbg" | translate }}</small>
              </p>
            </ng-container>
          </div>
        </form>
      </div>

      <div class="mb-5" *ngIf="activeTab === 2">
        <form [formGroup]="form" (ngSubmit)="submitForm()">
          <!-- START eTurist Requirement -->
          <div class="flex items-center rounded bg-info-light p-3.5 text-info dark:bg-info-dark-light">
            <span class="text-black ltr:pr-2 rtl:pl-2"><strong class="ltr:mr-1 rtl:ml-1">Info!</strong>{{ "office-branch.order_info_eturist" | translate }}</span>
          </div>

          <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3">
            <div [ngClass]="form.controls['municipality'].touched ? (form.controls['municipality'].errors ? 'has-error' : 'has-success') : ''">
              <label for="municipality">{{ "office-branch.municipality" | translate }}</label>
              <ng-select
                placeholder="{{ 'office-branch.enter_municipality' | translate }}"
                labelForId="municipality"
                [items]="optionsMunicipality$ | async"
                bindLabel="nameLatin"
                formControlName="municipality"
                [searchable]="true"
                [clearable]="true"
                class="custom-multiselect"
              >
              </ng-select>
              <ng-container *ngIf="form.controls['municipality'].touched && form.controls['municipality'].errors">
                <p class="mt-1 text-danger">
                  <small>{{ "office-branch.please_provide_municipality" | translate }}</small>
                </p>
              </ng-container>
            </div>
            <div [ngClass]="form.controls['city'].touched ? (form.controls['city'].errors ? 'has-error' : 'has-success') : ''">
              <label for="city">{{ "office-branch.city" | translate }}</label>
              <ng-select
                placeholder="{{ 'office-branch.enter_city' | translate }}"
                labelForId="city"
                [items]="optionsCities$ | async"
                bindLabel="nameLatin"
                formControlName="city"
                [searchable]="true"
                [clearable]="true"
                class="custom-multiselect"
                [disabled]="!form.controls['municipality'].value"
              >
              </ng-select>
              <ng-container *ngIf="form.controls['city'].touched && form.controls['city'].errors">
                <p class="mt-1 text-danger">
                  <small>{{ "office-branch.please_provide_city" | translate }}</small>
                </p>
              </ng-container>
            </div>
            <div [ngClass]="form.controls['street'].touched ? (form.controls['street'].errors ? 'has-error' : 'has-success') : ''">
              <label for="street">{{ "office-branch.street" | translate }}</label>
              <ng-select
                placeholder="{{ 'office-branch.enter_street' | translate }}"
                labelForId="street"
                [items]="optionsStreets$ | async"
                bindLabel="nameLatin"
                formControlName="street"
                [searchable]="true"
                [clearable]="true"
                class="custom-multiselect"
                [disabled]="!form.controls['city'].value"
              >
              </ng-select>

              <ng-container *ngIf="form.controls['street'].touched && form.controls['street'].errors">
                <p class="mt-1 text-danger">
                  <small>{{ "office-branch.please_provide_street" | translate }}</small>
                </p>
              </ng-container>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- Navigation Buttons -->
    <div class="flex justify-between">
      <button type="button" class="btn btn-primary" [disabled]="activeTab === 1" (click)="prevStep()">{{ "travel_checkout.back" | translate }}</button>
      <button type="button" class="btn btn-primary" [disabled]="activeTab === 2" (click)="nextStep()" [disabled]="isLoading">
        <ng-container *ngIf="isLoading; else alternateTranslation">
          <span class="inline-block h-3 w-3 animate-ping rounded-full bg-white ltr:mr-4 rtl:ml-4"></span>
          {{ "loading" | translate }}
        </ng-container>
        <ng-template #alternateTranslation>
          <span *ngIf="activeTab === 1">{{ "travel_checkout.next" | translate }}</span>
          <span *ngIf="activeTab === 2">{{ "office-branch.finish" | translate }}</span>
        </ng-template>
      </button>
    </div>
  </div>
</div>
