import { showMessage } from "../../utils/toast.popup";
import { PopupType } from "../../enums/popup-types";
import { TranslateService } from "@ngx-translate/core";
import { NgSelectComponent } from "@ng-select/ng-select";
import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core";

@Component({
  selector: "app-merge",
  templateUrl: "./merge.component.html",
})
export class MergeComponent<T> {
  @Input() items: T[] = [];
  @Input() selectedItemId!: number;
  @Output() merge = new EventEmitter<number[]>();
  @Input() bindLabel!: string;

  @ViewChild("select") select!: NgSelectComponent;

  selectedItems: T[] = [];
  isLoading = false;
  maxSelection = 20; // Maximum number of items that can be selected

  constructor(public translate: TranslateService) {}

  // Method to trigger merge action
  onMerge() {
    const selectedIds = this.selectedItems.map((item) => (item as any).id);
    if (selectedIds.length > 0 && this.selectedItemId) {
      this.isLoading = true;
      this.merge.emit(selectedIds); // Emit selected items for merging
      this.isLoading = false;
    } else {
      showMessage(PopupType.Danger, this.translate.instant("selectItems"));
    }
  }

  // Track function for performance optimization in *ngFor
  trackByFn(index: number, item: any): any {
    return item ? item.id || index : index;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.select?.focus();
    });
  }

  // Clear the selected items
  clearSelection(): void {
    this.selectedItems = [];
  }
  clearSearchInput(): void {
    if (this.select) {
      this.select.searchTerm = '';
    }
  }
}
