<div class="panel pb-0">
  <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
    <h5 class="text-lg font-semibold dark:text-white-light"></h5>
    <div class="flex items-center gap-5 ltr:ml-auto rtl:mr-auto">
      <div hlMenu class="dropdown">
        <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
          <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
          <icon-caret-down class="h-5 w-5" />
        </button>
        <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
          <ng-container *ngFor="let col of cols; index as i">
            <li>
              <div class="flex items-center px-4 py-1">
                <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                  <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                  <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                </label>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
      <div>
        <app-search-input (searchChanged)="onSearchChange($event)"></app-search-input>
      </div>
    </div>
  </div>
  <div class="datatable">
    <ng-datatable
      [rows]="filteredSales"
      [stickyHeader]="true"
      [height]="'670px'"
      [columns]="cols"
      [sortable]="true"
      [isServerMode]="true"
      [sortDirection]="'desc'"
      [loading]="loading"
      [pageSize]="pageSize"
      [page]="pageNumber"
      [totalRows]="totalRows"
      (changeServer)="changeServer($event)"
      skin="whitespace-nowrap table-hover"
      [noDataContent]="'empty_tables' | translate"
      [showNumbersCount]="3"
    >
      <ng-template slot="travelStartDate" let-value="data">
        <span>{{ value.travelStartDate | date : "dd.MM.yyyy" }}</span>
      </ng-template>
      <ng-template slot="actions" let-value="data">
        <div class="dropdown inline-flex">
          <button
            type="button"
            [ngxTippy]="tooltipTemplate"
            [tippyContext]="{ data: value }"
            [tippyProps]="{ placement: 'right', interactive: true }"
            class="btn btn-outline-secondary rounded-circle"
            [routerLink]="'/dashboard/travels/' + travelId"
            (mouseenter)="onMouseEnter(value.uniqueIdentifier)"
          >
            <div class="flex h-6 w-6 items-center gap-2">
              <icon-menu-travel></icon-menu-travel>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template #tooltipTemplate let-data="data">
        <ng-container *ngIf="currentId === data.uniqueIdentifier">
          <app-travel-quick-view-by-eturist [eTuristRecordId]="data.uniqueIdentifier"></app-travel-quick-view-by-eturist>
        </ng-container>
      </ng-template>
    </ng-datatable>
  </div>
</div>
<!-- 80870 -->
