<div class="relative">
  <div class="absolute inset-0">
    <img src="/assets/images/auth/Background-Login.png" alt="image" class="fixed inset-0 h-full w-full object-cover" />
    <div class="fixed inset-0 bg-black opacity-10 dark:opacity-10"></div>
    <div class="bg-white"></div>
  </div>

  <div class="absolute end-4 top-4 z-10">
    <div class="dropdown z-10">
      <div hlMenu>
        <button type="button" hlMenuButton class="flex items-center gap-2.5 rounded-lg border border-white-dark/30 bg-white px-2 py-1.5 text-white-dark hover:border-primary hover:text-primary dark:bg-black">
          <div>
            <img [src]="'/assets/images/flags/' + (locale$ | async)?.toUpperCase() + '.svg'" alt="image" class="h-5 w-5 rounded-full object-cover" />
          </div>
          <div class="text-base font-bold uppercase">{{ (locale$ | async)?.toUpperCase() }}</div>
          <span class="shrink-0">
            <icon-caret-down />
          </span>
        </button>
        <ul
          *hlMenuItems
          @toggleAnimation
          class="top-11 grid w-[280px] grid-cols-2 gap-2 !px-2 font-semibold text-dark dark:text-white-dark dark:text-white-light/90 ltr:-right-14 sm:ltr:-right-2 rtl:-left-14 sm:rtl:-left-2"
          style="right: 0; padding-right: 1rem; @media (min-width: 640px) {right: auto; padding-right: 0}"
        >
          <li *ngFor="let item of languageList$ | async">
            <button *hlMenuItem="let menuItem" type="button" class="w-full hover:text-primary" [ngClass]="{ 'bg-primary/10 text-primary': translate.currentLang === item.code }" (click)="changeLanguage(item)">
              <img class="h-5 w-5 rounded-full object-cover" [src]="'/assets/images/flags/' + item.code.toUpperCase() + '.svg'" alt="" />
              <span class="ltr:ml-3 rtl:mr-3">{{ item.name }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="relative flex min-h-screen flex-col items-center justify-center px-6 py-10 sm:px-16">
    <!-- <div class="mt-10 max-w-[1000px] rounded-[2rem] bg-white p-5 pt-10">
      <div class="flex max-w-[1000px] flex-col items-center justify-center gap-3"> -->
    <div class="space-y-8">
      <div class="panel rounded-[2rem] p-0 py-5 pt-10">
        <div class="mb-5 w-full">
          <div class="inline-block w-full">
            <div class="relative z-[1]">
              <!-- Progress Bar -->
              <div
                class="absolute top-[30px] -z-[1] h-1 bg-primary transition-all"
                [ngClass]="{
                  'w-[10%]': activeTab === 1,
                  'w-[35%]': activeTab === 2,
                  'w-[65%]': activeTab === 3,
                  'w-[100%]': activeTab === 4 || activeTab === 5,
                }"
              ></div>

              <!-- Step Indicators -->
              <ul class="mb-5 grid grid-cols-4">
                <li *ngFor="let step of [1, 2, 3, 4]" class="mx-auto">
                  <a
                    href="javascript:;"
                    class="mx-auto flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-gray-300 bg-white"
                    [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === step || activeTab === 5 }"
                    (click)="makeStep(step)"
                  >
                    <!-- Your SVG Icons -->
                    <icon-person *ngIf="step === 1"></icon-person>
                    <icon-home *ngIf="step === 2"></icon-home>
                    <icon-mail *ngIf="step === 3"></icon-mail>
                    <icon-thumbs-up *ngIf="step === 4"></icon-thumbs-up>
                  </a>
                  <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === step }">
                    {{
                      step === 1
                        ? ("profile" | translate)
                        : step === 2
                        ? ("login_wizard.second_step_title" | translate)
                        : step === 3
                        ? ("login_wizard.communication" | translate)
                        : ("login_wizard.e_turist_label" | translate)
                    }}
                  </span>
                </li>
              </ul>
            </div>

            <!-- Content for Each Step -->
            <div class="ml-auto mr-auto w-[95%] lg:w-[1000px]">
              <div [hidden]="activeTab !== 0" class="mb-5">
                <div class="flex flex-col justify-center p-8">
                  <h2 class="flex justify-center text-xl font-bold">{{ "login_wizard.welcome_title" | translate }}</h2>
                  <p class="flex justify-center text-center">{{ "login_wizard.welcome_message" | translate }}</p>
                </div>
              </div>
              <div [hidden]="activeTab !== 1" class="mb-5"><app-manager-info-form [office]="office" (officeChange)="updateOffice($event)"></app-manager-info-form></div>
              <div [hidden]="activeTab !== 2" class="mb-5"><app-office-branch-info-form [office]="office" (officeChange)="updateOffice($event)"></app-office-branch-info-form></div>
              <div [hidden]="activeTab !== 3" class="mb-5"><app-office-branch-mail-form [office]="office" (officeChange)="updateOffice($event)"></app-office-branch-mail-form></div>
              <div [hidden]="activeTab !== 4" class="mb-5"><app-office-branch-eturist-info-form [office]="office"></app-office-branch-eturist-info-form></div>
              <div [hidden]="activeTab !== 5" class="mb-5">
                <div class="flex flex-col justify-center p-8">
                  <h2 class="flex justify-center text-xl font-bold">{{ "login_wizard.finish_title" | translate }}</h2>
                  <p class="flex justify-center text-center">{{ "login_wizard.finish_message_1" | translate }}</p>
                  <p class="flex justify-center text-center">{{ "login_wizard.finish_message_2" | translate }}</p>
                  <p class="flex justify-center text-center font-bold">{{ "login_wizard.logout_message" | translate }}</p>
                </div>
              </div>
            </div>

            <!-- Navigation Buttons -->
            <div class="flex pl-8 pr-8" [ngClass]="activeTab === 0 ? 'justify-end' : 'justify-between'">
              <div [hidden]="activeTab === 0">
                <button type="button" class="btn btn-primary" [disabled]="activeTab === 1" (click)="prevStep()">{{ "login_wizard.back" | translate }}</button>
              </div>
              <div class="flex">
                <button *ngIf="activeTab === 3 || activeTab === 4" class="btn btn-primary mr-2" (click)="skipStep(activeTab + 1)">{{ "login_wizard.skip" | translate }}</button>
                <button type="button" class="btn btn-primary" (click)="makeStep(activeTab + 1)" [disabled]="activeTab === 6 || isLoading">
                  <ng-container *ngIf="isLoading; else alternateTranslation">
                    <span class="inline-block h-3 w-3 animate-ping rounded-full bg-white ltr:mr-4 rtl:ml-4"></span>
                    {{ "loading" | translate }}
                  </ng-container>
                  <ng-template #alternateTranslation>
                    <span *ngIf="activeTab === 0">{{ "login_wizard.start" | translate }}</span>
                    <span *ngIf="activeTab !== 5 && activeTab !== 0">{{ "login_wizard.next" | translate }}</span>
                    <span *ngIf="activeTab === 5" (click)="logout()">{{ "login_wizard.finish" | translate }}</span>
                  </ng-template>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
