import { Component, OnInit } from "@angular/core";
import { Invoice } from "../../invoice";
import { Customer } from "src/app/domain/customers/models/customer";
import { ActivatedRoute, Router } from "@angular/router";
import { InvoiceService } from "../../../services/invoice.service";
import * as moment from "moment";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";

@Component({
  selector: "app-invoice-document-edit",
  templateUrl: "./invoice-document-edit.component.html",
})
export class InvoiceDocumentEditComponent implements OnInit {
  public invoice!: Invoice;
  public passangers!: Customer[];
  invoiceId!: number;
  travelId!: number;
  cancelEditUrl!: string;

  constructor(public route: ActivatedRoute, public invoiceService: InvoiceService, public router: Router) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.invoiceId = parseInt(params["invoiceId"]);
      this.travelId = parseInt(params["id"]);
      this.cancelEditUrl = `/dashboard/travels/${this.travelId}/invoice`;
      this.fetchInvoiceDate();
    });
  }

  fetchInvoiceDate() {
    this.invoiceService.getTravelInvoice(this.travelId).subscribe(
      (response) => {
        this.invoice = response;
        this.passangers = response.passengers.map((passenger: any) => {
          passenger.fullName = passenger.name + " " + passenger.surname;
          passenger.dateOfBirth = moment(passenger.dateOfBirth).format("DD.MM.YYYY");
          return passenger;
        });
      },
      (error) => {
        showMessage(PopupType.Danger, error.toString());
      }
    );
  }
}
