import * as moment from "moment";
import { catchError, tap } from "rxjs/operators";
import { CustomersService } from "../services/customers.service";
import { UtilService } from "src/app/shared/services/util.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Customer } from "src/app/domain/customers/models/customer";
import { EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { dateFormatValidator } from "src/app/shared/validators/date.validator";
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, AfterViewInit } from "@angular/core";

@Component({
  selector: "app-customer-create-edit",
  templateUrl: "./customer-create-edit.component.html",
})
export class CustomerCreateEditComponent implements OnInit, AfterViewInit {
  @Input() entityId!: number;
  @Input() destination: Customer = {} as Customer;
  @Input() isLoading!: boolean;
  @Input() editMode!: boolean;
  @Output() formSubmit = new EventEmitter<any>();
  form!: FormGroup;
  EntityPicklistType = EntityPicklistType;
  @ViewChild("nameInput") nameInput!: ElementRef;

  constructor(private fb: FormBuilder, public utilService: UtilService, public customersService: CustomersService) {}

  ngOnInit(): void {
    this.initForm();
    if (this.entityId) {
      this.loadCustomerDetails();
    }
  }

  ngAfterViewInit(): void {
		if (!this.entityId) {
			setTimeout(() => {
				if (this.nameInput) {
					this.nameInput.nativeElement.focus();
					this.nameInput.nativeElement.select();
				}
			});
		}
	}

  closeModal(): void {
    this.formSubmit.emit(null);
  }

  initForm(customer: Customer = {} as Customer): void {
    const dateOfBirth = customer.dateOfBirth ? moment(customer.dateOfBirth).format("DD.MM.YYYY") : "";
    this.form = this.fb.group({
      name: [customer.name || "", Validators.required],
      surname: [customer.surname || "", Validators.required],
      dateOfBirth: [dateOfBirth, dateFormatValidator()],
      phoneNumber: [customer.phoneNumber || ""],
      imbg: [customer.imbg || ""],
      address: [customer.address || ""],
      profession: [customer.profession || ""],
      passport: [customer.passport || ""],
      email: [customer.email || "", [Validators.email]],
    });
  }

	loadCustomerDetails(): void {
		this.customersService
			.getCustomerDetails(this.entityId)
			.pipe(
				tap((customer) => {
					this.initForm(customer);
					setTimeout(() => {
						if (this.nameInput) {
							const input = this.nameInput.nativeElement;
							input.focus();
							const length = input.value.length;
							input.setSelectionRange(length, length);
						}
					});
				}),
				catchError((err) => {
					console.error("Error loading customer data:", err);
					return [];
				})
			)
			.subscribe();
	}
	
  submitForm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const formData = this.form.value;
    if (formData.dateOfBirth) {
      formData.dateOfBirth = moment(formData.dateOfBirth, "DD.MM.YYYY").format("YYYY-MM-DD");
    }
    this.formSubmit.emit(formData);
  }
}
