<form [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="form">
  <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
    <div class="col-span-2 flex grid grid-cols-2 rounded-lg p-4 sm:col-span-4 sm:grid-cols-4">
      <div class="col-span-4 flex flex-col pb-4 sm:col-span-4">
        <div class="flex justify-center text-3xl font-bold">
          {{ "login_wizard.second_form_title" | translate }}
        </div>

        <small class="flex justify-center py-5 text-center text-base">{{ "login_wizard.second_step_subtext" | translate }}</small>
      </div>

      <div class="col-span-4 flex pr-0 sm:col-span-4 sm:pr-4">
        <div class="custom-file-container w-full" *ngIf="existingLogo; else uploadNewImage">
          <div class="image-wrapper m-auto w-1/3 rounded bg-gray-200 p-2">
            <img [src]="existingLogo.url" alt="" class="h-auto max-h-64 w-full rounded object-contain" />
          </div>
          <div class="button-wrapper m-auto flex justify-center py-2">
            <button class="btn btn-danger" type="button" (click)="removeImage($event)">{{ "login_wizard.remove_image" | translate }}</button>
          </div>
        </div>
        <ng-template #uploadNewImage>
          <div class="custom-file-container w-full rounded bg-gray-100" data-upload-id="office-branch-logo" (click)="uploadClick($event)"></div>
        </ng-template>
      </div>

      <div class="col-span-4 grid grid-cols-1 gap-5 p-4 pb-0 sm:col-span-4 sm:grid-cols-2 sm:pb-4">
        <div [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''">
          <label for="bname">{{ "login_wizard.name" | translate }}</label>
          <input type="text" placeholder="{{ 'login_wizard.enter_name' | translate }}" class="form-input" id="bname" formControlName="name" />
          <ng-container *ngIf="form.controls['name'].touched && form.controls['name'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "login_wizard.please_provide_name" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div [ngClass]="form.controls['bankAccount'].touched ? (form.controls['bankAccount'].errors ? 'has-error' : 'has-success') : ''">
          <label for="bankAccount">{{ "office-branch.bankAccount" | translate }}</label>
          <input type="text" placeholder="{{ 'office-branch.enter_bankAccount' | translate }}" class="form-input" id="bankAccount" formControlName="bankAccount" />
          <ng-container *ngIf="form.controls['bankAccount'].touched && form.controls['bankAccount'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "office-branch.please_provide_bankAccount" | translate }}</small>
            </p>
          </ng-container>
        </div>
      </div>

      <div class="col-span-4 grid grid-cols-1 gap-5 p-4 pb-0 sm:col-span-4 sm:grid-cols-3 sm:pb-4">
        <div [ngClass]="form.controls['PIB'].touched ? (form.controls['PIB'].errors ? 'has-error' : 'has-success') : ''">
          <label for="PIB">{{ "office-branch.PIB" | translate }}</label>
          <input type="text" placeholder="{{ 'office-branch.enter_PIB' | translate }}" class="form-input" id="PIB" formControlName="PIB" />
          <ng-container *ngIf="form.controls['PIB'].touched && form.controls['PIB'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "office-branch.please_provide_PIB" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div [ngClass]="form.controls['MB'].touched ? (form.controls['MB'].errors ? 'has-error' : 'has-success') : ''">
          <label for="MB">{{ "office-branch.MB" | translate }}</label>
          <input type="text" placeholder="{{ 'office-branch.enter_MB' | translate }}" class="form-input" id="MB" formControlName="MB" />
          <ng-container *ngIf="form.controls['MB'].touched && form.controls['MB'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "office-branch.please_provide_MB" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div [ngClass]="form.controls['fullAddress'].touched ? (form.controls['fullAddress'].errors ? 'has-error' : 'has-success') : ''">
          <label for="full_address">{{ "office-branch.full_address" | translate }}</label>
          <input type="text" placeholder="{{ 'office-branch.enter_full_address' | translate }}" class="form-input" id="full_address" formControlName="fullAddress" />
          <ng-container *ngIf="form.controls['fullAddress'].touched && form.controls['fullAddress'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "office-branch.please_provide_full_address" | translate }}</small>
            </p>
          </ng-container>
        </div>
      </div>

      <div class="col-span-4 grid grid-cols-1 gap-5 p-4 pb-0 sm:col-span-4 sm:grid-cols-2 sm:pb-4">
        <div [ngClass]="form.controls['licence'].touched ? (form.controls['licence'].errors ? 'has-error' : 'has-success') : ''">
          <label for="licence">{{ "office-branch.licence" | translate }}</label>
          <input type="text" placeholder="{{ 'office-branch.enter_licence' | translate }}" class="form-input" id="licence" formControlName="licence" />
          <ng-container *ngIf="form.controls['licence'].touched && form.controls['licence'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "office-branch.please_provide_licence" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div [ngClass]="form.controls['siteUrl'].touched ? (form.controls['siteUrl'].errors ? 'has-error' : 'has-success') : ''">
          <label for="siteUrl">{{ "office-branch.siteUrl" | translate }}</label>
          <div class="flex">
            <div
              class="flex items-center justify-center border border-[#e0e6ed] bg-[#eee] px-3 font-semibold dark:border-[#17263c] dark:bg-[#1b2e4b] ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md rtl:border-l-0"
            >
              https://www.
            </div>
            <input type="text" placeholder="{{ 'office-branch.enter_siteUrl' | translate }}" class="form-input ltr:rounded-l-none rtl:rounded-r-none" id="siteUrl" formControlName="siteUrl" />
          </div>
          <ng-container *ngIf="form.controls['siteUrl'].touched && form.controls['siteUrl'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "office-branch.please_provide_siteUrl" | translate }}</small>
            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</form>
