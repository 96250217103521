<div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
  <app-details-dropdown [id]="planid"></app-details-dropdown>
  <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"></app-file-history>
  <button type="button" class="btn btn-primary gap-2" *ngIf="otherService">
    <a class="flex items-center justify-center" href="javascript:;" [routerLink]="'/dashboard/other-services/edit/' + otherService.id" [queryParams]="{ returnId: id }">
      <icon-pencil-paper class="h-4.5 w-4.5 shrink-0" />
    </a>
  </button>
</div>

<div *ngIf="otherService">
  <div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
      <div>
        <div class="col-span-2 flex flex-col sm:col-span-1">
          <div class="text-white-dark">
            {{ "otherservices.name" | translate }}
          </div>
          <div class="text-xl">{{ otherService.customerFullName }}</div>
        </div>
      </div>

      <div class="col-span-2 flex sm:col-span-3">
        <div class="flex place-content-center items-center self-center">
          <div class="ltr:mr-2 rtl:ml-2">{{ "otherservices.active" | translate }}:</div>
          <ng-container *ngIf="otherService.active !== undefined && otherService.active !== null; else notActive">
            <div class="badge bg-success">
              {{ "otherservices.active" | translate }}
            </div>
          </ng-container>
          <ng-template #notActive>
            <div class="badge bg-slate-200 text-white">
              {{ "otherservices.deleted" | translate }}
            </div>
          </ng-template>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.email" | translate }} :</div>
          <div>{{ otherService.email }}</div>
        </div>
      </div>
      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.date_of_birth" | translate }} :</div>
          <div>{{ otherService.dateOfBirth | date : "dd.MM.yyyy." }}</div>
        </div>
      </div>
      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.phone_number" | translate }} :</div>
          <div>{{ otherService.phoneNumber }}</div>
        </div>
      </div>
      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.address" | translate }} :</div>
          <div>{{ otherService.address }}</div>
        </div>
      </div>
      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.profession" | translate }} :</div>
          <div>{{ otherService.profession }}</div>
        </div>
      </div>
      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.service_title" | translate }} :</div>
          <div>{{ otherService.serviceName }}</div>
        </div>
      </div>
      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.created_at" | translate }}:</div>
          <div>{{ otherService.createdAt | date : "dd.MM.yyyy." }}</div>
        </div>
      </div>

      <div class="col-span-1 sm:col-span-1">
        <div class="flex-col">
          <div>{{ "otherservices.updated_at" | translate }}:</div>
          <div>{{ otherService.updatedAt | date : "dd.MM.yyyy." }}</div>
        </div>
      </div>
      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "paymentssendemail.payments" | translate }}
          </div>
        </div>
        <app-sale-details
          class="col-span-2 sm:col-span-4"
          [id]="planid"
          [hideStatus]="true"
          (reFetchEvent)="refetch()"
          [entityDetails]="entityDetails"
          [navigateToRoute]="'/dashboard/other-services/' + otherService.id"
        ></app-sale-details>
      </div>
    </div>
    <br />
    <div class="small text-secondary">{{ otherService.plan.createdBy.name }} {{ otherService.plan.createdBy.surname }}, {{ otherService.plan.createdAt | date : "dd.MM.yyyy." }}</div>
  </div>
</div>
