<form [formGroup]="form" (ngSubmit)="save()">
  <div class="relative mb-4" [ngClass]="form.controls['jIDNumber'].touched ? (form.controls['jIDNumber'].errors ? 'has-error' : 'has-success') : ''">
    <label for="confirmationNumber">{{ "account_data.search_eturist" | translate }}:</label>
    <ng-select
      #confirmationNumberSelect
      placeholder="{{ 'account_data.search_by_confirmation_number' | translate }}"
      labelForId="confirmationNumber"
      [items]="travelPlansSubscriber$ | async"
      bindLabel="uniqueIdentifier"
      formControlName="jIDNumber"
      [clearable]="true"
      class="custom-multiselect"
      [searchFn]="customSearch"
    >
      <ng-template ng-label-tmp let-item="item"> {{ item.uniqueIdentifier }} - {{ item.name }} </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div style="margin: 10px 0" class="card">
          <div class="card-body">
            <h5 class="card-title">{{ item.uniqueIdentifier }} - {{ item.name }}</h5>
            <h6 class="card-subtitle text-muted mb-2 text-wrap">{{ item.travelAgencyName }} - {{ item.travelAgencyIdentificationNumber }}</h6>
          </div>
        </div>
      </ng-template>
    </ng-select>
    <ng-container *ngIf="form.controls['jIDNumber'].touched && form.controls['jIDNumber'].errors">
      <small class="mt-1 text-danger">{{ "account_data.please_provide_confirmation_number" | translate }}</small>
    </ng-container>
  </div>
  <div class="relative mb-4" [ngClass]="form.controls['currencyTypeId'].touched ? (form.controls['currencyTypeId'].errors ? 'has-error' : 'has-success') : ''">
    <label for="currency">{{ "account_data.currency" | translate }}</label>
    <ng-select
      placeholder="{{ 'account_data.please_provide_currecy' | translate }}"
      labelForId="currency"
      [items]="currencySubscriber$ | async"
      bindLabel="name"
      formControlName="currencyTypeId"
      [clearable]="true"
      (change)="currencySelect($event)"
      class="custom-multiselect"
    >
    </ng-select>
  </div>
  <div class="relative mb-4" [ngClass]="form.controls['amount'].touched ? (form.controls['amount'].errors ? 'has-error' : 'has-success') : ''">
    <label for="sumInRSD">{{ "account_data.sum" | translate }} {{ currency }}:</label>
    <div class="flex">
      <div class="flex items-center justify-center border border-[#e0e6ed] bg-[#eee] px-3 font-semibold dark:border-[#17263c] dark:bg-[#1b2e4b] ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md rtl:border-l-0">
        {{ symbol }}
      </div>
      <input
        type="text"
        placeholder="{{ 'account_data.sum_in_rsd' | translate }}"
        mask="separator.3"
        [outputTransformFn]="toFixed"
        class="form-input ltr:rounded-l-none rtl:pr-10"
        id="sumInRSD"
        formControlName="amount"
      />
    </div>
    <small *ngIf="currency !== 'EUR'" class="mt-1 text-orange-500">
      {{
        "account_data.explanation_sum_rsd"
          | translate
            : {
                bruto: this.bruto,
                exchangeRate: (nbsExchangeRate | number : "1.3-3"),
                sumInRsd: (amount | number : "1.3-3"),
                currency: currency
              }
      }}
    </small>
    <ng-container *ngIf="form.controls['amount'].touched && form.controls['amount'].errors">
      <small class="mt-1 text-danger">{{ "account_data.please_provide_sum_in_rsd" | translate }}</small>
    </ng-container>
  </div>
  <div class="relative mb-4" [ngClass]="form.controls['transportTypeId'].touched ? (form.controls['transportTypeId'].errors ? 'has-error' : 'has-success') : ''">
    <label for="transportation">{{ "account_data.transport" | translate }}</label>
    <ng-select
      placeholder="{{ 'account_data.please_provide_transport' | translate }}"
      labelForId="transportation"
      [items]="transportSubscriber$ | async"
      bindLabel="name"
      formControlName="transportTypeId"
      [clearable]="true"
      class="custom-multiselect"
    >
    </ng-select>
  </div>
  <div class="relative mb-4" [ngClass]="form.controls['accomodationTypeId'].touched ? (form.controls['accomodationTypeId'].errors ? 'has-error' : 'has-success') : ''">
    <label for="accomodation">{{ "account_data.accomodation" | translate }}</label>
    <ng-select
      placeholder="{{ 'account_data.please_provide_accomodation' | translate }}"
      labelForId="accomodation"
      [items]="accomodationSubscriber$ | async"
      bindLabel="name"
      formControlName="accomodationTypeId"
      [clearable]="true"
      class="custom-multiselect"
      appendTo="body"
    >
    </ng-select>
  </div>
  <div class="relative mb-4">
    <label for="avans">{{ "travel_create.organizerConfirmationNumber" | translate }}</label>
    <div class="flex">
      <input
        id="organizerConfirmationNumber"
        type="text"
        placeholder="{{ 'travel_create.organizerConfirmationNumber_placeholder' | translate }}"
        class="form-input"
        formControlName="organizerConfirmationNumber"
        [appLimitCharacterInput]="60"
      />
    </div>
  </div>
  <button type="submit" class="btn btn-primary w-full" [disabled]="loading">
    <ng-container *ngIf="!loading; else loadingTemplate">{{ "account_data.save" | translate }} </ng-container>
    <ng-template #loadingTemplate>
      <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle ltr:mr-4 rtl:ml-4"></span>{{ "loading" | translate }}
    </ng-template>
  </button>
</form>
