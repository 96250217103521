import { Store } from "@ngrx/store";
import { Observable, take, tap } from "rxjs";
import { User } from "src/app/auth/model/user";
import { Office } from "../../models/office-branch";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { PopupType } from "src/app/shared/enums/popup-types";
import { updateUser } from "src/app/store/auth/auth.actions";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { selectFeatureUser } from "src/app/store/auth/auth.selector";
import { slideDownUp, toggleAnimation } from "src/app/shared/animations";
import { UserService } from "src/app/domain/user-profile/service/user.service";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { OfficeBranchService } from "../../services/office-branch.service";

@Component({
  selector: "app-manager-info-form",
  templateUrl: "./manager-info-form.component.html",
  animations: [toggleAnimation, slideDownUp],
})
export class ManagerInfoFormComponent implements OnInit, OnChanges {
  user!: User;
  public form!: FormGroup;
  @Input() office?: Office;
  isLoading: boolean = false;
  user$!: Observable<User | undefined>;
  @Output() officeChange = new EventEmitter<Office>();
  private hasInitialized = false;

  constructor(private userService: UserService, public translate: TranslateService, public fb: FormBuilder, public store: Store<AppState>, public officeBranchService: OfficeBranchService) {}

  ngOnInit(): void {
    this.user$ = this.store.select(selectFeatureUser);
    this.user$
      .pipe(
        take(1),
        tap((res) => {
          if (res) this.user = res;
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasInitialized && changes["office"] && this.office) {
      this.initForm(this.user, this.office);
      this.hasInitialized = true;
    }
  }

  initForm(user: User, office: Office): void {
    const { name, surname } = user;
    const { email, phoneNumber } = office;
    this.form = this.fb.group({
      name: [name, Validators.required],
      lastname: [surname, Validators.required],
      email: [email, Validators.required],
      phone: [phoneNumber, Validators.required],
    });
  }

  async submitForm(): Promise<boolean> {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    const userData = {
      name: this.form.value.name,
      surname: this.form.value.lastname,
      email: this.user.email,
      username: this.user.username,
      uniqueMasterCitizenNumber: this.user.uniqueMasterCitizenNumber,
    };

    return new Promise((resolve) => {
      const userSubscription = this.userService.updateUser(this.user.id, userData).subscribe({
        next: (res) => {
          userSubscription.unsubscribe();
          const data = {
            name: res.name,
            surname: res.surname,
            settings: res.settings,
            uniqueMasterCitizenNumber: res.uniqueMasterCitizenNumber,
          };
          this.store.dispatch(updateUser({ user: data }));
          if ((this.form.value.email != this.office?.email || this.form.value.phone != this.office?.phoneNumber) && this.office) {
            const updatedOffice = this.getOffice();
            const officeSubscription = this.officeBranchService.updateOffice(this.office.id, this.getOffice()).subscribe({
              next: () => {
                officeSubscription.unsubscribe();
                resolve(true);
                this.initForm(res, updatedOffice);
                this.officeChange.emit(this.getOffice());
              },
              error: (error) => {
                officeSubscription.unsubscribe();
                showMessage(PopupType.Danger, error.toString());
                resolve(false);
              },
            });
          } else {
            resolve(true);
            this.office = this.getOffice();
            this.officeChange.emit(this.getOffice());
          }
        },
        error: (error) => {
          userSubscription.unsubscribe();
          showMessage(PopupType.Danger, error.toString());
          resolve(false);
        },
      });
    });
  }

  getOffice() {
    return {
      ...this.office,
      email: this.form.value.email,
      phoneNumber: this.form.value.phone,
    } as Office;
  }
}
