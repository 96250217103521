import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Contract } from "../models/contract";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";

@Injectable({
  providedIn: "root",
})
export class ContractService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getTravelContract(id: number): Observable<Contract> {
    return this.http.get<Contract>(`${this.apiUrl}/api/contract/Travel/${id}`);
  }

  updateTravelContract(id: number, travelId: number): (data: any) => Observable<Contract> {
    return (data: any) => {
      return this.http.put<Contract>(`${this.apiUrl}/api/contract/${id}/${travelId}`, data);
    };
  }

  getContractByGroupTravel(groupTravelPlanId: number): Observable<any> {
    const params = new HttpParams().set("groupTravelPlanId", groupTravelPlanId.toString());
    return this.http.get<any>(`${this.apiUrl}/api/contract/GroupTravelContract`, { params });
  }

  updateGroupTravelContract(id: number): (data: any) => Observable<Contract> {
    return (data: any) => {
      return this.http.put<Contract>(`${this.apiUrl}/api/contract/contract/${id}`, data);
    };
  }

  sendContract(id: number): (data: FormData) => Observable<any> {
    return (data: FormData) => {
      data.append("ContractId", id.toString());
      return this.http.post<Contract>(`${this.apiUrl}/api/Contract/send`, data);
    };
  }
  generateContractHtml(id: number): Observable<string> {
    return this.http.get(`${this.apiUrl}/api/Contract/${id}/generate`, { responseType: "text" });
  }
}
