import * as moment from "moment";
import { Subscription } from "rxjs";
import { Transfer } from "../models/transfer";
import { Sale } from "../../sales/models/sale";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Customer } from "../../customers/models/customer";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { TabVisibilityUtil } from "src/app/shared/tab-visibility.util";
import { TransfersService } from "../services/transfers.service.service";
import { ValidationService } from "src/app/shared/services/validation.service";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { SaleCreateComponent } from "../../sales/sale.create/sale.create.component";
import { PassangersListComponent } from "../../passangers/passangers.list/passangers.list.component";
import { LocalStorageService } from "src/app/service/local-storage.service";

@Component({
  selector: "app-transfer-plan",
  templateUrl: "./transfer-plan.component.html",
})
export class TransferPlanComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("sale") sales!: SaleCreateComponent;
  @ViewChild("firstCheckbox") firstCheckbox!: ElementRef;
  @ViewChild("nextStepButton") nextStepButton!: ElementRef;
  activeTab = 1;
  passengers!: Customer[];
  sale!: Sale;
  transferId!: number | undefined;
  entityId!: number[] | undefined;
  transfer!: Transfer;
  isForwardDirection!: boolean;
  private routeSubscriber!: Subscription;
  private customer!: Customer;
  public isEditingPassanger = false;
  isLoading = false;
  isFromToSelected = true;
  isToFromSelected = false;
  hasTransferData = false;
	tooltipMessage!: string;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private transferService: TransfersService,
    private router: Router,
    private validationService: ValidationService,
    private cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.routeSubscriber = this.route.paramMap.subscribe((params) => {
      const id = params.get("id");
      this.transferId = id ? parseInt(id) : undefined;
      this.fetchTransfer(this.transferId);
      this.entityId = id ? [parseInt(id)] : undefined;
    });
    setTimeout(() => {
      this.firstCheckbox.nativeElement.focus();
    });
    this.hasTransferData = !!this.localStorageService.loadDataFromLocalStorage<string>("transferPlan");
		this.tooltipMessage = "customer_checkout.discard_sales";

  }
  ngOnDestroy() {
    this.routeSubscriber.unsubscribe();
  }

  ngAfterViewInit() {
    this.loadTrandferPlanFromLocalStorage();
    this.cdr.detectChanges();
  }
  saveTransferPlanToLocalStorage() {
    const transferData = {
      transferIds: this.entityId || [],
      customer: this.customer,
      passengers: this.passangerList.getPassangers(),
    };
    this.localStorageService.updateKey("transferPlan", JSON.stringify(transferData));
  }
  loadTrandferPlanFromLocalStorage() {
    const savedData = this.localStorageService.loadDataFromLocalStorage<string>("transferPlan");
    if (savedData) {
      const ticketData = JSON.parse(savedData);
      if (ticketData) {
        this.customer = ticketData.customer;
        if (this.passangerList) {
          this.passangerList.setPassangers(ticketData.passengers);
        }
        if (ticketData.transferIds) {
          this.entityId = ticketData.transferIds;
          this.isFromToSelected = ticketData.transferIds[0]
          this.isToFromSelected = ticketData.transferIds[1]
        }
      }
    }
  }
  proceedToNextStage(nextStep: number | undefined = undefined) {
    if (this.activeTab === 1) {
      if (!this.isFromToSelected && !this.isToFromSelected) {
        showMessage(PopupType.Danger, this.translate.instant("transfer.select_at_least_one_direction"));
        return;
      }
      const mainCustomer = this.validationService.validateMainCustomer(this.passangerList.getPassangers());
      if (!mainCustomer) return;
      this.customer = mainCustomer;
    }

    this.activeTab = nextStep ?? this.activeTab + 1;
    this.saveTransferPlanToLocalStorage();
  }

  private focusNextStepButton() {
    setTimeout(() => {
      this.nextStepButton.nativeElement.focus();
    }, 0);
  }
  handleCustomerSubmit() {
    this.focusNextStepButton();
  }

  isVisibleTab(index: number) {
    return TabVisibilityUtil.isVisibleTab(this.activeTab, index);
  }
  toggleButton(isEditing: boolean) {
    this.isEditingPassanger = isEditing;
  }

  fetchTransfer(id: number | undefined) {
    if (id) {
      this.transferService.getTransferData(id).subscribe((res: any) => {
        this.transfer = res;
      });
    }
  }
  toggleDirection() {
    this.isForwardDirection = !this.isForwardDirection;
  }

  addDirection() {
    if (this.isFromToSelected && this.isToFromSelected) {
      this.entityId = [this.transfer.id, this.transfer.returnTransfer?.id];
    } else if (this.isFromToSelected) {
      this.entityId = [this.transfer.id];
    } else if (this.isToFromSelected) {
      this.entityId = [this.transfer.returnTransfer?.id];
    }
  }
  async submitTransfer() {
    if (!this.entityId || this.entityId.length === 0) {
      showMessage(PopupType.Danger, this.translate.instant("transfer.select_at_least_one_direction"));
      return;
    }
    try {
      this.isLoading = true;
      if (this.sales.checkIfValid() && this.passangerList.checkValidity()) {
        this.passengers = this.passangerList.getPassangers();
        this.sale = this.sales.getSale();

        const addSub = this.transferService.addPassangerToTransfer(this.fetchTransferPlanData(), this.entityId).subscribe({
          next: () => {
            this.isLoading = false;
            addSub.unsubscribe();
            showMessage(PopupType.Success, this.translate.instant("transfer.added_passangers_successfully"));
            this.router.navigate([`/dashboard/transfers/${this.transferId}`]);
            this.localStorageService.removeSpecificItem("transferPlan");
          },
          error: (error) => {
            this.isLoading = false;
            addSub.unsubscribe();
            let errStr = error.error;
            if (errStr.includes("limit")) showMessage(PopupType.Danger, error.error);
            else showMessage(PopupType.Danger, this.translate.instant("transfer.error_while_adding_passangers"));
          },
        });
      }
    } catch (error) {
      showMessage(PopupType.Danger, this.translate.instant("transfer.error_while_adding_passangers"));
    }
  }

  fetchTransferPlanData() {
    return {
      transferId: this.transferId,
      passangers: this.passengers.map((p: any) => {
        return { customerId: p.id, lifeStage: p.lifeStage, isMainCustomer: p.isMainCustomer };
      }),
      plan: { ...this.sale, dueDate: moment(this.sale.dueDate, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss"), customerId: this.customer.id },
      customerId: this.customer.id,
    };
  }
  clearTransferData(): void {
    this.localStorageService.removeSpecificItem("transferPlan");
    this.router.navigate(["dashboard/sales"]);
  }
}
