
import { Destination } from '../models/destination';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from 'src/app/shared/services/util.service';
import { EntityPicklistType } from 'src/app/shared/enums/picklist-types';
import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'app-edit-destination',
  templateUrl: './edit-destination.component.html',
})
export class EditDestinationComponent implements OnInit {
  @Input() destination: Destination = {} as Destination;
  @Input() isLoading!: boolean;
  @Input() editMode!: boolean;
  @Output() formSubmit = new EventEmitter<any>();
  form!: FormGroup;
  EntityPicklistType = EntityPicklistType;
	@ViewChild("nameInput") nameInput!: ElementRef;
  constructor(private fb: FormBuilder, public utilService: UtilService) {
  }
	ngOnInit(): void {
    this.initForm();
    if (this.destination) {
      this.populateForm(this.destination);
    }
    setTimeout(() => {
      this.setFocusToEnd();
    }, 10);
  }
	
  initForm(): void {
    this.form = this.fb.group({
      description: ['', Validators.required],
      country: ['', Validators.required],
      countryId: [''],
    });
  }

  populateForm(destination: Destination) {
    this.form.patchValue({
      description: destination.description || '',
      countryId: destination.countryId || '',
      country: destination.country || '',
    });

    setTimeout(() => {
      this.setFocusToEnd();
    }, 10);
  }


	setFocusToEnd() {
    if (this.nameInput && this.nameInput.nativeElement) {
      const input = this.nameInput.nativeElement;
      input.focus();
      
      const length = input.value.length;
      input.setSelectionRange(length, length);
    }
  }

  submitForm(): void {
    if (this.form.valid) {
      const formValue = this.form.value;
      formValue.countryId = formValue.country.id;

      this.formSubmit.emit(formValue);
    } else {
      this.form.markAllAsTouched();
    }
  }
  closeModal() {
    this.formSubmit.emit(null);
  }
}