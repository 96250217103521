<div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
  <button type="button" class="btn btn-info gap-2" (click)="sendContract()">
    <icon-send />
    {{ "invoices.send" | translate }}
  </button>
  <button type="button" class="btn btn-primary gap-2" (click)="printContract()" [disabled]="isLoading">
    <icon-printer />
    {{ "contract.print" | translate }}
  </button>
  <button type="button" class="btn btn-warning gap-2" (click)="editContract()">
    <icon-edit />
    {{ "contract.edit" | translate }}
  </button>
  <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="factureId" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"></app-file-history>
</div>
<div class="panel" *ngIf="invoice" id="contract">
  <div class="d-flex justify-content-between align-items-center">
    <div class="flex flex-wrap items-center justify-between">
      <div class="flex-2" *ngIf="invoice.officeBranch && invoice.officeBranch.logoImage">
        <img [src]="invoice.officeBranch.logoImage.url" alt="" class="w-15 h-20" />
      </div>
      <div class="space-y-1 text-white-dark">
        <div class="w-full" *ngIf="invoice.officeBranch">
          <div class="space-y-1 text-white-dark">
            <div class="flex flex-wrap justify-end" *ngIf="invoice.officeBranch.fullAddress">
              <span class="mr-2">{{ invoice.officeBranch.fullAddress }} </span>
              <icon-home />
            </div>
            <div class="flex flex-wrap justify-end" *ngIf="invoice.officeBranch.phoneNumber">
              <span class="mr-2">{{ invoice.officeBranch.phoneNumber }} </span>
              <icon-phone />
            </div>
            <div class="flex flex-wrap justify-end" *ngIf="invoice.officeBranch.email">
              <span class="mr-2">{{ invoice.officeBranch.email }} </span>
              <icon-mail />
            </div>
            <div class="flex flex-wrap justify-end" *ngIf="invoice.officeBranch.siteURL">
              <span class="mr-2">https://www.{{ invoice.officeBranch.siteURL }} </span>
              <icon-chrome />
            </div>
            <div class="flex flex-wrap justify-end">
              <span class="mr-2">{{ "giro-account" | translate }}: {{ invoice.officeBranch.bankAccount }},PIB: {{ invoice.officeBranch.pib }} </span>
              <icon-zip-file />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4 grid text-center text-xl font-bold">
    <p>{{ "invoices.proforma_invoice" | translate }}</p>
    <p>{{ "invoices.by_reservation_number" | translate }} ID: {{ invoice.uniqueDocumentNumber || "/" }}</p>
  </div>

  <div class="mt-4 grid grid-cols-1 gap-1 sm:grid-cols-1">
    <div class="col-span-2 mb-4 flex flex-col sm:col-span-1">
      <div class="">{{ "contract.traveller_information" | translate }}</div>
    </div>
    <div class="flex flex-col sm:flex-row">
      <table class="w-full table-auto border border-gray-400">
        <tbody>
          <tr class="border border-gray-300 bg-gray-50">
            <td class="border-b border-gray-300 p-2">{{ "contract.destination" | translate }}: {{ invoice.destination }}</td>
            <td class="border-b border-gray-300 p-2">{{ "contract.hotel" | translate }}: {{ invoice.hotel }}</td>
          </tr>
          <tr class="border border-gray-300 bg-gray-50">
            <td class="border-b border-gray-300 p-2">{{ "contract.check_in" | translate }}: {{ invoice.checkIn | date : "dd/MM/yyyy" }}</td>
            <td class="border-b border-gray-300 p-2">{{ "contract.check_out" | translate }}: {{ invoice.checkOut | date : "dd/MM/yyyy" }}</td>
          </tr>
          <tr class="border border-gray-300 bg-gray-50">
            <td class="border-b border-gray-300 p-2">{{ "contract.start_date" | translate }}: {{ invoice.startDate | date : "dd/MM/yyyy" }}</td>
            <td class="border-b border-gray-300 p-2">{{ "contract.end_date" | translate }}: {{ invoice.endDate | date : "dd/MM/yyyy" }}</td>
          </tr>
          <tr class="border border-gray-300 bg-gray-50">
            <td class="border-b border-gray-300 p-2">{{ "contract.service" | translate }}: {{ invoice.service }}</td>
            <td class="border-b border-gray-300 p-2">{{ "contract.transportation" | translate }}: {{ invoice.transportation }}</td>
          </tr>
          <tr class="border border-gray-300 bg-gray-50">
            <td class="border-b border-gray-300 p-2">{{ "contract.days" | translate }}: {{ invoice.days }}, {{ "contract.nights" | translate }}: {{ invoice.nights }}</td>
            <td class="border-b border-gray-300 p-2">{{ "contract.rooms" | translate }}: {{ invoice.rooms }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-span-2 mt-4 flex flex-col sm:col-span-1">
      <div class="mb-4 flex flex-col sm:col-span-1">
        <div class="">{{ "contract.passangers_information" | translate }}</div>
      </div>
      <div class="overflow-x-auto">
        <table class="mb-3 w-full table-auto border border-gray-400">
          <thead class="border-t-2">
            <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-100 sm:col-span-3">
              <th class="border border-gray-300">{{ "contract.guests" | translate }}</th>
              <th class="border border-gray-300">{{ "contract.name_and_surname" | translate }}</th>
              <!-- <th class="border border-gray-300 sm:col-span-1">{{ "contract.date_of_birth" | translate }}</th> -->
              <th class="border border-gray-300">{{ "contract.date_of_birth" | translate }}</th>
              <th class="border border-gray-300">{{ "contract.email" | translate }}</th>
              <th class="border border-gray-300">{{ "contract.address" | translate }}</th>
              <th class="border border-gray-300">{{ "contract.contact" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let passanger of invoice.passengers; index as i">
              <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
                <td class="border border-gray-300">{{ i + 1 }}</td>
                <td class="border border-gray-300">{{ passanger?.name }} {{ passanger?.surname }}</td>
                <!-- <td class="border border-gray-300 sm:col-span-1">{{ passanger?.dateOfBirth | date : "dd/MM/yyyy" }}</td> -->
                <td class="border border-gray-300">{{ passanger?.dateOfBirth | date : "dd/MM/yyyy" }}</td>
                <td class="border border-gray-300">{{ passanger?.email }}</td>
                <td class="border border-gray-300">{{ passanger?.address }}</td>
                <td class="border border-gray-300">{{ passanger?.phoneNumber }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-span-2 mt-4 flex flex-col sm:col-span-1">
      <div class="col-span-2 mb-4 mt-4 flex flex-col sm:col-span-1">
        <div class="-dark text-white"></div>
        <div class="">{{ "contract.trip_invoice" | translate }}</div>
      </div>
      <table class="table-bordered mb-5 table">
        <tbody class="bg-gray-50">
          <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ "contract.total_in_euro" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ invoice.totalInEuroPrice | currency : "EUR " : "symbol" : "1.2-2" }}</td>
          </tr>
          <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ "contract.nbs" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ invoice.exchangeRate | number : "1.4-4" }}</td>
          </tr>
          <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
            <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ "contract.total_in_din" | translate }}</td>
            <td class="border border-gray-300 sm:col-span-1" colspan="2">{{ invoice.priceTotal | currency : "RSD " : "symbol" : "1.2-2" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="grid grid-cols-1 border border-gray-300 bg-gray-50 p-4 text-start shadow-md sm:grid-cols-1">
      <p class="text-start">{{ "invoices.passenger_agreement" | translate }}</p>
      <p class="text-start">{{ "invoices.contract_acknowledgment" | translate }}</p>
    </div>
    <div class="mt-2 grid grid-cols-1 text-start sm:grid-cols-1">
      <p class="text-start">{{ "invoices.document_validity" | translate }}</p>
      <p class="text-start">{{ "invoices.payment_instruction" | translate }}</p>
    </div>
    <div class="mt-2 grid grid-cols-1 text-start sm:grid-cols-1">
      <p class="text-start font-bold">{{ "invoices.payment_reference" | translate }} {{ invoice.uniqueDocumentNumber || "/" }}</p>
    </div>

    <div class="col-span-2 mt-4 flex flex-col sm:col-span-1">
      <div class="mt-5 flex w-full items-center justify-between">
        <div class="w-1/2 text-start">
          <div *ngIf="invoice?.qrCodeImage?.url">
            <img [src]="invoice.qrCodeImage.url" alt="QR Code" width="200" height="200" />
          </div>
          <small class="mt-2 text-sm">
            {{ "invoices.checkPaymentDetails" | translate }}
          </small>
        </div>
        <div class="w-1/2 text-end">
          <p>_____________________________</p>
          <p><strong>Agent:</strong> {{ invoice.createdByFullName }}</p>
        </div>
      </div>
    </div>

    <div class="mt-2 grid grid-cols-1 text-start sm:grid-cols-1">
      <p class="text-start">{{ "invoices.tourist_agency_vat_notice" | translate }}</p>
    </div>
  </div>
</div>

<modal #modal class="modal-top custom-modal" [closeOnOutsideClick]="false">
  <ng-template #modalBody>
    <button (click)="closeModal()" class="absolute right-2 top-2 text-gray-500 hover:text-gray-700">
      <icon-x class="h-5 w-5"></icon-x>
    </button>
    <br />
    <ng-container *ngIf="invoice">
      <app-file-send
        [title]="'contract_form_title_slip' | translate"
        fileType="invoice_facture"
        [id]="invoice.id"
        [passangers]="invoice.passengers"
        [sendDocumentFn]="invoiceService.sendInvoice(this.invoice.id)"
        messageRs="Ugovor je u dodatku imejla. Molim vas da proverite podatke.Pri prihvatanju ugovora prihvatate Opšte uslove o putovanje agencije.MOLIMO VAS DA POTVRDITE DA SE SAGLASNI SA UGOVOROM ODGOVOROM NA OVAJ IMEJL.Molim Vas da se raspitate o uslovima putovanja i prelaska granica. Takođe proverite vaša lična dokumenta. To nije obaveza turističke agencije."
        messageEn="The contract is attached to the email. Please check the details. When the contract is accepted, you are obliged to follow the General Travel Conditions. Please answer this email if you confirm the contract.  Please inquire about travel conditions and border crossing requirements. Also, check your personal documents. This is not the responsibility of the travel agency."
        (notifyWhenSend)="finishSending()"
      >
        <table class="mb-4 w-full table-auto">
          <thead>
            <tr>
              <th class="px-4 py-2">{{ "contractsendemail.name" | translate }}</th>
              <th class="px-4 py-2">{{ "contractsendemail.surname" | translate }}</th>
              <th class="px-4 py-2">{{ "contractsendemail.email" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let passenger of invoice.passengers">
              <td class="border px-4 py-2">{{ passenger.name }}</td>
              <td class="border px-4 py-2">{{ passenger.surname }}</td>
              <td class="border px-4 py-2">{{ passenger.email }}</td>
            </tr>
          </tbody>
        </table>
        <div class="flex">
          <table class="w-full table-auto border border-gray-400">
            <tbody>
              <tr class="border border-gray-300 bg-gray-50">
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.createdate" | translate }}: {{ this.invoice.createDate | date : "dd.MM.yyyy" }}</td>
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.pricetotal" | translate }}: {{ this.invoice.totalInEuroPrice | currency : "EUR" }}</td>
              </tr>
              <tr class="border border-gray-300 bg-gray-50">
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.hotel" | translate }}: {{ this.invoice.hotel }}</td>
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.rooms" | translate }}: {{ this.invoice.rooms }}</td>
              </tr>
              <tr class="border border-gray-300 bg-gray-50">
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.checkin" | translate }}: {{ this.invoice.checkIn | date : "dd.MM.yyyy" }}</td>
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.checkout" | translate }}: {{ this.invoice.checkOut | date : "dd.MM.yyyy" }}</td>
              </tr>
              <tr class="border border-gray-300 bg-gray-50">
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.startdate" | translate }}: {{ this.invoice.startDate | date : "dd.MM.yyyy" }}</td>
                <td class="border-b border-gray-300 p-2">{{ "contractsendemail.enddate" | translate }}: {{ this.invoice.endDate | date : "dd.MM.yyyy" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </app-file-send>
    </ng-container>
  </ng-template>
</modal>
