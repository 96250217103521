<div class="form-group">
  <label for="confirmationNumber">{{ "confirmation_number_label" | translate }}</label>
  <input
    type="text"
    id="confirmationNumber"
    class="form-input"
    [ngModel]="confirmationNumber"
    (ngModelChange)="onInputChange($event)"
    placeholder="{{ 'enter_organizer_confirmation_number' | translate }}"
    #confirmationInput
  />
</div>
<div class="mt-7">
  <button (click)="submitConfirmationNumber()" class="btn btn-primary w-full">
    {{ "submit" | translate }}
  </button>
</div>
