<div class="mb-3 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
  <ng-container class="mb-3 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
    <ng-container *ngIf="checked && groupTravelData && !groupTravelData.eTuristRecordId; else registeredInEturist">
      <button *appHasPackageRight="['eTouristAccounting']" type="button" (click)="addRecordsToETurist()" class="btn btn-primary gap-2 p-0 px-1" ngxTippy="{{ 'account_data.record_sale' | translate }}">
        <icon-accounting-data></icon-accounting-data>
        {{ "account_data.accounting_data" | translate }}
        <img src="assets/images/eturista-logo.png" class="w-18 h-9" />
      </button>

      <div [ngxTippy]="'higher_tier' | translate">
        <button *appHasPackageRight="['eTouristAccounting', true]" type="button" disabled="true" class="btn btn-primary gap-2 p-0 px-1">
          <icon-accounting-data></icon-accounting-data>
          {{ "account_data.accounting_data" | translate }}
          <img src="assets/images/eturista-logo.png" class="w-18 h-9" />
        </button>
      </div>
    </ng-container>

    <ng-template #registeredInEturist>
      <ng-container *ngIf="groupTravelData && groupTravelData.eTuristRecordId">
        <app-details-dropdown-eturist
          [eTuristRecordId]="groupTravelData.eTuristRecordId"
          [plan]="groupTravelData.plan"
          [travelId]="groupTravelData.id"
          [id]="id"
          [emails]="emails"
          (recordUpdated)="refetch()"
          [IsGroupETourist]="true"
        >
        </app-details-dropdown-eturist>
      </ng-container>
    </ng-template>

    <ng-container *ngIf="groupTravelData">
      <app-group-travel-dropdown [id]="groupTravelData.planId" [entityId]="id"> </app-group-travel-dropdown>
    </ng-container>
    <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"></app-file-history>
    <button type="button" class="btn btn-primary gap-2" *ngIf="groupTravelData && groupTravelData.id">
      <a class="flex items-center justify-center" href="javascript:;" [routerLink]="'/dashboard/group-travels/' + groupTravelData.id + '/plan/edit'" [queryParams]="{ returnId: id }">
        <icon-pencil-paper class="h-4.5 w-4.5 shrink-0" />
      </a>
    </button>
  </ng-container>
</div>

<div *ngIf="groupTravelData">
  <div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-1 flex flex-col sm:col-span-2 print:hidden">
          <div class="text-xl font-bold">
            {{ "group_travel_details_plan.lead_passanger" | translate }}
          </div>
          <div class="text-xl">{{ groupTravelData.customer.name }} {{ groupTravelData.customer.surname }}</div>
          <div class="text-[15px] italic text-white-dark" style="font-style: italic">
            {{ groupTravelData.customer.phoneNumber }}
          </div>
        </div>

        <div class="col-span-1 flex flex-col sm:col-span-2 print:hidden">
          <div class="flex flex-col">
            <div>{{ "group_travel_details_plan.status" | translate }}:</div>
            <ng-container *ngIf="groupTravelData.plan.status; else notPaid">
              <div class="badge mt-1 inline-flex w-auto self-start bg-success">
                {{ "group_travel_details_plan.paid" | translate }}
              </div>
            </ng-container>
            <ng-template #notPaid>
              <div class="badge mt-1 inline-flex w-auto self-start bg-danger text-white">
                {{ "group_travel_details_plan.not_paid" | translate }}
              </div>
            </ng-template>
          </div>
          <div class="mt-2 flex flex-col">
            <div class="ltr:mr-2 rtl:ml-2">{{ "group_travel_details_plan.active" | translate }}:</div>
            <ng-container *ngIf="groupTravelData.plan.active; else notActive">
              <div class="badge mt-1 inline-flex w-auto self-start bg-success">
                {{ "group_travel_details_plan.active" | translate }}
              </div>
            </ng-container>
            <ng-template #notActive>
              <div class="badge mt-1 inline-flex w-auto self-start bg-slate-200 text-white">
                {{ "group_travel_details_plan.deleted" | translate }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-span-2 flex grid grid-cols-2 rounded-lg p-4 sm:col-span-4 sm:grid-cols-4 print:hidden">
        <div class="col-span-2 grid grid-cols-2 sm:col-span-4">
          <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
            <div class="text-xl font-bold">
              {{ "group_travel_details.name" | translate }}
              <div class="text-xl">{{ groupTravelData.groupTravel.name }}</div>
            </div>
          </div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details_plan.departure_from" | translate }} :</div>
          <div *ngIf="mainDestination.destination?.description">{{ mainDestination.destination?.description }}</div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details_plan.hotel" | translate }}:</div>
          <div>{{ mainDestination.hotel?.name }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details.transportation" | translate }} :</div>
          <div *ngIf="groupTravelData.groupTravel.type; else showTransport">
            {{ groupTravelData.groupTravel.type }}
          </div>
          <ng-template #showTransport>
            <div>
              {{ groupTravelData.groupTravel.eTuristTransportLabel }}
            </div>
          </ng-template>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1 sm:row-span-3">
          <div>{{ "travel_details.additional_destinations" | translate }} :</div>
          <div *ngFor="let dest of additionalDestinations">
            <app-travel-accordion [content]="dest"></app-travel-accordion>
          </div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details_plan.departure_date" | translate }}:</div>
          <div>{{ groupTravelData.groupTravel.start | date : "dd.MM.yyyy." }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details_plan.return_date" | translate }}:</div>
          <div>{{ groupTravelData.groupTravel.end | date : "dd.MM.yyyy." }}</div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details_plan.days_nights" | translate }}:</div>
          <div>{{ groupTravelData.groupTravel.days }}/{{ groupTravelData.groupTravel.nights }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.room_description" | translate }}:</div>
          <div>{{ mainDestination.roomDescription }}</div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.service_type" | translate }}:</div>
          <div *ngIf="mainDestination">
            <div *ngIf="mainDestination.service?.title; else showService">
              {{ mainDestination.service?.title }}
            </div>
            <ng-template #showService>
              <div>
                {{ mainDestination.eTuristAccommodationLabel }}
              </div>
            </ng-template>
          </div>
        </div>

        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.notes" | translate }}:</div>
          <div>{{ groupTravelData.plan.notes }}</div>
        </div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "paymentssendemail.payments" | translate }}
          </div>
        </div>
        <app-sale-details
          class="col-span-2 sm:col-span-4"
          [id]="groupTravelData.planId"
          [hideStatus]="true"
          (reFetchEvent)="refetch()"
          [entityDetails]="entityDetails"
          [navigateToRoute]="'/dashboard/group-travels/' + groupTravelData.id + '/plan/details'"
        ></app-sale-details>
      </div>
    </div>
    <div class="my-5 block space-y-4 overflow-x-auto rounded-lg border border-white-dark/20 p-4 print:hidden" *ngIf="groupTravelData.groupTravelPassangers && groupTravelData.groupTravelPassangers.length > 0">
      <div class="text-[20px] text-dark">{{ "group_travel_details_plan.all_passengers" | translate }}:</div>
      <div class="grid grid-cols-1 justify-center sm:grid-cols-1">
        <ng-container *ngFor="let item of groupTravelData.groupTravelPassangers; index as i">
          <div
            class="flex grid grid-cols-2 items-center justify-between gap-5 gap-x-2 rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b] sm:min-w-[625px] sm:grid-cols-4"
          >
            <div class="user-profile grid-cols-1 justify-self-center sm:grid-cols-1 sm:justify-self-start">
              <img [src]="item.customer.imageUrl ? item.customer.imageUrl : '/assets/images/auth/user-icon.png'" alt="" class="h-8 w-8 rounded-md object-cover" />
            </div>

            <div class="grid-cols-2 px-2 sm:grid-cols-1">{{ item.customer.name }} {{ item.customer.surname }}</div>
            <div class="grid-cols-1 px-2 sm:grid-cols-1">
              {{ "group_travel_details_plan.phone_number" | translate }}:
              {{ item.customer.phoneNumber ? item.customer.phoneNumber : "N/A" }}
            </div>
            <div class="grid-cols-1 px-2 sm:grid-cols-1">
              {{ "group_travel_details_plan.email" | translate }}:
              {{ item.customer.email ? item.customer.email : "N/A" }}
            </div>

            <ng-template #backUp> </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="small text-secondary">{{ groupTravelData.createdBy.name }} {{ groupTravelData.createdBy.surname }}, {{ groupTravelData.createdAt | date : "dd.MM.yyyy." }}</div>
  </div>
</div>
<modal #eturistModal class="modal-top auth-modal" [closeOnOutsideClick]="false">
  <ng-template #modalHeader>
    <div class="!py-5">{{ "account_data.record_sale" | translate }}</div>
  </ng-template>
  <ng-template #modalBody *ngIf="mainDestination">
    <app-travel-e-tourist-form
      [uniqueIdentifier]="groupTravelData.groupTravel.eTuristRecordId || undefined"
      [nbsExchangeRate]="groupTravelData.plan.exchangeRate"
      [id]="id"
      [bruto]="groupTravelData.plan.bruto"
      (formSubmit)="addedRecordToEturist()"
      [eTuristTransportCode]="groupTravelData.groupTravel.eTuristTransportCode || ''"
      [eTuristAccommodationCode]="mainDestination.eTuristAccommodationCode || ''"
      [IsGroupETourist]="true"
    ></app-travel-e-tourist-form>
  </ng-template>
</modal>
