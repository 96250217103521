import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { toggleAnimation } from "src/app/shared/animations";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { ETouristSearchTravelSaleDto, ETouristTravelSale } from "src/app/shared/models/etourist";
import { ETouristService } from "src/app/shared/services/etourist.service";
import { TravelsService } from "../../travels/services/travels.service";
import { Subject, takeUntil } from "rxjs";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";

@Component({
  selector: "app-tourist-sales",
  templateUrl: "./tourist-sales.component.html",
  animations: [toggleAnimation],
})
export class TouristSalesComponent implements OnInit, OnDestroy {
  public cols: DatatableColumn[] = [];
  touristSales: ETouristTravelSale[] = [];
  filteredSales: ETouristTravelSale[] = [];
  loading = false;
  search = "";
  public pageSize = 10;
  public totalRows = 2;
  public pageNumber = 1;
  public currentId!: number;
  public travelId!: number | null;
  private destroy$ = new Subject<void>();

  constructor(private translate: TranslateService, public eTouristService: ETouristService, public travelService: TravelsService) {}

  ngOnInit(): void {
    this.getColumns();
    this.loadTouristSales();
  }

  getColumns(): void {
    this.cols = [
      { field: "actions", title: this.translate.instant("tourist_sales.actions"), hide: false, sort: false } as DatatableColumn,
      { field: "uniqueIdentifier", title: this.translate.instant("tourist_sales.uniqueIdentifier"), isUnique: true, hide: false } as DatatableColumn,
      { field: "organizerBusinessName", title: this.translate.instant("tourist_sales.travelAgencyName"), hide: false } as DatatableColumn,
      { field: "organizerTaxNumber", title: this.translate.instant("tourist_sales.travelAgencyIdentificationNumber"), hide: false } as DatatableColumn,
      { field: "subjectBusinessName", title: this.translate.instant("tourist_sales.name"), hide: true } as DatatableColumn,
      { field: "destinations", title: this.translate.instant("tourist_sales.destinations"), hide: false } as DatatableColumn,
      { field: "destinationCountry", title: this.translate.instant("tourist_sales.destinationCountry"), hide: false } as DatatableColumn,
      { field: "travelStartDate", title: this.translate.instant("tourist_sales.travelStartDate"), hide: false } as DatatableColumn,
      { field: "salesStatus", title: this.translate.instant("tourist_sales.salesStatus"), hide: false } as DatatableColumn,
    ];
  }

  updateColumn(col: DatatableColumn) {
    col.hide = !col.hide;
    this.cols = [...this.cols];
  }

  onSearchChange(searchTerm: string): void {
    this.search = searchTerm;
    this.filterTouristSales();
  }

  public changeServer(data: any) {
    this.pageSize = data.pagesize;
    this.pageNumber = data.current_page;
    this.loadTouristSales();
  }

  loadTouristSales(): void {
    const searchTravelSaleDto: ETouristSearchTravelSaleDto = {
      NumberOfTravelsPerPage: this.pageSize,
      PageNumber: this.pageNumber,
    };
    this.eTouristService.searchTravelSale(searchTravelSaleDto).subscribe((data: ETouristTravelSale[]) => {
      this.touristSales = data;
      this.filterTouristSales();
    });
  }
  filterTouristSales(): void {
    if (this.search.trim() === "") {
      this.filteredSales = [...this.touristSales];
    } else {
      const searchLower = this.search.toLowerCase();
      this.filteredSales = this.touristSales.filter((sale) => Object.values(sale).some((value) => value && value.toString().toLowerCase().includes(searchLower)));
    }
  }

  onMouseEnter(id: number): void {
    this.currentId = id;
    this.fetchTravelId(id.toString());
  }
  fetchTravelId(eTuristRecordId: string) {
    this.travelService
      .getTravelQuickViewByETuristRecordId(eTuristRecordId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.travelId = data.id ?? null;
        },
        error: (error) => {
          showMessage(PopupType.Danger, error.error);
        },
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
