import { Office } from "../../models/office-branch";
import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { slideDownUp, toggleAnimation } from "src/app/shared/animations";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { OfficeBranchService } from "../../services/office-branch.service";

@Component({
  selector: "app-office-branch-mail-form",
  templateUrl: "./office-branch-mail-form.component.html",
  animations: [toggleAnimation, slideDownUp],
})
export class OfficeBranchMailFormComponent implements OnChanges {
  @Input() office!: Office;
  @Output() officeChange = new EventEmitter<Office>();
  public form!: FormGroup;
  passwordVisible = false;
  private hasInitialized: boolean = false;

  constructor(private officeBranchService: OfficeBranchService, public translate: TranslateService, public fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["office"] && this.office && !this.hasInitialized) {
      this.initForm(this.office);
      this.hasInitialized = true;
    }
  }

  initForm(office: Office): void {
    const { senderEmail, senderName, adminEmail, password } = office || ({} as Office);
    this.form = this.fb.group({
      senderEmail: [senderEmail, [Validators.email, Validators.required]],
      senderName: [senderName, Validators.required],
      adminEmail: [adminEmail, [Validators.email, Validators.required]],
      password: [password, Validators.required],
    });
  }

  async submitForm(): Promise<boolean> {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    return new Promise((resolve) => {
      if (this.office) {
        const subscription = this.officeBranchService.updateOfficeEmailConfiguration(this.office.id, this.form.getRawValue()).subscribe({
          next: (response) => {
            subscription.unsubscribe();
            resolve(true);
            this.officeChange.emit({ ...this.office, ...this.form.getRawValue() });
            showMessage(PopupType.Success, this.translate.instant("popup.office_branch_updated_successfully"));
          },
          error: (error) => {
            subscription.unsubscribe();
            resolve(false);
            showMessage(PopupType.Danger, error.error.toString());
          },
        });
      }
    });
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
}
