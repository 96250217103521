<div class="grid grid-cols-1 gap-5 p-4 sm:grid-cols-2" *ngIf="office">
  <div class="col-span-2 rounded-lg p-4 sm:col-span-2">
    <div class="col-span-2 flex flex-col pb-4 sm:col-span-2">
      <div class="flex justify-center text-xl font-bold">
        {{ "login_wizard.fourth_step_title" | translate }}
      </div>

      <small class="flex justify-center py-5 text-center text-base">{{ "login_wizard.fourth_step_subtext" | translate }}</small>
    </div>

    <!-- First Form: One row, two columns -->
    <form [formGroup]="guidForm" (ngSubmit)="validateGUID()" class="grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div [ngClass]="guidForm.controls['guid'].touched ? (guidForm.controls['guid'].errors ? 'has-error' : 'has-success') : ''">
        <label for="guid">{{ "office-branch.enter_guid" | translate }}</label>
        <input (ngModelChange)="reset()" id="guid" formControlName="guid" class="form-input" placeholder="{{ 'office-branch.enter_guid' | translate }}" />
        <ng-container *ngIf="guidForm.controls['guid'].touched && guidForm.controls['guid'].errors">
          <p class="mt-1 text-danger">
            <small>{{ "office-branch.please_provide_guid" | translate }}</small>
          </p>
        </ng-container>
      </div>

      <div [ngClass]="guidForm.controls['jmbg'].touched ? (guidForm.controls['jmbg'].errors ? 'has-error' : 'has-success') : ''">
        <label for="jmbg">{{ "office-branch.enter_jmbg" | translate }}</label>
        <input (ngModelChange)="reset()" id="jmbg" formControlName="jmbg" class="form-input" placeholder="{{ 'office-branch.enter_jmbg' | translate }}" />
        <ng-container *ngIf="guidForm.controls['jmbg'].touched && guidForm.controls['jmbg'].errors">
          <p class="mt-1 text-danger">
            <small>{{ "office-branch.please_provide_jmbg" | translate }}</small>
          </p>
        </ng-container>
      </div>
      <div class="flex h-full flex-col">
        <label class="select-none text-white" for="jmbg">{{ "office-branch.enter_jmbg" | translate }}</label>
        <button type="submit" class="btn btn-primary w-full">
          <ng-container *ngIf="isLoading; else alternateTranslation">
            <span class="inline-block h-3 w-3 animate-ping rounded-full bg-white ltr:mr-4 rtl:ml-4"></span>
            {{ "loading" | translate }}
          </ng-container>
          <ng-template #alternateTranslation>
            <span> {{ "login_wizard.validate" | translate }}</span>
          </ng-template>
        </button>
      </div>
    </form>

    <div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <small [innerHTML]="'login_wizard.guid_info' | translate"></small>
    </div>

    <!-- Second Form: One row, three columns -->
    <form [formGroup]="form" (ngSubmit)="submitForm()" class="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
      <!-- Info Box -->
      <div *ngIf="isValidated" class="col-span-1 flex items-center rounded bg-info-light p-3.5 text-info dark:bg-info-dark-light md:col-span-3">
        <span class="text-black ltr:pr-2 rtl:pl-2"> <strong class="ltr:mr-1 rtl:ml-1">Info!</strong>{{ "office-branch.order_info_eturist" | translate }} </span>
      </div>

      <!-- Municipality Select -->
      <div [ngClass]="form.controls['municipality'].touched ? (form.controls['municipality'].errors ? 'has-error' : 'has-success') : ''">
        <label for="municipality">{{ "office-branch.municipality" | translate }}</label>
        <div class="relative">
          <div *ngIf="!isValidated" class="disabled-overlay"></div>
          <ng-select
            placeholder="{{ 'office-branch.enter_municipality' | translate }}"
            labelForId="municipality"
            [items]="optionsMunicipality$ | async"
            bindLabel="nameLatin"
            formControlName="municipality"
            [searchable]="true"
            [clearable]="true"
            class="custom-multiselect"
          ></ng-select>
        </div>
        <ng-container *ngIf="form.controls['municipality'].touched && form.controls['municipality'].errors">
          <p class="mt-1 text-danger">
            <small>{{ "office-branch.please_provide_municipality" | translate }}</small>
          </p>
        </ng-container>
      </div>

      <!-- City Select -->
      <div [ngClass]="form.controls['city'].touched ? (form.controls['city'].errors ? 'has-error' : 'has-success') : ''">
        <label for="city">{{ "office-branch.city" | translate }}</label>
        <div class="relative">
          <div *ngIf="!isValidated" class="disabled-overlay"></div>
          <ng-select
            placeholder="{{ 'office-branch.enter_city' | translate }}"
            labelForId="city"
            [items]="optionsCities$ | async"
            bindLabel="nameLatin"
            formControlName="city"
            [searchable]="true"
            [clearable]="true"
            class="custom-multiselect"
            [disabled]="!form.controls['municipality'].value"
          ></ng-select>
        </div>
        <ng-container *ngIf="form.controls['city'].touched && form.controls['city'].errors">
          <p class="mt-1 text-danger">
            <small>{{ "office-branch.please_provide_city" | translate }}</small>
          </p>
        </ng-container>
      </div>

      <!-- Street Select -->
      <div [ngClass]="form.controls['street'].touched ? (form.controls['street'].errors ? 'has-error' : 'has-success') : ''">
        <label for="street">{{ "office-branch.street" | translate }}</label>
        <div class="relative">
          <div *ngIf="!isValidated" class="disabled-overlay"></div>
          <ng-select
            placeholder="{{ 'office-branch.enter_street' | translate }}"
            labelForId="street"
            [items]="optionsStreets$ | async"
            bindLabel="nameLatin"
            formControlName="street"
            [searchable]="true"
            [clearable]="true"
            class="custom-multiselect"
            [disabled]="!form.controls['city'].value"
          ></ng-select>
        </div>
        <ng-container *ngIf="form.controls['street'].touched && form.controls['street'].errors">
          <p class="mt-1 text-danger">
            <small>{{ "office-branch.please_provide_street" | translate }}</small>
          </p>
        </ng-container>
      </div>
    </form>
  </div>
</div>
