import * as moment from "moment";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Sale } from "src/app/domain/sales/models/sale";
import { StyleUtil } from "src/app/shared/utils/style.util";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { CheckoutService } from "../../services/checkout.service";
import { Customer } from "src/app/domain/customers/models/customer";
import { TabVisibilityUtil } from "src/app/shared/tab-visibility.util";
import { Travel, TravelDraft } from "src/app/domain/travels/models/travel";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { LocalStorageService } from "src/app/service/local-storage.service";
import { ValidationService } from "src/app/shared/services/validation.service";
import { TravelDestination } from "src/app/domain/travels/models/travel-destination";
import { TravelCreateComponent } from "src/app/domain/travels/travel.create/travel.create.component";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PassangersListComponent } from "src/app/domain/passangers/passangers.list/passangers.list.component";
import { DestinationsListComponent } from "src/app/domain/destinations/destinations.list/destinations.list.component";
import { TravelDestinationCreateComponent } from "src/app/domain/travels/travel-destination.create/travel-destination.create.component";

@Component({
  selector: "app-travel-checkout-create",
  templateUrl: "./travel-checkout.create.component.html",
})
export class TravelCheckoutCreateComponent implements AfterViewInit, OnInit {
  @ViewChild("travelBase") travelComponent!: TravelCreateComponent;
  @ViewChild("destinations") destinations!: DestinationsListComponent;
  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("mainDestination") mainDestination!: TravelDestinationCreateComponent;
  @ViewChild("nextStepButton") nextStepButton!: ElementRef;

  note!: string;
  public id!: number;
  public sale!: Sale;
  public activeTab = 1;
  public isLoading = false;
  public customer!: Customer;
  public travel!: TravelDraft;
  public showTravelForm = false;
  public showMainDestination = true;
  public isEditingPassanger = false;
  hasTravelData = false;
  public tabSizeHandler = StyleUtil.getTabWidth;
	tooltipMessage!: string;

  constructor(
    private router: Router,
    public translate: TranslateService,
    public checkoutService: CheckoutService,
    public validationService: ValidationService,
    private cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.hasTravelData = !!this.localStorageService.loadDataFromLocalStorage<string>("travelData");
		this.tooltipMessage = "customer_checkout.discard_sales";
  }

  proceedToNextStage(nextStep: number) {
    if (nextStep === 2) {
      this.customer = this.passangerList.getPassangers().find((e) => e.mainCustomer) as Customer;
      if (!this.validationService.validatePassanger(this.customer, this.passangerList.checkValidity())) {
        return;
      }
      this.showTravelForm = true;
    }
    if (this.activeTab === 1) {
      const mainCustomer = this.validationService.validateMainCustomer(this.passangerList.getPassangers());
      if (!mainCustomer) return;
      this.customer = mainCustomer;
    }

    this.setFocusField(this.activeTab + 1);

    if (this.activeTab === 2 && nextStep !== 1) {
      if (!this.travelComponent.checkIfValid()) {
        showMessage(PopupType.Danger, this.translate.instant("forms_invalid_ticket_sale_checkout"));
        return;
      }
    }

    this.activeTab = nextStep;
    this.saveTravelLocalStorage();
  }

  private focusNextStepButton() {
    setTimeout(() => {
      this.nextStepButton.nativeElement.focus();
    }, 0);
  }
  handleCustomerSubmit() {
    this.focusNextStepButton();
  }

  setFocusField(nextStep: number) {
    if (nextStep == 2) {
      this.travelComponent.focusFirstField();
    }
  }
  ngAfterViewInit() {
    this.loadTravelFromLocalStorage();
    this.cdr.detectChanges();
  }
  saveTravelLocalStorage() {
    const travelData = {
      customer: this.customer,
      passangers: this.passangerList.getPassangers(),
    };
    this.localStorageService.updateKey("travelData", JSON.stringify(travelData));
  }

  loadTravelFromLocalStorage() {
    const savedData = this.localStorageService.loadDataFromLocalStorage<string>("travelData");
    if (savedData) {
      const { customer, passangers } = JSON.parse(savedData);
      this.customer = customer;
      if (this.passangerList) {
        this.passangerList.setPassangers(passangers);
      }
    }
  }

  createDraftTravel() {
    if (!this.travelComponent.checkIfValid()) {
      showMessage(PopupType.Danger, this.translate.instant("travel_checkout.travel_hasnt_been_defined_fully"));
      return;
    }
    if (!this.mainDestination.checkIfValid()) {
      showMessage(PopupType.Danger, this.translate.instant("travel_checkout.main_destination_missing_info"));
      return;
    }
    if (!this.passangerList.checkValidity()) {
      showMessage(PopupType.Danger, this.translate.instant("travel_checkout.passanger_validation_failed"));
      return;
    }

    this.travel = this.travelComponent.getTravel() as TravelDraft;

    const travelDraft = this.createReservationObject();
    this.isLoading = true;
    const createSubscription = this.checkoutService.createTravelDraft({ ...travelDraft, ...travelDraft.plan }).subscribe({
      next: (r: Travel) => {
        this.isLoading = false;
        this.id = r.id;
        showMessage(PopupType.Success, this.translate.instant("travel_created_successfully"));
        createSubscription.unsubscribe();
        this.router.navigate([`dashboard/travels/draft/edit/${this.id}`], {
          queryParams: {
            activeTab: 3,
          },
        });
        this.localStorageService.removeSpecificItem("travelData");
      },
      error: (error) => {
        this.isLoading = false;
        showMessage(PopupType.Danger, error.error);
        createSubscription.unsubscribe();
      },
    });
  }

  isVisibleTab(index: number) {
    return TabVisibilityUtil.isVisibleTab(this.activeTab, index);
  }

  toggleButton(isEditing: boolean) {
    this.isEditingPassanger = isEditing;
  }
  createReservationObject(): any {
    const { start, end, type, days, nights, organizer, departure, avans, eTuristTransportCode, eTuristTransportLabel } = this.travel;

    const { id } = this.customer;

    // Extracting necessary IDs
    const organizerId: number = organizer?.id;
    const departureId: number | undefined = departure ? departure?.id : undefined;

    let passangers = this.passangerList.getPassangers().map((e) => {
      return { customerId: e.id, lifeStage: e.lifeStage, isMainCustomer: e.isMainCustomer };
    });

    const otherDest = this.destinations.getDestinations() || [];

    const mainCustomer = this.passangerList.getPassangers().find((e) => e.mainCustomer);
    if (mainCustomer?.mainCustomer == false) passangers = passangers.filter((e) => e.customerId !== mainCustomer?.id);

    const destinations = [...otherDest, { ...this.mainDestination.getTravel(), isMainDestination: true } as TravelDestination];

    const newReservation = {
      start: DateTimeFormatter.formatDateToISO(start),
      end: DateTimeFormatter.formatDateToISO(end),
      days,
      nights,
      typeId: type?.id,
      eTuristTransportCode: eTuristTransportCode,
      eTuristTransportLabel: eTuristTransportLabel,
      customerId: id,
      numberOfPassangers: passangers.length,
      passangers,
      departureId,
      organizerId,
      plan: {
        avans: avans,
        dueDate: moment().endOf("year"),
        bruto: 1,
        neto: 0,
        customerId: id,
        notes: this.note,
      },
      travelDestinations: destinations.map((p) => {
        const { travelId, destination, hotel, start, end, service, days, nights, roomDescription, isMainDestination, eTuristAccommodationCode, eTuristAccommodationLabel } = p;
        return {
          start: DateTimeFormatter.formatCustomOrISOToISO(start),
          end: DateTimeFormatter.formatCustomOrISOToISO(end),
          days,
          nights,
          roomDescription,
          serviceId: service?.id,
          destinationId: destination?.id,
          hotelId: hotel?.id,
          travelId: travelId,
          isMainDestination,
          eTuristAccommodationCode: eTuristAccommodationCode,
          eTuristAccommodationLabel: eTuristAccommodationLabel,
        };
      }),
    };

    return newReservation;
  }
  formatDate(date: string | undefined): string | undefined {
    if (!date) return undefined;
    const parsedDate = moment(date, "YYYY.MM.DD");
    return parsedDate.isValid() ? parsedDate.format("DD.MM.YYYY") : date;
  }

  returnBack() {
    this.setFocusField(this.activeTab - 1);
    this.activeTab = this.activeTab > 0 ? --this.activeTab : 1;
  }
  onNotify(data: any) {
    this.mainDestination.setStartAndEnd(data);
  }
  clearTravelData() {
    this.localStorageService.removeSpecificItem("travelData");
    this.router.navigate(["dashboard/sales"]);
  }
}
