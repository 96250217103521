<form [formGroup]="form" (ngSubmit)="submitForm()">
  <div [ngClass]="form.controls['senderEmail'].touched ? (form.controls['senderEmail'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
    <label for="senderEmail">{{ "office-branch.senderEmail" | translate }}</label>
    <input type="text" placeholder="{{ 'office-branch.enter_senderEmail' | translate }}" class="form-input" id="senderEmail" formControlName="senderEmail" />
    <ng-container *ngIf="form.controls['senderEmail'].touched && form.controls['senderEmail'].errors">
      <p class="mt-1 text-danger">
        <small>{{ "office-branch.please_provide_senderEmail" | translate }}</small>
      </p>
    </ng-container>
  </div>
  <div [ngClass]="form.controls['senderName'].touched ? (form.controls['senderName'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
    <label for="senderName">{{ "office-branch.senderName" | translate }}</label>
    <input type="text" placeholder="{{ 'office-branch.enter_senderName' | translate }}" class="form-input" id="senderName" formControlName="senderName" />
    <ng-container *ngIf="form.controls['senderName'].touched && form.controls['senderName'].errors">
      <p class="mt-1 text-danger">
        <small>{{ "office-branch.please_provide_senderName" | translate }}</small>
      </p>
    </ng-container>
  </div>
  <div [ngClass]="form.controls['adminEmail'].touched ? (form.controls['adminEmail'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
    <label for="adminEmail">{{ "office-branch.adminEmail" | translate }}</label>
    <input type="text" placeholder="{{ 'office-branch.enter_adminEmail' | translate }}" class="form-input" id="adminEmail" formControlName="adminEmail" />
    <ng-container *ngIf="form.controls['adminEmail'].touched && form.controls['adminEmail'].errors">
      <p class="mt-1 text-danger">
        <small>{{ "office-branch.please_provide_adminEmail" | translate }}</small>
      </p>
    </ng-container>
  </div>
  <div [ngClass]="form.controls['password'].touched ? (form.controls['password'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
    <label for="password">{{ "office-branch.password" | translate }}</label>
    <div class="relative text-white-dark">
      <input [type]="passwordVisible ? 'text' : 'password'" autocomplete="off" placeholder="{{ 'office-branch.enter_password' | translate }}" class="form-input" id="password" formControlName="password" />
      <button type="button" class="absolute inset-y-0 right-0 flex items-start pr-3 pt-2" (click)="togglePasswordVisibility()">
        <icon-eye-closed *ngIf="!passwordVisible; else hideIcon" class="dark:text-white" />
        <ng-template #hideIcon>
          <icon-eye-open class="dark:text-white" />
        </ng-template>
      </button>
    </div>
    <ng-container *ngIf="form.controls['password'].touched && form.controls['password'].errors">
      <p class="mt-1 text-danger">
        <small>{{ "office-branch.please_provide_password" | translate }}</small>
      </p>
    </ng-container>
  </div>

  <div class="mb-4 mt-4">
    <small>
      {{ "office-branch.password_instructions" | translate }}
      <a href="https://knowledge.workspace.google.com/kb/how-to-create-app-passwords-000009237" target="_blank" class="icon-link">
        <icon-new-tab />
      </a>
    </small>
  </div>

  <button type="submit" class="btn btn-primary mt-3 w-full">
    <ng-container *ngIf="loading; else alternateTranslation">
      <span class="inline-block h-3 w-3 animate-ping rounded-full bg-white ltr:mr-4 rtl:ml-4"></span>
      {{ "loading" | translate }}
    </ng-container>
    <ng-template #alternateTranslation>
      {{ "office-branch.save" | translate }}
    </ng-template>
  </button>
</form>
