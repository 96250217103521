import * as moment from "moment";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { SimpleTicket } from "../models/ticket";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { toggleAnimation } from "src/app/shared/animations";
import { TicketsService } from "../services/tickets.service";
import { UtilService } from "src/app/shared/services/util.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { safeParseDate } from "src/app/shared/utils/datetime.formatter";
import { PicklistType, EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { DestinationAutocompleteComponent } from "../../destinations/destination-autocomplete/destination-autocomplete.component";
import { dateFormatValidator, endDateTimeAfterStartDateTimeValidator } from "src/app/shared/validators/date.validator";
@Component({
  selector: "app-ticket-create",
  templateUrl: "./ticket.create.component.html",
  animations: [toggleAnimation],
})
export class TicketCreateComponent implements OnInit, OnDestroy {
  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;

  public id: number | undefined;
  public form!: FormGroup;
  public isSubmitForm = false;
  private oneWaySubscription: Subscription | undefined;
  private routeSubscriber!: Subscription;

  @ViewChild("direction") directionRadio!: ElementRef;
  @ViewChild("destinationAutocomplete") destinationAutocomplete!: DestinationAutocompleteComponent;

  @Input() defaultTicket: SimpleTicket = {} as SimpleTicket;
  @Output() submitTicket: EventEmitter<SimpleTicket> = new EventEmitter<SimpleTicket>();
  ngOnInit(): void {
    this.initFormIntern({} as SimpleTicket);
    const today = moment().format("DD.MM.YYYY");
    this.form.patchValue({
      reservationDate: today,
    });
    this.routeSubscriber = this.route.params.subscribe((params) => {
      this.form.get("type")?.setValue(params["type"]);
    });
  }

  ngOnDestroy() {
    this.oneWaySubscription?.unsubscribe();
    this.routeSubscriber.unsubscribe();
  }

  constructor(public fb: FormBuilder, public route: ActivatedRoute, public store: Store<AppState>, public translate: TranslateService, private ticketService: TicketsService, public utilService: UtilService) {}

  setTicket(ticket: SimpleTicket) {
    this.initFormIntern(ticket);
  }

  public focusFirstField() {
    setTimeout(() => {
      // this.directionRadio.nativeElement.focus(); -- Ne znam da li je bolje ovaj ili ovaj drugi
      this.destinationAutocomplete.focusField();
    });
  }

	onDateBlur(field: string) {
    const control = this.form.get(field);
    if (!control || !control.value) {
      return;
    }

    const inputValue = control.value.replace(/\s+/g, "");

    if (inputValue.length < 8) {
      return;
    }

    const match = inputValue.match(/^(\d{2})(\d{2})(\d{4})(\d{2})?(\d{2})?$/);

    if (!match) {
      return;
    }

    const [, day, month, year, hour = "00", minute = "00"] = match;
    control.setValue(`${day}.${month}.${year} ${hour}:${minute}`);
    control.markAsTouched();
    control.updateValueAndValidity();
  }

  initFormIntern(ticket: SimpleTicket | undefined = undefined) {
    const { id = this.id, oneWay = true, destination, departure, departureDate, arrivalDate, company, reservationDate = moment().format("DD.MM.YYYY"), type } = ticket || {};
    this.id = id;

    const formattedReservationDate = safeParseDate(reservationDate, "DDMMYYYY");
    const formattedDepartureDate = safeParseDate(departureDate, "DDMMYYYYHHmm");
    const formattedArrivalDate = safeParseDate(arrivalDate, "DDMMYYYYHHmm");
    const country = departure?.country || destination?.country || null;
    this.form = this.fb.group(
      {
        oneWay: [oneWay],
        destination: [destination, Validators.required],
        departure: [departure],
        departureDate: [formattedDepartureDate, { validators: [Validators.required], updateOn: "blur" }],
        arrivalDate: [formattedArrivalDate, { validators: [dateFormatValidator()], updateOn: "blur" }],
        company: [company, Validators.required],
        reservationDate: [formattedReservationDate, { validators: [dateFormatValidator()], updateOn: "blur" }],
        type: [type],
        country: [country],
      },
      {
        validators: [endDateTimeAfterStartDateTimeValidator],
      }
    );

    this.oneWaySubscription = this.form.get("oneWay")?.valueChanges.subscribe((value) => {
      const arrivalDateControl = this.form.get("arrivalDate");
      if (arrivalDateControl) {
        if (value) {
          arrivalDateControl.clearValidators();
        } else {
          arrivalDateControl.setValidators([Validators.required, dateFormatValidator()]);
        }
        arrivalDateControl.updateValueAndValidity();
      }
    });
  }

  submitForm() {
    this.isSubmitForm = true;
    this.submitTicket.emit(this.form.getRawValue());
  }

  checkIfValid(): boolean {
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  getTicket(): SimpleTicket {
    return { ...this.form.getRawValue() } as SimpleTicket;
  }

  initForm(ticket: SimpleTicket) {
    this.setTicket(ticket);
  }
}
