import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Invoice } from "../models/invoice";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";

@Injectable({
  providedIn: "root",
})
export class InvoiceService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }
  sendInvoice(id: number): (data: FormData) => Observable<any> {
    return (data: FormData) => {
      data.append("ContractId", id.toString());
      return this.http.post<Invoice>(`${this.apiUrl}/api/Invoices/send`, data);
    };
  }
  generateInvoiceHtml(id: number): Observable<string> {
    return this.http.get(`${this.apiUrl}/api/Invoices/${id}/generate`, { responseType: "text" });
  }

  getTravelInvoice(id: number): Observable<Invoice> {
    return this.http.get<Invoice>(`${this.apiUrl}/api/invoices/Travel/${id}`);
  }

  updateTravelInvoice(id: number, travelId: number): (data: any) => Observable<Invoice> {
    return (data: any) => {
      return this.http.put<Invoice>(`${this.apiUrl}/api/invoices/${id}/${travelId}`, data);
    };
  }
}
