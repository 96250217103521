import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { AppService } from "src/app/service/app.service";
import { ActivatedRoute, Router } from "@angular/router";
import { toggleAnimation } from "src/app/shared/animations";
import { CustomFile, Office } from "../models/office-branch";
import { FileUploadWithPreview } from "file-upload-with-preview";
import { Language } from "src/app/store/language/language.reducer";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OfficeBranchService } from "../services/office-branch.service";
import { LocalStorageService } from "src/app/service/local-storage.service";
import { ManagerInfoFormComponent } from "./first-step/manager-info-form.component";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { selectLanguageList, selectLocale } from "src/app/store/language/language.selector";
import { OfficeBranchMailFormComponent } from "./third-step/office-branch-mail-form.component";
import { OfficeBranchInfoFormComponent } from "./second-step/office-branch-info-form.component";
import { OfficeBranchETuristInfoFormComponent } from "./fourth-step/office-branch-eturist-info-form.component";

@Component({
  selector: "app-office-branch-missing-data-form",
  templateUrl: "./office-branch-first-login.component.html",
  animations: [toggleAnimation],
})
export class OfficeBranchFirstLoginComponent implements OnInit {
  isLoading = false;
  public form!: FormGroup;
  protected existingLogo!: CustomFile | undefined;
  public imageUpload!: FileUploadWithPreview;
  public office!: Office;
  officeBranchId!: number | null;
  locale$!: Observable<string>;
  languageList$!: Observable<{ code: string; name: string }[]>;
  activeTab = 0;

  @ViewChild(ManagerInfoFormComponent) stepOneForm!: ManagerInfoFormComponent;
  @ViewChild(OfficeBranchInfoFormComponent) stepTwoForm!: OfficeBranchInfoFormComponent;
  @ViewChild(OfficeBranchMailFormComponent) stepThreeForm!: OfficeBranchMailFormComponent;
  @ViewChild(OfficeBranchETuristInfoFormComponent) stepFourForm!: OfficeBranchETuristInfoFormComponent;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private appSetting: AppService,
    public storeData: Store<AppState>,
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
    public officeBranchService: OfficeBranchService
  ) {}

  ngOnInit(): void {
    this.locale$ = this.storeData.select(selectLocale);
    this.languageList$ = this.storeData.select(selectLanguageList);
    this.route.params.subscribe((params) => {
      this.officeBranchId = parseInt(params["id"]);
      if (this.officeBranchId !== null) {
        this.officeBranchService.getOfficeById(this.officeBranchId).subscribe((res) => {
          this.office = res;
        });
      }
    });
  }

  changeLanguage(item: Language) {
    this.appSetting.toggleLanguage(item);
    window.location.reload();
  }

  async makeStep(tab: number) {
    if (this.activeTab == 1 && tab == 2) {
      this.isLoading = true;
      this.stepOneForm.submitForm().then((isValid: boolean) => {
        this.isLoading = false;
        if (isValid) {
          this.activeTab = tab;
        }
      });
    } else if (this.activeTab == 2 && tab == 3) {
      this.isLoading = true;
      this.stepTwoForm.submitForm().then((isValid: boolean) => {
        this.isLoading = false;
        if (isValid) {
          this.activeTab = tab;
        }
      });
    } else if (this.activeTab == 3 && tab == 4) {
      this.isLoading = true;
      this.stepThreeForm.submitForm().then((isValid: boolean) => {
        this.isLoading = false;
        if (isValid) {
          this.activeTab = tab;
        }
      });
    } else if (this.activeTab == 4 && tab == 5) {
      this.isLoading = true;
      this.stepFourForm.submitForm().then((isValid: boolean) => {
        this.isLoading = false;
        if (isValid) {
          this.activeTab = tab;
        }
      });
    } else if (tab < this.activeTab) this.activeTab = tab;
    else if (this.activeTab == 0) this.activeTab = 1;
  }

  prevStep() {
    if (this.activeTab > 1) this.activeTab--;
  }

  skipStep(tab: number) {
    this.activeTab = tab;
  }

  logout() {
    this.localStorageService.clearSpecificData();
    this.router.navigate(["/auth/login"]);
  }

  updateOffice(updatedOffice: Office) {
    this.office = updatedOffice;
  }
}
