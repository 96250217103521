import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { slideDownUp, toggleAnimation } from "src/app/shared/animations";
import { OfficeBranchService } from "../services/office-branch.service";
import { Office } from "../models/office-branch";
import { TranslateService } from "@ngx-translate/core";
import { ModalComponent } from "angular-custom-modal";

@Component({
  selector: "app-office-branch-details",
  templateUrl: "./office-branch.details.component.html",
  animations: [toggleAnimation, slideDownUp],
})
export class OfficeBranchDetailsComponent implements OnInit, OnDestroy {
  public id!: number;
  public office!: Office;
  isLoading: boolean = false;
  isPasswordHidden: boolean = true;
  @ViewChild("emailConfigModal") emailConfigModal!: ModalComponent;
  @ViewChild("addOfficeLocationModal") addOfficeLocationModal!: ModalComponent;

  constructor(private route: ActivatedRoute, private officeBranchService: OfficeBranchService, public translate: TranslateService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = parseInt(params["id"]);
      if (this.id > 0) {
        this.fetchBranchData();
      }
    });
  }

  fetchBranchData() {
    this.isLoading = true;
    const subscription = this.officeBranchService.getOfficeById(this.id).subscribe((response) => {
      subscription.unsubscribe();
      this.isLoading = false;
      this.office = response;
    });
  }

  togglePasswordVisibility(): void {
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  closeEmailModal() {
    this.emailConfigModal.close();
    this.fetchBranchData();
  }

  openEmailModal() {
    this.emailConfigModal.open();
  }

  openModalAddingOfficeLocation(id: number) {
    this.addOfficeLocationModal.open();
  }

  closeLocationsModal() {
    this.fetchBranchData();
    this.addOfficeLocationModal.close();
  }
  ngOnDestroy(): void {}
}
