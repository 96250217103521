import * as moment from "moment";
import { Subscription } from "rxjs";
import { Vehicle } from "../models/transfer";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { UtilService } from "src/app/shared/services/util.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TransfersService } from "../services/transfers.service.service";
import { dateTimeFormatValidator } from "src/app/shared/validators/date.validator";
import { EntityPicklistType, PicklistType } from "src/app/shared/enums/picklist-types";
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";

@Component({
  selector: "app-transfer-create",
  templateUrl: "./transfer.create.component.html",
})
export class TransferCreateComponent implements OnInit, OnDestroy, AfterViewInit {
  EntityPicklistType = EntityPicklistType;
  PicklistType = PicklistType;
  id: number | undefined;
  form!: FormGroup;
  vehicles!: Vehicle[];
  selectedVehicle!: Vehicle;
  isLoading = false;
  @ViewChild("nameInput") nameInput!: ElementRef;
  @ViewChildren("formInput") formInputs!: QueryList<ElementRef>;
  @ViewChildren("submitBtn") submitBtn!: ElementRef;
  private oneWaySubscription: Subscription | undefined;
  constructor(public fb: FormBuilder, public route: ActivatedRoute, public translate: TranslateService, private transfersService: TransfersService, public utilService: UtilService, private router: Router) {}
  ngOnInit(): void {
    this.initFormIntern();
    const getTransferPicklist = this.transfersService.getTransfersPicklist().subscribe((res) => {
      getTransferPicklist.unsubscribe();
      this.vehicles = res;
    });
    setTimeout(() => {
      this.nameInput.nativeElement.focus();
      this.nameInput.nativeElement.select();
    }, 0);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.nameInput.nativeElement.focus();
      this.nameInput.nativeElement.select();
    }, 0);
  }

  validateDates(group: FormGroup): { [key: string]: any } | null {
    const departureDate = group.get("departureDate")?.value;
    const arrivalDate = group.get("arrivalDate")?.value;

    if (departureDate && arrivalDate) {
      const departureMoment = moment(departureDate, "DD.MM.YYYY HH:mm");
      const arrivalMoment = moment(arrivalDate, "DD.MM.YYYY HH:mm");

      if (arrivalMoment.isBefore(departureMoment)) {
        return { invalidDates: true };
      }
    }
    return null;
  }
  initFormIntern() {
    this.form = this.fb.group(
      {
        name: ["", Validators.required],
        departureDate: ["", [Validators.required, dateTimeFormatValidator()]],
        arrivalDate: ["", []],
        departure: ["", Validators.required],
        destination: ["", Validators.required],
        vehicle: ["", Validators.required],
        capacity: ["", Validators.required],
        twoWay: [true],
        country: [""],
      },
      { validators: this.validateDates }
    );
    this.oneWaySubscription = this.form.get("twoWay")?.valueChanges.subscribe((value) => {
      const arrivalDateControl = this.form.get("arrivalDate");
      if (arrivalDateControl) {
        if (value) {
          arrivalDateControl.setValidators([Validators.required, dateTimeFormatValidator()]);
        } else {
          arrivalDateControl.clearValidators();
        }
        arrivalDateControl.updateValueAndValidity();
      }
    });
  }
  ngOnDestroy() {
    this.oneWaySubscription?.unsubscribe();
  }

  submitForm() {
    if (this.form.invalid) {
      showMessage(PopupType.Warning, this.translate.instant("transfer.fill_all_fields"));
      this.form.markAllAsTouched();
      return;
    }
    this.addTransfer();
  }

  handleEnter(event: KeyboardEvent, nextElementId: string): void {
    if (event.key === "Enter") {
      event.preventDefault();
      if (nextElementId) {
        const nextElement = document.getElementById(nextElementId);
        if (nextElement) {
          nextElement.focus();
        }
      } else {
        this.submitBtn.nativeElement.focus();
      }
    }
  }
  handleFocus(event: FocusEvent): void {
    const button = event.target as HTMLButtonElement;
    button.classList.add("focused");
  }
  fetchTransfer() {
    const { name, departureDate, arrivalDate, departure, vehicle, destination, capacity, twoWay } = this.form.getRawValue();
    const formattedDepartureDate = moment(departureDate, "DD.MM.YYYY HH:mm").format("YYYY-MM-DDTHH:mm:ss");
    const formattedArrivalDate = arrivalDate ? moment(arrivalDate, "DD.MM.YYYY HH:mm").format("YYYY-MM-DDTHH:mm:ss") : null;
    const country = departure?.country || destination?.country || null;
    const data = {
      name,
      departureDate: formattedDepartureDate,
      arrivalDate: formattedArrivalDate,
      departureId: departure?.id,
      destinationId: destination?.id,
      vehicleOptionId: vehicle?.id,
      capacity,
      twoWay,
      country,
    };
    return data;
  }

  addTransfer() {
    const data = this.fetchTransfer();
    this.isLoading = true;
    const createSub = this.transfersService.createTransfer(data).subscribe({
      next: () => {
        this.isLoading = false;
        createSub.unsubscribe();
        showMessage(PopupType.Success, this.translate.instant("transfer.created_transfer_successfully"));
        this.router.navigate(["/dashboard/transfers"]);
      },
      error: () => {
        this.isLoading = false;
        createSub.unsubscribe();
        showMessage(PopupType.Warning, this.translate.instant("transfer.error_while_creating_transfer"));
      },
    });
  }

	onDateBlur(field: string) {
    const control = this.form.get(field);
    if (!control || !control.value) {
      return;
    }

    const inputValue = control.value.replace(/\s+/g, "");

    if (inputValue.length < 8) {
      return;
    }

    const match = inputValue.match(/^(\d{2})(\d{2})(\d{4})(\d{2})?(\d{2})?$/);

    if (!match) {
      return;
    }

    const [, day, month, year, hour = "00", minute = "00"] = match;
    control.setValue(`${day}.${month}.${year} ${hour}:${minute}`);
    control.markAsTouched();
    control.updateValueAndValidity();
  }
  get twoWay() {
    return this.form?.get("twoWay")?.value;
  }
}
