import * as moment from "moment";
import { Subscription } from "rxjs";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgSelectComponent } from "@ng-select/ng-select";
import { SaleBase } from "src/app/domain/sales/models/sale";
import { StyleUtil } from "src/app/shared/utils/style.util";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Travel } from "src/app/domain/travels/models/travel";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { CheckoutService } from "../../services/checkout.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { TabVisibilityUtil } from "src/app/shared/tab-visibility.util";
import { Organizer } from "src/app/domain/organizers/models/organizers";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Customer, PassangerTypes } from "../../../domain/customers/models/customer";
import { TravelDestination } from "src/app/domain/travels/models/travel-destination";
import { EntityPicklistType, PicklistType } from "src/app/shared/enums/picklist-types";
import { SaleCreateComponent } from "src/app/domain/sales/sale.create/sale.create.component";
import { TravelCreateComponent } from "src/app/domain/travels/travel.create/travel.create.component";
import { PassangersListComponent } from "src/app/domain/passangers/passangers.list/passangers.list.component";
import { DestinationsListComponent } from "src/app/domain/destinations/destinations.list/destinations.list.component";
import { TravelDestinationCreateComponent } from "src/app/domain/travels/travel-destination.create/travel-destination.create.component";

@Component({
  templateUrl: "./travel-checkout.edit.component.html",
})
export class TravelCheckoutEditComponent implements OnInit, OnDestroy {
  @ViewChild("mainDestination") mainDestination!: TravelDestinationCreateComponent;
  @ViewChild("destinations") destinations!: DestinationsListComponent;
  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("travelBase") travelComponent!: TravelCreateComponent;
  @ViewChild("sale") saleComponent!: SaleCreateComponent;
  @ViewChild("nextStepButton") nextStepButton!: ElementRef;
  @ViewChild("confirmationNumberSelect") confirmationNumberSelect!: NgSelectComponent;

  public allPassangers: Customer[] = [];
  organizer!: Organizer;
  public activeTab = 1;
  note!: string;
  public id!: number;
  public sale!: SaleBase;
  public travel!: Travel;
  public customer!: Customer;
  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;
  currentPlan!: SaleBase;
  isLoading = false;

  public tabSizeHandler = StyleUtil.getTabWidth;
  private routeSubscribtion!: Subscription;
  private routeDoubleSubscription!: Subscription;
  @ViewChild("confirmationModal") confirmationModal!: ModalComponent;
  confirmationNumber!: string;

  constructor(private router: Router, private route: ActivatedRoute, public checkoutService: CheckoutService, public translate: TranslateService, private modalService: ModalService) {}

  ngOnInit() {
    this.routeSubscribtion = this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.routeDoubleSubscription = this.route.queryParams.subscribe((params) => {
        const activeTab = parseInt(params["activeTab"]);
        this.activeTab = activeTab;
        if (this.router.url.includes("draft/edit") || this.router.url.includes("draft/accept")) {
          this.fetchEntities();
        }
      });
    });
  }

  ngOnDestroy() {
    this.routeSubscribtion.unsubscribe();
    this.routeDoubleSubscription.unsubscribe();
  }

  fetchEntities() {
    const fetchSubscription = this.checkoutService.fetchTravel(this.id).subscribe((response) => {
      fetchSubscription.unsubscribe();
      const { passangers: customerPassagers, customer, plan, travelDestinations, travelPassagers: passangers } = response;
      const { notes } = plan;

      const customerList = passangers.filter((p) => p.customer && p.customer.id !== customer.id).map((p) => ({ ...p.customer, lifeStage: p.lifeStage, isMainCustomer: p.isMainCustomer }));
      this.passangerList.push(customerList);

      this.customer = {
        ...customer,
        lifeStage: PassangerTypes.adults,
        guid: crypto.randomUUID(),
        imageUrl: "/assets/images/man.svg",
        mainCustomer: true,
        isMainCustomer: passangers.find((p) => p.customerId === customer.id)?.isMainCustomer,
      } as Customer;

      if (this.passangerList) {
        this.passangerList.setMainCustomer(this.customer);
      }

      this.currentPlan = plan;
      this.saleComponent.initForm(DateTimeFormatter.formatPlanDates({ ...plan, customer: customer, customerId: customer.id }));
      this.sale = this.saleComponent.getSale();
      this.note = notes ?? "";

      this.travelComponent.initForm(DateTimeFormatter.formatTravelDates(response));
      this.travel = this.travelComponent.getTravel();

      const mainDestination = travelDestinations.find((t) => t.isMainDestination) ?? ({} as TravelDestination);
      this.mainDestination.initForm(mainDestination);

      this.destinations.push(travelDestinations.filter((t) => !t.isMainDestination));
    });
  }

  onNotify(data: any) {
    this.mainDestination.setStartAndEnd(data);
  }

  isVisibleTab(index: number) {
    return TabVisibilityUtil.isVisibleTab(this.activeTab, index);
  }
  createReservationObject(): any {
    this.sale = this.saleComponent.getSale();
    const { start, end, type, days, nights, organizer, departure, eTuristTransportCode, eTuristTransportLabel } = this.travel;
    const { bruto, neto, avans, dueDate, paymentTypeId, status } = this.sale;
    const { id } = this.customer;
    const isoDueDate = DateTimeFormatter.formatDateToISO(dueDate);

    // Extracting necessary IDs
    const organizerId: number = organizer?.id;
    const departureId: number | undefined = departure ? departure?.id : undefined;

    let passangers = this.passangerList.getPassangers().map((e) => {
      return { customerId: e.id, lifeStage: e.lifeStage, isMainCustomer: e.isMainCustomer };
    });

    const mainCustomer = this.passangerList.getPassangers().find((e) => e.mainCustomer);
    if (mainCustomer?.mainCustomer == false) passangers = passangers.filter((e) => e.customerId !== mainCustomer?.id);

    if (this.sale.withAvans === 0) {
      this.sale.avans = undefined;
    }
    const otherDest = this.destinations.getDestinations() || [];
    const destinations = [...otherDest, { ...this.mainDestination.getTravel(), isMainDestination: true } as TravelDestination];

    const newReservation = {
      start: DateTimeFormatter.formatDateToISO(start),
      end: DateTimeFormatter.formatDateToISO(end),
      days,
      nights,
      typeId: type?.id,
      eTuristTransportCode: eTuristTransportCode,
      eTuristTransportLabel: eTuristTransportLabel,
      customerId: id,
      numberOfPassangers: passangers.length,
      plan: { bruto, neto, avans, dueDate: isoDueDate, customerId: mainCustomer?.id, notes: this.note, paymentTypeId, entityState: this.activeTab === 4 ? 0 : 1, status: status },
      planId: this.sale.id,
      passangers,
      departureId,
      organizerId,
      travelDestinations: destinations.map((p) => {
        const { id, travelId, destination, hotel, start, end, service, days, nights, roomDescription, isMainDestination, eTuristAccommodationCode, eTuristAccommodationLabel } = p;
        return {
          id,
          start: DateTimeFormatter.formatCustomOrISOToISO(start),
          end: DateTimeFormatter.formatCustomOrISOToISO(end),
          days,
          nights,
          roomDescription,
          serviceId: service?.id,
          destinationId: destination?.id,
          hotelId: hotel?.id,
          travelId: travelId,
          isMainDestination: isMainDestination,
          eTuristAccommodationCode: eTuristAccommodationCode,
          eTuristAccommodationLabel: eTuristAccommodationLabel,
        };
      }),
      organizerConfirmationNumber: this.confirmationNumber,
    };
    return newReservation;
  }
  formatDate(date: string | undefined): string | undefined {
    if (!date) return undefined;

    const parsedDate = moment(date, "YYYY.MM.DD");
    return parsedDate.isValid() ? parsedDate.format("DD.MM.YYYY") : date;
  }

  updateTravelDraft() {
    if (!this.validateTravel()) {
      return;
    }
    this.travel = this.travelComponent.getTravel();
    const travelDraft = this.createReservationObject();
    this.isLoading = true;

    const updateSub = this.checkoutService.updateTravelDraft(this.id, { ...travelDraft, ...travelDraft.plan }).subscribe({
      next: () => {
        this.isLoading = false;
        showMessage(PopupType.Success, this.translate.instant("travel_edited_successfully"));
        updateSub.unsubscribe();

        if (this.activeTab === 4) {
          this.router.navigate(["/dashboard/sales"]);
        } else {
          this.proceedToNextStage(this.activeTab + 1);
        }
      },
      error: (error) => {
        this.isLoading = false;
        updateSub.unsubscribe();
        showMessage(PopupType.Danger, error);
      },
    });
  }

  handleVoucherSent() {
    this.proceedToNextStage(this.activeTab);
  }
  handleConfirmationNumber(number: string) {
    this.confirmationNumber = number;
    if (this.confirmationNumber) {
      this.confirmationModal.close();
      this.saleComponent.setFocusOnNeto();
    }
  }

  proceedToNextStage(nextStep: number) {
    this.activeTab = nextStep ?? this.activeTab + 1;
    if (this.activeTab === 4) {
      this.showConfirmationModal();
    }
  }
  showConfirmationModal() {
    this.confirmationModal.open();
  }

  private focusNextStepButton() {
    setTimeout(() => {
      this.nextStepButton.nativeElement.focus();
    }, 0);
  }
  handleCustomerSubmit() {
    this.focusNextStepButton();
  }

  returnBack() {
    this.activeTab = this.activeTab - 1;
    if (this.activeTab == 3) {
      this.activeTab = 2;
    }
  }
  async sendInvoice() {
    const confirm = await this.modalService.openModalForSendingInovice({
      title: this.translate.instant("send.avans"),
      message: this.translate.instant("confirm.send_avans"),
      confirmButtonText: this.translate.instant("yes"),
      cancelButtonText: this.translate.instant("no"),
      plan: this.currentPlan,
      navigateToRoute: `dashboard/travels/draft/edit/${this.id}`,
      activeTab: 3,
      isOnlyAvans: false,
      paymentId: undefined,
      entityDetails: undefined,
    });
    if (confirm) {
      showMessage(PopupType.Success, this.translate.instant("popup.avans_sent_successfully"));
    }
  }
  getButtonLabel(): string {
    return this.activeTab === 4 ? "travel_checkout.complete_sale" : "travel_checkout.next";
  }
  private validateTravel(): boolean {
    const travel = this.travelComponent.getTravel();
    const { start, end, organizer, departure, type, days, nights } = travel;

    if (!start || !end || !organizer || !departure || !days || !nights) {
      showMessage(PopupType.Danger, this.translate.instant("travel_checkout.fill_all_travel_details"));
      return false;
    }

    if (!this.mainDestination.checkIfValid()) {
      showMessage(PopupType.Danger, this.translate.instant("travel_checkout.main_destination_missing_info"));
      return false;
    }

    if (!this.passangerList.checkValidity()) {
      showMessage(PopupType.Danger, this.translate.instant("travel_checkout.passanger_validation_failed"));
      return false;
    }

    if (this.activeTab !== 2 && this.activeTab !== 3) {
      if (!this.saleComponent.checkIfValid()) {
        showMessage(PopupType.Danger, this.translate.instant("travel_checkout.sale_object_not_valid"));
        return false;
      }

      const { bruto, neto, dueDate, paymentTypeId, avans } = this.saleComponent.getSale();
      if (!bruto || !neto || !dueDate || !paymentTypeId) {
        showMessage(PopupType.Danger, this.translate.instant("travel_checkout.fill_all_sale_details"));
        return false;
      }

      if (this.saleComponent.withAvans && !avans) {
        showMessage(PopupType.Danger, this.translate.instant("travel_checkout.specify_advance_payment"));
        return false;
      }
    }
    const passangers = this.passangerList.getPassangers();
    if (passangers.length === 0) {
      showMessage(PopupType.Danger, this.translate.instant("travel_checkout.add_at_least_one_passenger"));
      return false;
    }

    return true;
  }
}
