import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { CustomFile, Office } from "../../models/office-branch";
import { FileUploadWithPreview } from "file-upload-with-preview";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { slideDownUp, toggleAnimation } from "src/app/shared/animations";
import { OfficeBranchService } from "../../services/office-branch.service";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-office-branch-info-form",
  templateUrl: "./office-branch-info-form.component.html",
  animations: [toggleAnimation, slideDownUp],
})
export class OfficeBranchInfoFormComponent implements OnChanges {
  public form!: FormGroup;
  public imageUpload!: FileUploadWithPreview;
  protected existingLogo!: CustomFile | undefined;
  @Output() officeChange = new EventEmitter<Office>();
  @Input() office?: Office;
  private hasInitialized: boolean = false;

  constructor(private officeBranchService: OfficeBranchService, public translate: TranslateService, public fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["office"] && this.office && !this.hasInitialized) {
      this.initForm(this.office);
      this.hasInitialized = true;
    }
  }

  initForm(office: Office): void {
    const { name, licence, logoImage, siteURL: siteUrl, bankAccount, pib, fullAddress, mb } = office || ({} as Office);
    this.existingLogo = logoImage;
    this.form = this.fb.group({
      name: [name, Validators.required],
      bankAccount: [bankAccount, Validators.required],
      PIB: [pib, Validators.required],
      MB: [mb, Validators.required],
      licence: [licence, [Validators.required]],
      siteUrl: [siteUrl, Validators.required],
      fullAddress: [fullAddress, Validators.required],
    });

    if (!logoImage) {
      setTimeout(() => {
        this.initializeFileUploader();
      });
    }
  }

  removeImage(e: Event) {
    e.preventDefault();
    this.existingLogo = undefined;

    setTimeout(() => {
      this.initializeFileUploader();
    });
  }

  uploadClick(e: Event) {
    e.stopPropagation();
    const clickedElement = e.target as HTMLElement;
    if (clickedElement.className === "image-preview") {
      this.imageUpload.emulateInputSelection();
    }
  }

  initializeFileUploader() {
    this.imageUpload = new FileUploadWithPreview("office-branch-logo", {
      maxFileCount: 1,
      presetFiles: [],

      images: {
        baseImage: "/assets/images/file-preview.svg",
      },
      accept: "image/*",
      text: {
        browse: this.translate.instant("click_to_upload_logo"),
        chooseFile: this.translate.instant("click_to_choose_logo"),
        label: this.translate.instant("click_to_upload_your_logo"),
      },
      showDeleteButtonOnImages: true,
    });
  }

  async submitForm(): Promise<boolean> {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    return new Promise((resolve) => {
      if (this.office) {
        const updateSubscription = this.officeBranchService.updateOffice(this.office.id, this.fetchOffice()).subscribe({
          next: (response) => {
            updateSubscription.unsubscribe();
            showMessage(PopupType.Success, this.translate.instant("popup.office_branch_updated_successfully"));
            resolve(true);

            if (this.imageUpload && this.imageUpload.cachedFileArray.length > 0) {
              const uploadImgSubscription = this.officeBranchService.uploadOfficeLogo(response.id, this.imageUpload.cachedFileArray[0]).subscribe({
                next: () => {
                  uploadImgSubscription.unsubscribe();
                  showMessage(PopupType.Success, this.translate.instant("popup.office_branch_image_updated_successfully"));
                },
                error: () => {
                  uploadImgSubscription.unsubscribe();
                  showMessage(PopupType.Danger, this.translate.instant("popup.office_branch_image_updated_successfully"));
                },
              });
              this.officeChange.emit({ ...this.office, ...this.fetchOffice() });
            } else {
              this.officeChange.emit({ ...this.office, ...this.fetchOffice() });
            }
          },
          error: (error) => {
            updateSubscription.unsubscribe();
            showMessage(PopupType.Danger, error.error.toString());
            resolve(false);
          },
        });
      }
    });
  }

  fetchOffice(): Office {
    return {
      ...this.office,
      ...this.form.getRawValue(),
    } as Office;
  }
}
