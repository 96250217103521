import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, map, Observable } from "rxjs";
import { EtouristCheckResponse } from "../models/generics";
import { BaseHttpService } from "src/app/service/base.http.service";
import {
  ETouristCity,
  ETouristCodebookEntry,
  ETouristMunicipality,
  ETouristPDFDocumentInfoResponseDto,
  ETouristPlanResponseToReturnDto,
  ETouristSearchTravelSaleDto,
  ETouristStreet,
  ETouristTravelSale,
} from "../models/etourist";

@Injectable({
  providedIn: "root",
})
export class ETouristService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }
  private availabilitySubject = new BehaviorSubject<boolean>(false);

  // Observable to access the shared state
  getAvailability(): Observable<boolean> {
    return this.availabilitySubject.asObservable();
  }

  // Optionally expose a way to get the latest value synchronously
  isAvailable(): boolean {
    return this.availabilitySubject.getValue();
  }

  checkAvailability(): Observable<boolean> {
    return this.http.get<EtouristCheckResponse>(`${this.apiUrl}/api/ETourist/credentials/check`).pipe(
      map((response) => {
        this.availabilitySubject.next(response.check);
        return response.check;
      })
    );
  }

  getMunicipality(): Observable<ETouristMunicipality[]> {
    return this.http.get<ETouristMunicipality[]>(`${this.apiUrl}/api/ETourist/municipalities`);
  }

  getCities(municipalitiesId: number): Observable<ETouristCity[]> {
    return this.http.get<ETouristCity[]>(`${this.apiUrl}/api/ETourist/localities/${municipalitiesId}`);
  }

  getStreets(cityId: number): Observable<ETouristStreet[]> {
    return this.http.get<ETouristStreet[]>(`${this.apiUrl}/api/ETourist/streets/${cityId}`);
  }

  getCurrecy(): Observable<ETouristCodebookEntry[]> {
    return this.http.get<ETouristCodebookEntry[]>(`${this.apiUrl}/api/ETourist/codebook/4`);
  }

  getTransport(): Observable<ETouristCodebookEntry[]> {
    return this.http.get<ETouristCodebookEntry[]>(`${this.apiUrl}/api/ETourist/codebook/5`);
  }

  getAccomodation(): Observable<ETouristCodebookEntry[]> {
    return this.http.get<ETouristCodebookEntry[]>(`${this.apiUrl}/api/ETourist/codebook/6`);
  }

  getTravelPlans(): Observable<ETouristPlanResponseToReturnDto[]> {
    return this.http.get<ETouristPlanResponseToReturnDto[]>(`${this.apiUrl}/api/ETourist/travels/plans`);
  }

  downloadETuristConfirmation(eTuristRecordId: string | null): Observable<ETouristPDFDocumentInfoResponseDto | null> {
    if (!eTuristRecordId) {
      return new Observable();
    }
    return this.http.get<ETouristPDFDocumentInfoResponseDto>(`${this.apiUrl}/api/ETourist/travels/pdf/${eTuristRecordId}`);
  }

  sendPdf(id: string, emails: string[]): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/api/ETourist/travels/pdf/${id}/send`, { emails });
  }

  deleteRecordFromETurist(id: number) {
    return this.http.delete(`${this.apiUrl}/api/ETourist/travels/${id}/delete`);
  }
  searchTravelSale(searchTravelSaleDto: ETouristSearchTravelSaleDto): Observable<ETouristTravelSale[]> {
    return this.http.post<ETouristTravelSale[]>(`${this.apiUrl}/api/ETourist/travels/search`, searchTravelSaleDto);
  }
  deleteGroupTravelPlanFromETurist(id: number) {
    return this.http.delete(`${this.apiUrl}/api/ETourist/grouptravelplans/${id}/delete`);
  }
}
