import { NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { HasRoleGuard } from "./guards/has-role.guard";

//  Routes
import { routes } from "./app.route";

import { AppComponent } from "./app.component";

// service
import { AppService } from "./service/app.service";

// store
import { StoreModule } from "@ngrx/store";
import { dateTimeReducer } from "./store/datepicker/datepicker.reducer";

// i18n
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// headlessui
import { MenuModule } from "headlessui-angular";

// perfect-scrollbar
import { NgScrollbarModule } from "ngx-scrollbar";

// dashboard
import { IndexComponent } from "./index";

// Layouts
import { AppLayoutComponent } from "./layouts/app-layout";
import { AuthLayoutComponent } from "./layouts/auth-layout";

import { HeaderComponent } from "./layouts/header";
import { FooterComponent } from "./layouts/footer";
import { SidebarComponent } from "./layouts/sidebar";
import { IconModule } from "./shared/icon/icon.module";

import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { DataTableModule } from "@bhplugin/ng-datatable";
import { TravelsTableComponent } from "./domain/travels/travels.table/travels.table.component";
import { HotelsTableComponent } from "./domain/hotels/hotels.table/hotels.table.component";
import { OrganizersTableComponent } from "./domain/organizers/organizers.table/organizers.table.component";
import { DestinationsTableComponent } from "./domain/destinations/destinations.table/destinations.table.component";
import { CompaniesTableComponent } from "./domain/companies/companies.table/companies.table.component";
import { SalesTableComponent } from "./domain/sales/sales.table/sales.table.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { authReducer } from "./store/auth/auth.reducer";
import { JwtInterceptor } from "./interceptors/jwt.interceptor";
import { ErrorInterceptor } from "./interceptors/error.interceptor";
import { HotelComponent } from "./domain/hotels/hotel/hotel.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { CompanyComponent } from "./domain/companies/company/company.component";
import { OrganizerComponent } from "./domain/organizers/organizer/organizer.component";
import { DestinationComponent } from "./domain/destinations/destination/destination.component";
import { GroupTravelsTableComponent } from "./domain/group-travels/group-travels.table/group-travels.table.component";
import { WorkersComponent } from "./domain/workers/worker/worker.component";
import { WorkersOfficesTableComponent } from "./domain/workers/workers-offices.table/workers-offices.table.component";
import { CalendarComponent } from "./domain/calendar/calendar";
import { FullCalendarModule } from "@fullcalendar/angular";
import { ModalModule } from "angular-custom-modal";
import { TravelCheckoutEditComponent } from "./checkouts/travel/travel-checkout.edit/travel-checkout.edit.component";
import { CustomerCreateComponent } from "./domain/customers/customer.create/customer.create.component";
import { TravelCreateComponent } from "./domain/travels/travel.create/travel.create.component";
import { SelectRemoteComponent } from "./shared/components/select-remote/select-remote.component";
import { AutocompleteRemoteComponent } from "./shared/components/autocomplete-remote/autocomplete-remote.component";
import { TicketCheckoutComponent } from "./checkouts/ticket/ticket.checkout.component";
import { TicketCreateComponent } from "./domain/tickets/ticket.create/ticket.create.component";
import { SaleCreateComponent } from "./domain/sales/sale.create/sale.create.component";
import { NumbersOnlyDirective } from "./shared/directives/numbers.only";
import { NgxNumberSpinnerModule } from "ngx-number-spinner";
import { NgxTippyModule } from "ngx-tippy-wrapper";
import { GroupTravelComponent } from "./domain/group-travels/group-travel/group-travel.component";
import { GroupDestinationsFormComponent } from "./domain/destinations/group-destination.form/group-destinations.form.component";
import { TravelDetailsComponent } from "./domain/travels/travel.details/travel.details.component";
import { TicketDetailsComponent } from "./domain/tickets/ticket.details/ticket.details.component";
import { TransfersTableComponent } from "./domain/transfers/transfers.table/transfers.table.component";
import { TransfersDetailsComponent } from "./domain/transfers/transfers.details/transfers.details.component";
import { TransferComponent } from "./domain/transfers/transfer/transfer.component";
import { TravelDestinationCreateComponent } from "./domain/travels/travel-destination.create/travel-destination.create.component";
import { WorkerFormComponent } from "./domain/workers/worker.form.component/worker.form.component";
import { HorizontalGraphComponent } from "./shared/components/horizontal-graph/horizontal-graph";
import { StatisticsComponent } from "./shared/components/statistics/statistics";
import { SimpleColumnComponent } from "./shared/components/simple-column/simple-column";
import { QuillModule } from "ngx-quill";
import { DailySaleComponent } from "./domain/sales/daily-sale/daily-sale.component";
import { PassangersListComponent } from "./domain/passangers/passangers.list/passangers.list.component";
import { GroupTravelDetailsComponent } from "./domain/group-travels/group-travel.details/group-travel.details.component";
import { DailySaleDocumentComponent } from "./domain/sales/daily-sale/daily-sale.document/daily-sale.document.component";
import { DailySaleEditComponent } from "./domain/sales/daily-sale/daily-sale.edit/daily-sale.edit.component";
import { SettingsListComponent } from "./domain/settings/settings-list/settings-list.component";
import { SettingsCreateComponent } from "./domain/settings/settings.create/settings.create.component";
import { TransferCreateComponent } from "./domain/transfers/transfer.create/transfer.create.component";
import { TransferEditComponent } from "./domain/transfers/transfer.edit/transfer.edit.component";
import { TransferPlanComponent } from "./domain/transfers/transfer-plan/transfer-plan.component";
import { VoucherEditComponent } from "./domain/documents/voucher/voucher.edit/voucher.edit.component";
import { BookComponent } from "./domain/evidence-book/book/book.component";
import { TicketsTableComponent } from "./domain/tickets/tickets.table/tickets.table";
import { DraftTableComponent } from "./domain/sales/draft.table/draft.table.component";
import { GroupTravelPlanComponent } from "./domain/group-travels/group-travel-plan/group-travel-plan.component";
import { TransferPassangersTableComponent } from "./domain/transfers/transfer-passanger-table/transfer-passanger-table.component";
import { AngJson2excelBtnModule } from "ang-json2excel-btn";
import { GroupTravelPassangersTableComponent } from "./domain/group-travels/group-travel-passangers.table/group-travel-passangers.table.component";
import { PassengersTableComponent } from "./domain/transfers/passengers-table/passengers-table.component";
import { SaleDetailsComponent } from "./domain/sales/sale.details/sale.details.component";
import { OfficeBranchTableComponent } from "./domain/office-branch/office-branch.table/office-branch.table.component";
import { OfficeBranchFormComponent } from "./domain/office-branch/office-branch.form.component/office-branch.form.component";
import { EtouristCheckerComponent } from "./layouts/etourist-checker";
import { OfficeBranchLocationFormComponent } from "./domain/office-branch/office-branch-location.form.component/office-branch-location.form.component";
import { PaymentFormComponent } from "./domain/sales/payment.form/payment.form.component";
import { CustomersTableComponent } from "./domain/customers/customer.table/customers.table.component";
import { BreadcrumbsComponent } from "./shared/components/breadcrumbs/breadcrumbs";
import { CustomerStatsComponent } from "./domain/customers/customer.stats/customer.stats.component";
import { StatisticsMoneyComponent } from "./domain/statisticsmoney/statisticsmoney.component";
import { ChartStatisticsComponent } from "./domain/statisticsmoney/chart-statistics/chart-statistics.component";
import { UserProfileComponent } from "./domain/user-profile/user-profile/user-profile.component";
import { DynamicIconComponent } from "./shared/components/dynamic-icon/dynamic-icon";
import { GlobalTableFiltersComponent } from "./shared/components/global-table-filters/global-table-filters.component";
import { ETouristTableComponent } from "./domain/e-tourist/e-tourist.table/e-tourist.table.component";
import { AnnouncementComponent } from "./domain/travels/announcement/announcement.component";
import { OrganizerStatisticsComponent } from "./domain/organizers/organizer-statistics/organizer-statistics.component";
import { OrganizerStatisticsProfitComponent } from "./domain/organizers/organizer-statistics-profit/organizer-statistics-profit.component";
import { StatisticsChartComponent } from "./shared/components/statistics-chart/statistics-chart.component";
import { YearlyEarningsChartComponent } from "./shared/components/yearly-earnings-chart/yearly-earnings-chart.component";
import { YearlyDebtChartComponent } from "./shared/components/yearly-debt-chart/yearly-debt-chart.component";
import { MontlyEarningsChartComponent } from "./shared/components/montly-earnings-chart/montly-earnings-chart.component";
import { MontlyDebtChartComponent } from "./shared/components/montly-debt-chart/montly-debt-chart.component";
import { DailyEarningsChartComponent } from "./shared/components/daily-earnings-chart/daily-earnings-chart.component";
import { DailyDebtChartComponent } from "./shared/components/daily-debt-chart/daily-debt-chart.component";
import { DailyMoneyChartComponent } from "./shared/components/daily-money-chart/daily-money-chart.component";
import { MontlyMoneyChartComponent } from "./shared/components/montly-money-chart/montly-money-chart.component";
import { OtherServicesTableComponent } from "./domain/other-services/other-services.table/other-services-table.component";
import { OtherServicesDetailsComponent } from "./domain/other-services/other-services.details/other-services.details.component";
import { ExportToExcelComponent } from "./shared/components/export-to-excel/export-to-excel.component";
import { AuthModule } from "./auth/auth.module";
import { HasIdGuard } from "./guards/has-id.guard";
import { IconMenuOtherServicesComponent } from "./shared/icon/menu/icon-menu-other-services";
import { TagInputModule } from "ngx-chips";
import { ExportTableComponent } from "./shared/components/export-table/export-table.component";
import { GroupTravelVoucherEditComponent } from "./domain/group-travels/group-travel-voucher-edit/group-travel-voucher-edit.component";
import { GroupTravelContractComponent } from "./domain/group-travels/group-travel-contract/group-travel-contract.component";
import { GroupTravelContractEditComponent } from "./domain/group-travels/group-travel-contract-edit/group-travel-contract-edit.component";
import { CountryChartComponent } from "./domain/statisticsmoney/country-chart/country-chart.component";
import { TravelContractComponent } from "./domain/travels/travel-contract/travel-contract.component";
import { TravelContractEditComponent } from "./domain/travels/travel-contract.edit/travel-contract.edit.component";
import { ContractComponent } from "./domain/documents/contract/contract/contract.component";
import { ContractEditComponent } from "./domain/documents/contract/contract.edit/contract.edit.component";
import { GroupTravelVoucherListComponent } from "./domain/group-travels/group-travel-voucher-list/group-travel-voucher-list.component";
import { TravelVouchersComponent } from "./domain/travels/travel-vouchers/travel-vouchers.component";
import { VoucherListComponent } from "./domain/documents/voucher/voucher-list/voucher-list.component";
import { VoucherComponent } from "./domain/documents/voucher/voucher/voucher.component";
import { FileSendComponent } from "./domain/documents/common/file-send/file-send.component";
import { VoucherTemplateComponent } from "./domain/documents/voucher/voucher/voucher-template/voucher-template.component";
import { TravelCheckoutCreateComponent } from "./checkouts/travel/travel-checkout.create/travel-checkout.create.component";
import { DailyActionsComponent } from "./domain/daily-actions/daily-actions.component";
import { MessagePortalComponent } from "./portal/portal";
import { PortalModule } from "@angular/cdk/portal";
import { FileHistoryComponent } from "./shared/components/file-history/file-history.component";
import { TravelVoucherEditComponent } from "./domain/travels/travel-voucher.edit/travel-voucher.edit.component";
import { TicketQuickViewComponent } from "./domain/quick-views/ticket-quick-view/ticket-quick-view.component";
import { TravelQuickViewComponent } from "./domain/quick-views/travel-quick-view/travel-quick-view.component";
import { TransferQuickViewComponent } from "./domain/quick-views/transfer-quick-view/transfer-quick-view.component";
import { OtherServicesQuickViewComponent } from "./domain/quick-views/other-services-quick-view/other-services-quick-view.component";
import { GroupTravelQuickViewComponent } from "./domain/quick-views/group-travel-quick-view/group-travel-quick-view.component";
import { WorkerSalesByMonthComponent } from "./domain/workers/worker-sales-by-month/worker-sales-by-month.component";
import { OverallStatisticsComponent } from "./shared/components/overall-statistics/overall-statistics.component";
import { ConfirmationDialogComponent } from "./shared/components/confirmation-dialog/confirmation-dialog.component";
import { TransferPlanQuickviewComponent } from "./domain/quick-views/transfer-plan-quickview/transfer-plan-quickview.component";
import { GroupTravelPlanQuickviewComponent } from "./domain/quick-views/group-travel-plan-quickview/group-travel-plan-quickview.component";
import { DailyWorkerSummaryComponent } from "./domain/daily-actions/daily-worker-summary/daily-worker-summary.component";
import { UserNewPasswordComponent } from "./domain/user-profile/user-new-password/user-new-password.component";
import { DetailsDropdownComponent } from "./shared/components/details-dropdown/details-dropdown.component";
import { PackageRightsGuard } from "./guards/has-package-rights.guard";
import { WorkersTableComponent } from "./domain/workers/workers.table/workers.table.component";
import { InvoiceComponent } from "./shared/components/invoice/invoice.component";
import { DestinationAutocompleteComponent } from "./domain/destinations/destination-autocomplete/destination-autocomplete.component";
import { MergeComponent } from "./shared/components/merge/merge.component";
import { OrganizerFormComponent } from "./domain/organizers/organizer-form/organizer-form.component";
import { EditDestinationComponent } from "./domain/destinations/edit-destination/edit-destination.component";
import { CustomerCreateEditComponent } from "./domain/customers/customer-create-edit/customer-create-edit.component";
import { DatepickerComponent } from "./shared/components/datepicker/datepicker.component";
import { Error404Component } from "./shared/components/error404/error404";
import { OfficeBranchListComponent } from "./domain/office-branch/office-branch.list/office-branch.list.component";
import { GroupTravelAddPassangerComponent } from "./domain/group-travels/group-travel-add-passanger/group-travel-add-passanger.component";
import { GroupTravelPlanEditComponent } from "./domain/group-travels/group-travel-plan-edit/group-travel-plan-edit.component";
import { TransferPlanEditComponent } from "./domain/transfers/transfer-plan-edit/transfer-plan-edit.component";
import { OtherServicesCreateComponent } from "./domain/other-services/other-services.create/other-services-create.component";
import { OtherServicesEditComponent } from "./domain/other-services/other-services.edit/other-services.edit.component";
import { DestinationsListComponent } from "./domain/destinations/destinations.list/destinations.list.component";
import { CompanyFormComponent } from "./domain/companies/company-form/company-form.component";
import { HotelFormComponent } from "./domain/hotels/hotel-form/hotel-form.component";
import { TravelCheckoutEditTravelComponent } from "./checkouts/travel/travel-checkout.edit.travel/travel-checkout-edit-travel.component";
import { AnnouncementEditComponent } from "./domain/travels/announcement/announcement.edit/announcement.edit.component";
import { languageReducer } from "./store/language/language.reducer";
import { LanguageInterceptor } from "./interceptors/language.interceptor";
import { ThemeCustomizerComponent } from "./layouts/theme-customizer";
import { layoutReducer } from "./store/layout/layout.reducer";
import { AccordionComponent } from "./shared/components/accordion-item/accordion-item.component";
import { LayoutEffects } from "./shared/services/effect.service";
import { EffectsModule } from "@ngrx/effects";
import { DirectivesModule } from "./directives/directives.module";
import { LayoutComponent } from "./domain/settings/layout/layout.component";
import { DangerZoneComponent } from "./domain/settings/danger-zone/danger-zone.component";
import { GroupTravelPlanDetailsComponent } from "./domain/group-travels/group-travel-plan-details/group-travel-plan-details.component";
import { TransfersPlanDetailsComponent } from "./domain/transfers/transfers-plan-details/transfers-plan-details.component";
import { IconArrowRightComponent } from "./shared/icon/icon-arrow-right";
import { HasBranchData } from "./guards/has-branch-data";
import { OfficeBranchFirstLoginComponent } from "./domain/office-branch/office-branch-first-login/office-branch-first-login.component";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { DynamicEntityIconComponent } from "./shared/components/dynamic-entity-icon/dynamic-entity-icon";
import { TravelETuristFormComponent } from "./domain/e-tourist/travel-e-turist-form/travel-e-turist-form.component";
import { TravelETuristPdfViewerComponent } from "./domain/e-tourist/travel-e-turist-pdf-viewer/travel-e-turist-pdf-viewer.component";
import { SafePipe } from "./shared/pipes/safe";
import { TravelETuristFormUpdateComponent } from "./domain/e-tourist/travel-e-turist-form-update/travel-e-turist-form-update.component";
import { IconNewTabComponent } from "./shared/icon/icon-new-tab";
import { IconCalendarRangeComponent } from "./shared/icon/icon-calendar-range";
import { DetailsDropdownEturistComponent } from "./shared/components/details-dropdown-eturist/details-dropdown-eturist.component";
import { EmailConfigurationModalComponent } from "./domain/office-branch/email-configuration-modal/email-configuration-modal.component";
import { SearchInputComponent } from "./shared/components/search-input/search-input.component";
import { TouristSalesComponent } from "./domain/e-tourist/tourist-sales/tourist-sales.component";
import { TravelQuickViewByEturistComponent } from "./domain/quick-views/travel-quick-view-by-eturist/travel-quick-view-by-eturist.component";
import { GroupTravelDropdownComponent } from "./shared/components/group-travel-dropdown/group-travel-dropdown.component";
import { IconETouristComponent } from "./shared/icon/icon-e-tourist";
import { OfficeBranchDetailsComponent } from "./domain/office-branch/office-branch.details.component/office-branch.details.component";
import { OfficeBranchEditComponent } from "./domain/office-branch/office-branch-edit/office-branch-edit.component";
import { GroupTravelEturistComponent } from "./domain/group-travels/group-travel-eturist/group-travel-eturist.component";
import { ManagerInfoFormComponent } from "./domain/office-branch/office-branch-first-login/first-step/manager-info-form.component";
import { OfficeBranchInfoFormComponent } from "./domain/office-branch/office-branch-first-login/second-step/office-branch-info-form.component";
import { OfficeBranchMailFormComponent } from "./domain/office-branch/office-branch-first-login/third-step/office-branch-mail-form.component";
import { OfficeBranchETuristInfoFormComponent } from "./domain/office-branch/office-branch-first-login/fourth-step/office-branch-eturist-info-form.component";
import { ConfirmationModalComponent } from "./shared/components/confirmation-modal/confirmation-modal.component";
import { InvoiceDocumentComponent } from "./domain/documents/facture/invoice-document.component";
import { InvoiceDocumentEditComponent } from './domain/documents/facture/models/edit/invoice-document-edit/invoice-document-edit.component';
import { InvoiceEditComponent } from './domain/documents/facture/models/edit/invoice-edit/invoice-edit.component';

@NgModule({
  imports: [
    NgxMaskDirective,
    NgxMaskPipe,
    PortalModule,
    BrowserAnimationsModule,
    NgxTippyModule,
    NgApexchartsModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
    BrowserModule,
    DataTableModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MenuModule,
    TagInputModule,
    AuthModule,
    AngJson2excelBtnModule,
    TagInputModule,
    DirectivesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot({
      datetime: dateTimeReducer,
      auth: authReducer,
      lang: languageReducer,
      layout: layoutReducer,
    }),
    NgScrollbarModule.withConfig({
      visibility: "hover",
      appearance: "standard",
    }),
    IconModule,
    ModalModule,
    FullCalendarModule,
    NgxNumberSpinnerModule,
    QuillModule.forRoot(),
    EffectsModule.forRoot([LayoutEffects]),
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  declarations: [
    ManagerInfoFormComponent,
    OfficeBranchInfoFormComponent,
    OfficeBranchMailFormComponent,
    OfficeBranchETuristInfoFormComponent,
    AccordionComponent,
    EmailConfigurationModalComponent,
    OfficeBranchFirstLoginComponent,
    OfficeBranchDetailsComponent,
    OfficeBranchEditComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    IndexComponent,
    AppLayoutComponent,
    AuthLayoutComponent,
    TicketsTableComponent,
    TravelsTableComponent,
    HotelsTableComponent,
    OrganizersTableComponent,
    DestinationsTableComponent,
    CompaniesTableComponent,
    SalesTableComponent,
    HotelComponent,
    CompanyComponent,
    OrganizerComponent,
    DestinationComponent,
    GroupTravelsTableComponent,
    GroupTravelComponent,
    WorkersComponent,
    WorkersOfficesTableComponent,
    CalendarComponent,
    SelectRemoteComponent,
    AutocompleteRemoteComponent,
    CustomerCreateComponent,
    TravelCreateComponent,
    TravelCheckoutEditComponent,
    TicketCheckoutComponent,
    TicketCreateComponent,
    SaleCreateComponent,
    NumbersOnlyDirective,
    OtherServicesCreateComponent,
    GroupDestinationsFormComponent,
    TicketDetailsComponent,
    TravelDetailsComponent,
    TransfersTableComponent,
    TransfersDetailsComponent,
    TransferComponent,
    GroupTravelDetailsComponent,
    WorkerFormComponent,
    TravelDestinationCreateComponent,
    PassangersListComponent,
    DailySaleComponent,
    HorizontalGraphComponent,
    SimpleColumnComponent,
    StatisticsComponent,
    SimpleColumnComponent,
    DailySaleComponent,
    PassangersListComponent,
    TravelCreateComponent,
    TravelDetailsComponent,
    TransfersTableComponent,
    TransfersDetailsComponent,
    TransferComponent,
    GroupTravelDetailsComponent,
    DailySaleDocumentComponent,
    DailySaleEditComponent,
    VoucherComponent,
    SettingsCreateComponent,
    SettingsListComponent,
    VoucherEditComponent,
    VoucherListComponent,
    ContractComponent,
    ContractEditComponent,
    DraftTableComponent,
    BookComponent,
    TransferCreateComponent,
    TransferEditComponent,
    TransferPlanComponent,
    GroupTravelPlanComponent,
    TransferPassangersTableComponent,
    GroupTravelPassangersTableComponent,
    PassengersTableComponent,
    SaleDetailsComponent,
    OfficeBranchTableComponent,
    OfficeBranchListComponent,
    OfficeBranchFormComponent,
    EtouristCheckerComponent,
    OfficeBranchLocationFormComponent,
    PaymentFormComponent,
    CustomersTableComponent,
    BreadcrumbsComponent,
    CustomerStatsComponent,
    StatisticsMoneyComponent,
    ChartStatisticsComponent,
    DynamicIconComponent,
    UserProfileComponent,
    GlobalTableFiltersComponent,
    ETouristTableComponent,
    AnnouncementComponent,
    OrganizerStatisticsComponent,
    OrganizerStatisticsProfitComponent,
    StatisticsChartComponent,
    YearlyEarningsChartComponent,
    YearlyDebtChartComponent,
    MontlyEarningsChartComponent,
    MontlyDebtChartComponent,
    DailyEarningsChartComponent,
    DailyDebtChartComponent,
    DailyMoneyChartComponent,
    MontlyMoneyChartComponent,
    OtherServicesTableComponent,
    OtherServicesDetailsComponent,
    OtherServicesEditComponent,
    IconMenuOtherServicesComponent,
    ExportTableComponent,
    ExportToExcelComponent,
    GroupTravelVoucherListComponent,
    GroupTravelVoucherEditComponent,
    TravelVoucherEditComponent,
    GroupTravelContractComponent,
    GroupTravelContractEditComponent,
    TravelContractComponent,
    TravelContractEditComponent,
    TravelVouchersComponent,
    FileSendComponent,
    VoucherTemplateComponent,
    DailyActionsComponent,
    FileHistoryComponent,
    TravelContractComponent,
    CountryChartComponent,
    TicketQuickViewComponent,
    TravelQuickViewComponent,
    TransferQuickViewComponent,
    OtherServicesQuickViewComponent,
    GroupTravelQuickViewComponent,
    WorkerSalesByMonthComponent,
    OverallStatisticsComponent,
    ConfirmationDialogComponent,
    MessagePortalComponent,
    TravelCheckoutCreateComponent,
    TransferPlanQuickviewComponent,
    GroupTravelPlanQuickviewComponent,
    OverallStatisticsComponent,
    DailyWorkerSummaryComponent,
    UserNewPasswordComponent,
    DetailsDropdownComponent,
    WorkersTableComponent,
    InvoiceComponent,
    DestinationAutocompleteComponent,
    MergeComponent,
    OrganizerFormComponent,
    EditDestinationComponent,
    CustomerCreateEditComponent,
    DatepickerComponent,
    Error404Component,
    GroupTravelAddPassangerComponent,
    GroupTravelPlanEditComponent,
    TransferPlanEditComponent,
    DestinationsListComponent,
    CompanyFormComponent,
    HotelFormComponent,
    TravelCheckoutEditTravelComponent,
    AnnouncementEditComponent,
    ThemeCustomizerComponent,
    LayoutComponent,
    DangerZoneComponent,
    GroupTravelPlanDetailsComponent,
    TransfersPlanDetailsComponent,
    IconArrowRightComponent,
    DynamicEntityIconComponent,
    TravelETuristFormComponent,
    TravelETuristPdfViewerComponent,
    TravelETuristFormUpdateComponent,
    SafePipe,
    IconNewTabComponent,
    IconCalendarRangeComponent,
    DetailsDropdownEturistComponent,
    IconETouristComponent,
    SearchInputComponent,
    TouristSalesComponent,
    TravelQuickViewByEturistComponent,
    GroupTravelDropdownComponent,
    GroupTravelEturistComponent,
    ConfirmationModalComponent,
    InvoiceDocumentComponent,
    InvoiceDocumentEditComponent,
    InvoiceEditComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    AppService,
    Title,
    PackageRightsGuard,
    HasRoleGuard,
    HasIdGuard,
    HasBranchData,
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
