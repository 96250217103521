import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-search-input",
  templateUrl: "./search-input.component.html",
})
export class SearchInputComponent {
  public search = "";
  public showClearIcon = false;
  public loading = false;

  @Output() searchChanged = new EventEmitter<string>();

  onSearchChange(searchTerm: string): void {
    this.loading = true;
    this.searchChanged.emit(searchTerm);
    this.showClearIcon = searchTerm.length > 0;
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  clearSearch(): void {
    this.search = "";
    this.showClearIcon = false;
    this.searchChanged.emit("");

    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  onSearchInput() {
    this.showClearIcon = false;
  }
}
