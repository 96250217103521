import { Store } from "@ngrx/store";
import { User } from "src/app/auth/model/user";
import { Office } from "../models/office-branch";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { updateUser } from "src/app/store/auth/auth.actions";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { selectFeatureUser } from "src/app/store/auth/auth.selector";
import { OfficeBranchService } from "../services/office-branch.service";
import { ETouristService } from "src/app/shared/services/etourist.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { catchError, filter, Observable, of, switchMap, take, tap } from "rxjs";
import { ETouristCity, ETouristMunicipality, ETouristStreet } from "src/app/shared/models/etourist";

@Component({
  selector: "app-office-branch-location-form",
  templateUrl: "./office-branch-location.form.component.html",
})
export class OfficeBranchLocationFormComponent implements OnInit {
  activeTab = 1;
  public user!: User;
  public office!: Office;
  public form!: FormGroup;
  public isLoading = false;
  public guidForm!: FormGroup;

  user$!: Observable<User | undefined>;
  public optionsCities$!: Observable<ETouristCity[]>;
  public optionsStreets$!: Observable<ETouristStreet[]>;
  public optionsMunicipality$!: Observable<ETouristMunicipality[]>;

  @Input() id!: number;
  @Output() submissionDone: EventEmitter<any> = new EventEmitter();

  constructor(public fb: FormBuilder, public store: Store<AppState>, public translate: TranslateService, public eTouristService: ETouristService, public officeBranchService: OfficeBranchService) {}

  ngOnInit(): void {
    this.guidForm = this.fb.group({
      guid: ["", [Validators.required]],
      jmbg: ["", [Validators.required]],
    });

    if (this.id !== undefined) {
      const fetchOfficeSubscription = this.officeBranchService.getOfficeById(this.id).subscribe((office) => {
        fetchOfficeSubscription.unsubscribe();
        this.office = office;
        this.initForm(office);

        this.user$ = this.store.select(selectFeatureUser);
        this.user$
          .pipe(
            take(1),
            tap((res) => {
              if (res) this.user = res;
              this.guidForm.get("jmbg")?.setValue(this.user.uniqueMasterCitizenNumber);
            })
          )
          .subscribe();

        const guid = this.office.guid ?? "";
        this.guidForm.get("guid")?.setValue(guid);
      });
    }

    this.fetchOptions();
  }

  initForm(office: Office): void {
    const { municipality, municipalityId, city, cityId, street, streetId } = office || ({} as Office);

    this.form = this.fb.group({
      municipality: [{ nameLatin: municipality, identificationNumber: municipalityId }, Validators.required],
      city: [{ nameLatin: city, identificationNumber: cityId }, Validators.required],
      street: [{ nameLatin: street, identificationNumber: streetId }, Validators.required],
    });

    this.setupValueChanges();
  }

  submitForm(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    const officeLocation = this.fetchOffice();
    const updateSubscription = this.officeBranchService.updateOffice(this.id, officeLocation).subscribe({
      next: () => {
        this.isLoading = false;
        updateSubscription.unsubscribe();
        showMessage(PopupType.Success, this.translate.instant("office-branch.eturista_confirmation_message"));
        this.initForm({} as Office);
        this.submissionDone.emit(null);
      },
      error: (error) => {
        this.isLoading = false;
        updateSubscription.unsubscribe();
        showMessage(PopupType.Danger, error.toString());
      },
    });
  }

  validateGUID(): void {
    if (!this.guidForm.valid) {
      this.guidForm.markAllAsTouched();
      return;
    }

    const jmbg = this.guidForm.value.jmbg;
    this.isLoading = true;
    const subscription = this.officeBranchService.updateOfficeGuid(this.office.id, this.guidForm.getRawValue()).subscribe({
      next: () => {
        subscription.unsubscribe();
        this.office = { ...this.office, ...this.guidForm.getRawValue() };
        this.isLoading = false;

        const data = {
          name: this.user.name,
          surname: this.user.surname,
          settings: this.user.settings,
          uniqueMasterCitizenNumber: jmbg,
        };
        this.store.dispatch(updateUser({ user: data }));
        if (this.activeTab < 2) {
          this.activeTab++;
        }
      },
      error: (error) => {
        this.isLoading = false;
        subscription.unsubscribe();
        showMessage(PopupType.Danger, error.error.toString());
      },
    });
  }

  fetchOptions() {
    this.optionsMunicipality$ = this.eTouristService.getMunicipality().pipe(
      catchError((error) => {
        showMessage(PopupType.Danger, error.error);
        return of([]);
      })
    );
  }

  setupValueChanges() {
    this.form
      .get("municipality")
      ?.valueChanges.pipe(
        filter((value) => !!value),
        switchMap((municipality: ETouristMunicipality) => {
          this.form.get("city")?.reset();
          this.form.get("city")?.enable();
          this.form.get("street")?.reset();
          this.form.get("street")?.disable();
          return this.eTouristService.getCities(municipality.identificationNumber).pipe(
            catchError((error) => {
              showMessage(PopupType.Danger, error.error);
              return of([]);
            })
          );
        })
      )
      .subscribe((cities) => {
        this.optionsCities$ = of(cities);
      });

    this.form
      .get("city")
      ?.valueChanges.pipe(
        filter((value) => !!value),
        switchMap((city: ETouristCity) => {
          this.form.get("street")?.reset();
          this.form.get("street")?.enable();
          return this.eTouristService.getStreets(city.identificationNumber).pipe(
            catchError((error) => {
              showMessage(PopupType.Danger, error.error);
              return of([]);
            })
          );
        })
      )
      .subscribe((streets) => {
        this.optionsStreets$ = of(streets);
      });
  }

  changed(value: any) {
    console.log(value);
  }

  fetchOffice(): Office {
    const { municipality, city, street } = this.form.getRawValue();

    return {
      ...this.office,
      municipality: municipality.nameLatin,
      city: city.nameLatin,
      street: street.nameLatin,
      municipalityId: municipality.identificationNumber,
      cityId: city.identificationNumber,
      streetId: street.identificationNumber,
    } as Office;
  }

  nextStep(): void {
    if (this.activeTab === 1) this.validateGUID();
    else if (this.activeTab === 2) this.submitForm();
  }

  prevStep(): void {
    if (this.activeTab > 1) {
      this.activeTab--;
    }
  }
}
