import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
	moduleId: module.id,
	selector: "icon-e-tourist",
	template: `
		<ng-template #template>
		<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" [ngClass]="class" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1080 1080" [ngClass]="class"  style="enable-background:new 0 0 20 20; fill: gray;" xml:space="preserve">
		<path
          d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12Z"
          stroke="currentColor"
          stroke-width="1.5"
        />
<g>
	<ellipse cx="591.6" cy="860.5" rx="173.4" ry="48.9"/>
	<path d="M354,422c3.7,74,33.9,145.1,85.5,198c3.5,4.1,9,9.6,13,13c7.9,7.5,19.4,18.1,28,24.6c4.9,4,9.7,8,14.5,12.2
		c-11.5-5.3-23.3-10.5-34.1-17.1c-5.7-4-18.5-12.1-23.8-16.3c-7-6.2-14.9-12.8-21.7-19.3l-13-14.5C361.3,554,339.8,484.9,354,422
		L354,422z"/>
	<path d="M386,589.6"/>
	<path d="M471.3,518.2c-19.9,5.8-40,11-60.5,15.6c-5.8,1.3-10.5,1.7-14.4,0.9c-0.6-0.1-1.3-0.2-1.9-0.4c0.5-0.1,1.1-0.2,1.7-0.2
		C419.8,530.7,445,525.4,471.3,518.2z"/>
	<path d="M550.8,491.2C524,501.9,497.5,510.8,472,518C498.8,510.2,525.1,501.3,550.8,491.2z"/>
	<path d="M386,589.6c-4.8-0.5-10.5,0.5-17.6,3.9c34.7,54.6,83.8,87.7,144.5,102.8c117,29.2,234.5-30.3,283.2-141.4
		c0.8-1.8,1.7-3.6,2.4-5.4c6.1-14.7,15.4-29.3,32.1-18.5c7.2,4.7,11.6,24.3,8,33.7c-31.7,81.4-89.5,137.5-172,166.4
		c-128.5,45.1-264.7-2.7-340.8-117.2c-7.1-10.6-20.3-21.2-32.4-23.9c-82.6-18.2-106.2-69.7-62.1-137c2-3,4.1-6.1,6.4-9.1
		c2.3-3.1,4.8-6.2,7.2-9.3c-11.7,23.6-15.3,46-9.4,65.4c6.5,10.9,17.2,19.4,31.4,25.6c6,2.7,12.6,4.9,19.7,6.7
		c-0.2-42.8-6.1-85.7,1.3-126.1c2.6-14,5.9-27.5,10-40.4c0.6-2,1.2-3.9,1.9-5.9c29.1-86.6,91.9-146.4,184.7-174.8
		c73.8-22.6,142.2-18.6,204.3,10.5c2.2,1.1,4.5,2.2,6.8,3.3c29.5,14.7,57.5,35.2,84,61.2c18.5,18.2,20.3,35.1,3.7,53.6
		c-13.2,14.7-27.9,28.1-43.4,40.4c23.9-54.9,22.2-60.5-30.2-95.5c-40-26.6-85.1-41-130-43c-0.9-0.1-1.9-0.1-2.8-0.1
		C525.7,213.7,475,228,433,258.3c-14.9,10.7-28.3,22.6-40.2,35.4c-0.5,0.5-0.9,1-1.4,1.5c-56.6,61.5-78.3,143.9-56.9,224.4
		c1.9,7.3,3.8,14.3,7.3,18.4c5.2,0,10.6-0.2,16.1-0.5c0.6-0.1,1.2-0.1,1.8-0.1c11.1-0.6,22.8-1.7,34.8-3.4c0.6,0.2,1.2,0.3,1.9,0.4
		c3.9,0.7,8.6,0.4,14.4-0.9c20.5-4.6,40.6-9.8,60.5-15.6c0.2-0.1,0.5-0.2,0.7-0.2c25.5-7.1,52-16,78.8-26.8
		c3.8-1.6,7.7-3.1,11.6-4.7c2.7-1.1,5.3-2.2,7.9-3.4c1.4-0.6,2.7-1.1,4-1.8c5.2-2.2,10.4-4.7,15.6-7
		C707.2,420.2,794,346.7,816,287.7c5.8-15.4,7.1-29.9,3.4-42.7c0-0.1-0.1-0.1-0.1-0.1c-12.1-20.7-36.4-34.5-69.1-41.1
		c0-0.1,0.1-0.3,0.1-0.4c13,2.9,26.5,5.1,39.7,7.8c12.8,2.5,25.5,5.7,37.4,10.5c36.3,14.9,54,52.8,36.3,87.5
		c-17.4,34.1-40.7,67.9-69.1,93.1C693.3,492,572,544.3,442,577.7c-6.1,1.6-12.3,3.2-22.2,5.8c-2.1,0.6-4.4,1.2-6.9,1.8"/>
	<path d="M550.8,491.2C524,501.9,497.5,510.8,472,518C498.8,510.2,525.1,501.3,550.8,491.2z"/>
	<path d="M550.8,491.2C524,501.9,497.5,510.8,472,518C498.8,510.2,525.1,501.3,550.8,491.2z"/>
</g>
</svg>
		</ng-template>
	`,
})
export class IconETouristComponent {
	@Input() class: any = "";
	@ViewChild("template", { static: true }) template: any;
	constructor(private viewContainerRef: ViewContainerRef) {}
	ngOnInit() {
		this.viewContainerRef.createEmbeddedView(this.template);
		this.viewContainerRef.element.nativeElement.remove();
	}
}
