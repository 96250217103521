<form class="space-y-5 p-5" [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
  <div class="flex space-x-4">
    <div class="focused">
      <label for="customOneWay" class="relative h-6 w-12">
        <input type="checkbox" formControlName="oneWay" #direction class="custom_switch peer absolute z-10 h-full w-full cursor-pointer opacity-0" id="customOneWay" />
        <span
          for="customOneWay"
          class="outline_checkbox bg-icon block h-full rounded-full border-2 border-[#ebedf2] before:absolute before:bottom-1 before:left-1 before:h-4 before:w-4 before:rounded-full before:bg-[#ebedf2] before:bg-[url(/assets/images/close.svg)] before:bg-center before:bg-no-repeat before:transition-all before:duration-300 peer-checked:border-primary peer-checked:before:left-7 peer-checked:before:bg-primary peer-checked:before:bg-[url(/assets/images/checked.svg)] dark:border-white-dark dark:before:bg-white-dark"
        ></span>
      </label>
    </div>
    <div class="align-middle">
      <ng-container *ngIf="!form.controls['oneWay'].value; else returnTicketText">
        {{ "ticket_create.return_ticket" | translate }}
      </ng-container>
      <ng-template #returnTicketText>
        {{ "ticket_create.one_way_ticket" | translate }}
      </ng-template>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
    <div [ngClass]="form.controls['departure'].touched ? (form.controls['departure'].errors ? 'has-error' : 'has-success') : ''">
      <app-destination-autocomplete [form]="form" formControlName="departure" #destinationAutocomplete></app-destination-autocomplete>
    </div>
    <div [ngClass]="form.controls['destination'].touched ? (form.controls['destination'].errors ? 'has-error' : 'has-success') : ''">
      <app-destination-autocomplete [form]="form" formControlName="destination"></app-destination-autocomplete>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
    <div [ngClass]="form.controls['departureDate'].touched ? (form.controls['departureDate'].errors ? 'has-error' : 'has-success') : ''">
      <label for="departureDate">{{ "ticket_create.departure_date" | translate }}</label>
      <input id="departureDate" type="text" class="form-input" formControlName="departureDate" class="form-input" placeholder="__.__.____ __:__" mask="d0.M0.0000 Hh:m0" [showMaskTyped]="true" (blur)="onDateBlur('departureDate')"/>
      <ng-container *ngIf="form.controls['departureDate'].touched">
        <ng-container *ngIf="form.controls['departureDate'].errors; else looksGoodDeparture">
          <p class="mt-1 text-danger">{{ "ticket_create.please_provide_departure_date" | translate }}</p>
        </ng-container>
        <ng-template #looksGoodDeparture>
          <p *ngIf="!form.hasError('endDateBeforeStartDate')" class="mt-1 text-[#1abc9c]">
            {{ "ticket_create.looks_good" | translate }}
          </p>
        </ng-template>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['arrivalDate'].touched ? (form.controls['arrivalDate'].errors ? 'has-error' : 'has-success') : ''" *ngIf="!form.controls['oneWay'].value">
      <label for="customArrivalDate">{{ "ticket_create.return_date" | translate }}</label>
      <input id="customArrivalDate" type="text" class="form-input" formControlName="arrivalDate" placeholder="__.__.____ __:__" mask="d0.M0.0000 Hh:m0" [showMaskTyped]="true" (blur)="onDateBlur('arrivalDate')"
			/>
      <ng-container *ngIf="form.controls['arrivalDate'].touched">
        <ng-container *ngIf="form.controls['arrivalDate'].errors; else looksGoodArrival">
          <p class="mt-1 text-danger">{{ "ticket_create.please_provide_return_date" | translate }}</p>
        </ng-container>
        <ng-template #looksGoodArrival>
          <p *ngIf="!form.controls['departureDate'].errors && form.hasError('endDateBeforeStartDate')" class="mt-1 text-danger">
            {{ "ticket_create.arrival_date_before_departure_date" | translate }}
          </p>
          <p *ngIf="!form.hasError('endDateBeforeStartDate')" class="mt-1 text-[#1abc9c]">
            {{ "ticket_create.looks_good" | translate }}
          </p>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
    <div [ngClass]="form.controls['reservationDate'].touched ? (form.controls['reservationDate'].errors ? 'has-error' : 'has-success') : ''">
      <label for="reservationDate">{{ "ticket_create.reservation_date" | translate }}</label>
      <input id="reservationDate" type="text" class="form-input" formControlName="reservationDate" class="form-input" placeholder="__.__.____" mask="d0.M0.0000" [showMaskTyped]="true" />
      <ng-container *ngIf="form.controls['reservationDate'].touched && !form.controls['reservationDate'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "ticket_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['reservationDate'].touched && form.controls['reservationDate'].errors">
        <p class="mt-1 text-danger">{{ "ticket_create.please_provide_reservation_date" | translate }}</p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['company'].touched ? (form.controls['company'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customCompany">{{ "ticket_create.company" | translate }}</label>
      <app-autocomplete-remote
        [type]="EntityPicklistType.Companies"
        labelName="name"
        placeholder="{{ 'ticket_create.search_for_company' | translate }}"
        [selectControl]="form"
        labelForId="customCompany"
        formControlName="company"
        [addCallback]="utilService.companiesAdd('company', form).bind(utilService)"
      ></app-autocomplete-remote>
      <ng-container *ngIf="form.controls['company'].touched && !form.controls['company'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "ticket_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['company'].touched && form.controls['company'].errors">
        <p class="mt-1 text-danger">{{ "ticket_create.please_provide_company" | translate }}</p>
      </ng-container>
    </div>
  </div>
</form>
