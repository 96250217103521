<form [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="form">
  <div class="grid grid-cols-1 gap-5 gap-x-2 p-4 sm:grid-cols-2">
    <div class="col-span-2 flex grid grid-cols-1 rounded-lg p-4 sm:col-span-4 sm:grid-cols-2">
      <div class="col-span-2 flex flex-col pb-4 sm:col-span-2">
        <div class="flex justify-center text-center text-3xl font-bold">
          {{ "login_wizard.third_step_title" | translate }}
        </div>

        <small class="flex justify-center py-5 text-center text-base">{{ "login_wizard.third_step_subtext" | translate }}</small>
      </div>

      <div class="col-span-4 grid grid-cols-1 gap-5 p-4 pb-0 sm:col-span-4 sm:grid-cols-2 sm:pb-4">
        <div [ngClass]="form.controls['senderEmail'].touched ? (form.controls['senderEmail'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
          <label for="senderEmail">{{ "office-branch.senderEmail" | translate }}</label>
          <input type="text" placeholder="{{ 'office-branch.enter_senderEmail' | translate }}" class="form-input" id="senderEmail" formControlName="senderEmail" />
          <ng-container *ngIf="form.controls['senderEmail'].touched && form.controls['senderEmail'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "office-branch.please_provide_senderEmail" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div [ngClass]="form.controls['senderName'].touched ? (form.controls['senderName'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
          <label for="senderName">{{ "office-branch.senderName" | translate }}</label>
          <input type="text" placeholder="{{ 'office-branch.enter_senderName' | translate }}" class="form-input" id="senderName" formControlName="senderName" />
          <ng-container *ngIf="form.controls['senderName'].touched && form.controls['senderName'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "office-branch.please_provide_senderName" | translate }}</small>
            </p>
          </ng-container>
        </div>
      </div>

      <div class="col-span-4 grid grid-cols-1 gap-5 p-4 pb-0 sm:col-span-4 sm:grid-cols-2 sm:pb-4">
        <div [ngClass]="form.controls['adminEmail'].touched ? (form.controls['adminEmail'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
          <label for="adminEmail">{{ "office-branch.adminEmail" | translate }}</label>
          <input type="text" placeholder="{{ 'office-branch.enter_adminEmail' | translate }}" class="form-input" id="adminEmail" formControlName="adminEmail" />
          <ng-container *ngIf="form.controls['adminEmail'].touched && form.controls['adminEmail'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "office-branch.please_provide_adminEmail" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div [ngClass]="form.controls['password'].touched ? (form.controls['password'].errors ? 'has-error' : 'has-success') : ''" class="mt-3">
          <label for="password">{{ "office-branch.password" | translate }}</label>
          <div class="relative text-white-dark">
            <input [type]="passwordVisible ? 'text' : 'password'" autocomplete="off" placeholder="{{ 'office-branch.enter_password' | translate }}" class="form-input" id="password" formControlName="password" />
            <button type="button" class="absolute inset-y-0 right-0 flex items-start pr-3 pt-2" (click)="togglePasswordVisibility()">
              <icon-eye-closed *ngIf="!passwordVisible; else hideIcon" class="dark:text-white" />
              <ng-template #hideIcon>
                <icon-eye-open class="dark:text-white" />
              </ng-template>
            </button>
          </div>
          <ng-container *ngIf="form.controls['password'].touched && form.controls['password'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "office-branch.please_provide_password" | translate }}</small>
            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</form>
