<div class="space-y-8">
  <div class="panel p-0 py-5">
    <div>
      <div class="m-6 flex justify-end" *ngIf="hasTravelData">
        <button type="button" [ngxTippy]="tooltipMessage | translate" class="btn btn-primary" (click)="clearTravelData()">
          <icon-discard></icon-discard>
        </button>
      </div>
      <div class="relative z-[1]">
        <div
          class="absolute top-[30px] -z-[1] m-auto h-1 w-[15%] bg-primary transition-[width] ltr:left-0 rtl:right-0"
          [ngStyle]="{
            width: tabSizeHandler(activeTab, true)
          }"
        ></div>
        <ul [ngClass]="'grid-cols-4'" class="mb-5 grid">
          <li class="mx-auto">
            <a
              href="javascript:;"
              class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
              [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 1 }"
              (click)="proceedToNextStage(1)"
            >
              <icon-person></icon-person>
            </a>
            <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 1 }">{{ "travel_checkout.passangers" | translate }}</span>
          </li>
          <li class="mx-auto">
            <a
              href="javascript:;"
              class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
              [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 2 }"
              (click)="proceedToNextStage(2)"
            >
              <icon-home></icon-home>
            </a>
            <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 2 }">{{ "travel_checkout.travel" | translate }}</span>
          </li>

          <ng-container>
            <li class="mx-auto">
              <a
                href="javascript:;"
                class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
                [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 3 }"
              >
                <icon-mail></icon-mail>
              </a>
              <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 3 }">{{ "travel_checkout.prepare_announcement" | translate }}</span>
            </li>
          </ng-container>

          <li class="mx-auto">
            <a
              href="javascript:;"
              class="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-[#f3f2ee] bg-white dark:border-[#1b2e4b] dark:bg-[#253b5c]"
              [ngClass]="{ '!border-primary !bg-primary text-white': activeTab === 4 }"
            >
              <icon-thumbs-up></icon-thumbs-up>
            </a>
            <span class="mt-2 block text-center" [ngClass]="{ 'text-primary': activeTab === 3 }">{{ "travel_checkout.reciept" | translate }}</span>
          </li>
        </ul>
      </div>
      <div>
        <div [ngStyle]="isVisibleTab(1)" class="pt-5">
          <app-passanger-list #passangerList (passangersSelected)="toggleButton($event)" (submitCustomer)="handleCustomerSubmit()"></app-passanger-list>
        </div>
        <div [ngStyle]="isVisibleTab(2)">
          <div class="mx-5">
            <h3 class="font-bold">{{ "travel_checkout.travel" | translate }}</h3>
            <hr />
          </div>

          <app-travel-create #travelBase (notify)="onNotify($event)" [showAvans]="true"></app-travel-create>

          <div class="relative px-5">
            <div class="mb-5 rounded-xl bg-gray-100 p-5">
              <app-travel-destination-create #mainDestination> </app-travel-destination-create>
            </div>
          </div>
          <app-destinations-list #destinations></app-destinations-list>
        </div>
        <div class="flex justify-between px-5">
          <ng-container *ngIf="activeTab > 1; else placeholder">
            <button type="button" class="btn btn-primary" (click)="returnBack()">
              {{ "travel_checkout.back" | translate }}
            </button>
          </ng-container>
          <ng-template #placeholder>
            <div class="invisible"></div>
          </ng-template>

          <button #nextStepButton type="button" class="btn btn-primary" *ngIf="activeTab === 1 && !isEditingPassanger" (click)="proceedToNextStage(activeTab + 1)">
            {{ "travel_checkout.next" | translate }}
          </button>
          <button class="btn btn-primary ltr:ml-4 rtl:mr-4" *ngIf="activeTab === 2" (click)="createDraftTravel()" [disabled]="isLoading">
            <ng-container *ngIf="isLoading; else alternateTranslation">
              <span class="inline-block h-3 w-3 animate-ping rounded-full bg-white ltr:mr-4 rtl:ml-4"></span>
              {{ "loading" | translate }}
            </ng-container>
            <ng-template #alternateTranslation>
              {{ "travel_checkout.next" | translate }}
            </ng-template>
          </button>
        </div>
        <div class="p-5">
          <hr class="my-2" />
          <div>
            <label for="notes">{{ "ticket_checkout.remarks" | translate }}</label>
            <textarea id="notes" rows="3" class="w-100 form-textarea mt-2" [placeholder]="translate.instant('ticket_checkout.remarks')" [(ngModel)]="note"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
