import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { NgDataTableComponent } from "@bhplugin/ng-datatable";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Contract } from "../../documents/contract/models/contract";
import { catchError, Observable, of, Subscription, take } from "rxjs";
import { GroupTravelsService } from "../services/group-travels.service";
import { slideDownUp, toggleAnimation } from "src/app/shared/animations";
import { ETouristService } from "src/app/shared/services/etourist.service";
import { ExchangeRateService } from "src/app/shared/services/exchangeRates.service";
import { ContractService } from "../../documents/contract/services/contract.service";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { GroupTravelDestination, GroupTravelPassangerPreview, GroupTravelPreview } from "../models/group-travel";
import { ETouristCodebookEntry, ETouristPlanResponseToReturnDto, ETuristSaleRecord } from "src/app/shared/models/etourist";

@Component({
  selector: "app-group-travel-eturist",
  templateUrl: "./group-travel-eturist.component.html",
  animations: [toggleAnimation, slideDownUp],
})
export class GroupTravelEturistComponent implements OnInit {
  @Input() groupTravelId: number | undefined;
  public pageSize = 10;
  public totalRows = 1;
  groupTravel!: GroupTravelPreview;
  tooltipMessage!: string;
  groupTravelPassangers: GroupTravelPassangerPreview[] = [];
  treeview1: any = [];
  private langChangeSubscription!: Subscription;
  public cols: DatatableColumn[] = [];
  loading = false;
  symbol?: string;
  form!: FormGroup;
  currency?: string;
  @Input() id!: number;
  bruto!: number;
  contract!: Contract;
  uniqueIdentifier!: number;
  amount: number = this.bruto;
  @Input() nbsExchangeRate!: number;
  @Input() eTuristTransportCode!: string;
  @Input() eTuristAccommodationCode!: string;
  @Output() formSubmit = new EventEmitter<null>();
  selectedTransport!: ETouristCodebookEntry | null;
  selectedService!: ETouristCodebookEntry | null;
  selectedPlan!: ETouristPlanResponseToReturnDto | null;
  protected currencySubscriber$!: Observable<ETouristCodebookEntry[]>;
  protected transportSubscriber$!: Observable<ETouristCodebookEntry[]>;
  protected accomodationSubscriber$!: Observable<ETouristCodebookEntry[]>;
  protected travelPlansSubscriber$!: Observable<ETouristPlanResponseToReturnDto[]>;
  mainDestination: GroupTravelDestination | undefined;
  @ViewChild("datatable") datatable!: NgDataTableComponent;
  @Output() recordUpdated = new EventEmitter<void>();

  constructor(
    private groupTravelService: GroupTravelsService,
    public translate: TranslateService,
    private eTuristService: ETouristService,
    public fb: FormBuilder,
    public exchangeService: ExchangeRateService,
    public contractService: ContractService
  ) {}
  ngOnInit(): void {
    this.getColumns();
    this.tooltipMessage = "descriptions.noPassengers";
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.getColumns();
    });
    this.currencySubscriber$ = this.eTuristService.getCurrecy().pipe(
      catchError((error) => {
        showMessage(PopupType.Danger, error.error);
        return of([]);
      })
    );
    this.transportSubscriber$ = this.eTuristService.getTransport().pipe(
      catchError((error) => {
        showMessage(PopupType.Danger, error.error);
        return of([]);
      })
    );
    this.travelPlansSubscriber$ = this.eTuristService.getTravelPlans().pipe(
      catchError((error) => {
        showMessage(PopupType.Danger, error.error);
        return of([]);
      })
    );
    this.accomodationSubscriber$ = this.eTuristService.getAccomodation().pipe(
      catchError((error) => {
        showMessage(PopupType.Danger, error.error);
        return of([]);
      })
    );
    this.initializeForm();

    this.currencySubscriber$.subscribe((currencies: any[]) => {
      const defaultCurrency = currencies.find((currency) => currency.code === "251");
      if (defaultCurrency) {
        this.currency = defaultCurrency.name;
        this.symbol = "€";
        this.form.get("currencyTypeId")?.setValue(defaultCurrency);
      }
    });
    this.fetchTravelData();
    this.initializeTravelData();
  }

  getColumns(): void {
    this.cols = [
      { field: "serialNumber", title: this.translate.instant("group_travel_table.serial_number"), hide: true, sort: false } as DatatableColumn,
      { field: "id", title: this.translate.instant("group_travel_table.id"), hide: true, sort: false } as DatatableColumn,
      { field: "numberPassangers", title: this.translate.instant("group_travel_table.companions"), hide: false, sort: false } as DatatableColumn,
      { field: "name", title: this.translate.instant("group_travel_table.firstname"), hide: false, sort: false } as DatatableColumn,
      { field: "surname", title: this.translate.instant("group_travel_table.lastname"), hide: false, sort: false } as DatatableColumn,
      { field: "confirmationNumber", title: this.translate.instant("group_travel_table.confirmation_number"), hide: false, sort: false } as DatatableColumn,
      { field: "transportType", title: this.translate.instant("group_travel_table.transport_type"), hide: false, sort: false } as DatatableColumn,
      { field: "accommodationType", title: this.translate.instant("group_travel_table.accommodation_type"), hide: false, sort: false } as DatatableColumn,
      { field: "totalPrice", title: this.translate.instant("group_travel_table.total_price"), hide: false, sort: false } as DatatableColumn,
      { field: "status", title: this.translate.instant("group_travel_table.status"), hide: false, sort: false } as DatatableColumn,
      { field: "dateOfBirth", title: this.translate.instant("group_travel_table.date_of_birth"), hide: true, sort: false } as DatatableColumn,
      { field: "address", title: this.translate.instant("group_travel_table.address"), hide: true, sort: false } as DatatableColumn,
      { field: "phoneNumber", title: this.translate.instant("group_travel_table.phone_number"), hide: false, sort: false } as DatatableColumn,
      { field: "avans", title: this.translate.instant("group_travel_table.avans"), hide: false, sort: false } as DatatableColumn,
      { field: "paymentType", title: this.translate.instant("group_travel_table.payment_type"), hide: false, sort: false } as DatatableColumn,
    ];
  }
  toggleTreeview(id: string) {
    const index = this.treeview1.indexOf(id);
    if (index > -1) {
      this.treeview1.splice(index, 1);
    } else {
      this.treeview1.push(id);
    }
  }

  fetchTravelData() {
    if (!this.groupTravelId) return;
    const subscription = this.groupTravelService.getGroupTravelData(this.groupTravelId).subscribe((response) => {
      this.groupTravel = response;
      this.uniqueIdentifier = response.eTuristRecordId;
      this.bruto = response.bruto;
      this.mainDestination = response.groupTravelDestinations.find((dest) => dest.isMainDestination);
      this.groupTravelPassangers = this.transformGroupTravelData(response);

      subscription.unsubscribe();
      if (this.uniqueIdentifier) {
        this.travelPlansSubscriber$
          .pipe(
            take(1),
            catchError(() => of([]))
          )
          .subscribe((plans) => {
            this.selectedPlan = plans.find((t) => t.uniqueIdentifier === this.uniqueIdentifier) || null;

            if (this.selectedPlan) this.form.get("jIDNumber")?.setValue(this.selectedPlan);
          });
      } else {
        showMessage(PopupType.Danger, this.translate.instant("jid_missing_message"));
      }
      this.getContractsForGroupTravelPlans();
    });
  }
  getContractsForGroupTravelPlans(): void {
    this.groupTravel.groupTravelPlans?.forEach((plan) => {
      if (!plan.contractId) {
        this.fetchContract(plan.id);
      }
    });
  }

  fetchContract(planId: number): void {
    const subscription = this.contractService
      .getContractByGroupTravel(planId)
      .pipe(take(1))
      .subscribe((response) => {
        this.contract = response;
        subscription.unsubscribe();
      });
  }

  transformGroupTravelData(groupTravel: GroupTravelPreview): GroupTravelPassangerPreview[] {
    let serialCounter = 1;

    return (
      groupTravel.groupTravelPlans
        ?.filter((plan) => !plan.eTuristRecordId)
        .map((plan) => ({
          planStatus: plan.plan.status.toString(),
          planId: plan.planId.toString(),
          groupTravelPlanId: plan.id.toString(),
          totalPrice: plan.plan.bruto,
          mainCustomer: plan.customer,
          passengers: (plan.groupTravelPassangers || []).filter((p) => p?.customer?.id !== plan.customer?.id),
          paymentType: plan.plan.paymentType.title,
          avans: plan.plan.avans ?? 0,
          serialNumber: serialCounter++,
          exchangeRate: plan.plan.exchangeRate,
        })) || []
    );
  }

  save() {
    if (this.form.invalid) {
      showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
      this.form.markAllAsTouched();
      return;
    }
    this.createGroupTravelAccountData();
  }

  initializeTravelData() {
    this.transportSubscriber$.pipe(catchError(() => of([]))).subscribe((transportList) => {
      this.selectedTransport = transportList.find((t) => t.code == this.groupTravel.eTuristTransportCode) || null;
      if (!this.selectedTransport) {
        showMessage(PopupType.Danger, this.translate.instant("transport_missing_message"));
      }
      if (this.selectedTransport && this.selectedService) {
        this.updateForm();
      }
    });
    this.accomodationSubscriber$.pipe(catchError(() => of([]))).subscribe((accomodationList) => {
      this.selectedService = accomodationList.find((t) => t.code === this.mainDestination?.eTuristAccommodationCode) || null;
      if (!this.selectedService) {
        showMessage(PopupType.Danger, this.translate.instant("accommodation_missing_message"));
      }
      if (this.selectedTransport && this.selectedService) {
        this.updateForm();
      }
    });
  }
  updateForm() {
    this.form.patchValue({
      transportTypeId: this.selectedTransport,
      accomodationTypeId: this.selectedService,
    });
  }

  initializeForm() {
    this.form = this.fb.group({
      jIDNumber: [null, Validators.required],
      amount: [this.bruto * this.nbsExchangeRate, Validators.required],
      currencyTypeId: [null, Validators.required],
      transportTypeId: [this.selectedTransport, Validators.required],
      accomodationTypeId: [this.selectedService, Validators.required],
    });
  }

  getAccountData() {
    const { jIDNumber, amount, currencyTypeId, transportTypeId, accomodationTypeId } = this.form.value as ETuristSaleRecord;
    return {
      jIDNumber,
      amount,
      currencyTypeId,
      transportTypeId,
      accomodationTypeId,
    };
  }
  selectAll() {
    setTimeout(() => {
      this.datatable.rows.forEach((row) => {
        row.selected = true;
      });
      console.log(this.datatable.rows);
    }, 1000);
  }

  createGroupTravelAccountData() {
    this.loading = true;
    const accountData = this.getAccountData();
    const travelSales = this.datatable.getSelectedRows().map((plan: { groupTravelPlanId: number; totalPrice: number; exchangeRate: string }) => ({
      id: plan.groupTravelPlanId,
      exchangeRate: parseFloat(plan.exchangeRate).toFixed(4),
      accountingData: {
        jIDNumber: accountData.jIDNumber,
        amount: plan.totalPrice.toString(),
        currencyTypeId: accountData.currencyTypeId,
        transportTypeId: accountData.transportTypeId,
        accomodationTypeId: accountData.accomodationTypeId,
        organizerConfirmationNumber: null,
      },
    }));

    const subscription = this.groupTravelService.addGroupTravelSaleToETurist(accountData.jIDNumber.uniqueIdentifier, travelSales).subscribe({
      next: (res) => {
        this.loading = false;
        subscription.unsubscribe();
        this.recordUpdated.emit();
        if (res.item2) {
          showMessage(PopupType.Danger, res.item2);
        } else {
          showMessage(PopupType.Success, this.translate.instant("popup.accounting_data_created_successfully"));
          this.formSubmit.emit();
        }
      },
      error: () => {
        this.loading = false;
        subscription.unsubscribe();
        showMessage(PopupType.Danger, this.translate.instant("popup.error_while_creating_accounting_data"));
      },
    });
  }
}
