<form [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="form">
  <!-- <div class="relative"> -->
  <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
    <div class="col-span-2 flex grid grid-cols-2 rounded-lg p-4 sm:col-span-4 sm:grid-cols-4">
      <div class="col-span-4 flex flex-col pb-4 sm:col-span-4">
        <div class="flex justify-center text-3xl font-bold">
          {{ "login_wizard.first_step_title" | translate }}
        </div>
      </div>

      <div class="col-span-4 grid grid-cols-1 gap-5 p-4 pb-0 sm:col-span-4 sm:grid-cols-2 sm:pb-4">
        <div [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''">
          <label for="name">{{ "customer_create.name" | translate }}</label>
          <input type="text" placeholder="{{ 'customer_create.enter_name' | translate }}" class="form-input" id="name" formControlName="name" />
          <ng-container *ngIf="form.controls['name'].touched && form.controls['name'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "customer_create.please_provide_name" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div [ngClass]="form.controls['lastname'].touched ? (form.controls['lastname'].errors ? 'has-error' : 'has-success') : ''">
          <label for="lastname">{{ "customer_create.surname" | translate }}</label>
          <input type="text" placeholder="{{ 'customer_checkout.enter_surname' | translate }}" class="form-input" id="lastname" formControlName="lastname" />
          <ng-container *ngIf="form.controls['lastname'].touched && form.controls['lastname'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "customer_create.please_provide_surname" | translate }}</small>
            </p>
          </ng-container>
        </div>
      </div>

      <div class="col-span-4 grid grid-cols-1 gap-5 p-4 pb-0 sm:col-span-4 sm:grid-cols-1 sm:pb-4">
        <div [ngClass]="form.controls['email'].touched ? (form.controls['email'].errors ? 'has-error' : 'has-success') : ''">
          <label for="email">{{ "login_wizard.email" | translate }}</label>
          <input type="text" placeholder="{{ 'login_wizard.enter_email' | translate }}" class="form-input" id="email" formControlName="email" />
          <ng-container *ngIf="form.controls['email'].touched && form.controls['email'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "login_wizard.please_provide_email" | translate }}</small>
            </p>
          </ng-container>
        </div>
      </div>

      <div class="col-span-4 grid grid-cols-1 gap-5 p-4 pb-0 sm:col-span-4 sm:grid-cols-1 sm:pb-4">
        <div [ngClass]="form.controls['phone'].touched ? (form.controls['phone'].errors ? 'has-error' : 'has-success') : ''">
          <label for="phone">{{ "login_wizard.phone_number" | translate }}</label>
          <input type="text" placeholder="{{ 'login_wizard.enter_phone_number' | translate }}" class="form-input" id="phone" formControlName="phone" />
          <ng-container *ngIf="form.controls['phone'].touched && form.controls['phone'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "login_wizard.please_provide_phone" | translate }}</small>
            </p>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</form>
