import { BehaviorSubject, Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";

@Injectable({
  providedIn: "root",
})
export class UtilService extends BaseHttpService {
  private countrySelectedSubject = new BehaviorSubject<number | null>(null);
  public countrySelected$ = this.countrySelectedSubject.asObservable();
  public subscription!: Subscription;

  constructor(private http: HttpClient) {
    super();
  }
  destinationAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.http.post(`${this.apiUrl}/api/destinations`, { description: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
      });
    };
  }
  addDestinationWithCountry(value: string, countryId: number) {
    return this.http.post(`${this.apiUrl}/api/destinations`, { description: value, countryId: countryId });
  }

  closeModal(modal: any) {
    modal.close();
  }
  openModal(modal: any) {
    modal.open();
  }
  selectCountry(countryId: number) {
    this.countrySelectedSubject.next(countryId);
  }
  organizerAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.subscription = this.http.post(`${this.apiUrl}/api/organizers`, { name: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
        this.subscription.unsubscribe();
      });
    };
  }
  companiesAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.subscription = this.http.post(`${this.apiUrl}/api/companies`, { name: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
        this.subscription.unsubscribe();
      });
    };
  }

  hotelsAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.subscription = this.http.post(`${this.apiUrl}/api/hotels`, { name: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
        this.subscription.unsubscribe();
      });
    };
  }
  countriesAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.subscription = this.http.post(`${this.apiUrl}/api/Countries`, { nameEN: value, nameSR: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
        this.subscription.unsubscribe();
      });
    };
  }
}
