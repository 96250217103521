import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Office, OfficeBranchEmailConfiguration, OfficeBranchETursitCheck } from "../models/office-branch";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";

@Injectable({
  providedIn: "root",
})
export class OfficeBranchService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getOffices(): Observable<Office[]> {
    return this.http.get<Office[]>(`${this.apiUrl}/api/OfficeBranches`);
  }

  createOffice(formData: Office) {
    const apiUrl = `${this.apiUrl}/api/OfficeBranches`;
    return this.http.post<Office>(apiUrl, formData);
  }

  getOfficeById(id: number) {
    const apiUrl = `${this.apiUrl}/api/OfficeBranches/${id}`;
    return this.http.get<any>(apiUrl);
  }

  updateOffice(id: number, updatedWorker: Office) {
    const apiUrl = `${this.apiUrl}/api/OfficeBranches/${id}`;
    return this.http.put<Office>(apiUrl, updatedWorker);
  }

  updateOfficeEmailConfiguration(id: number, mailConfig: OfficeBranchEmailConfiguration) {
    const apiUrl = `${this.apiUrl}/api/OfficeBranches/update-email-config/${id}`;
    return this.http.put<any>(apiUrl, mailConfig);
  }

  updateOfficeGuid(id: number, data: OfficeBranchETursitCheck) {
    const apiUrl = `${this.apiUrl}/api/OfficeBranches/update-guid/${id}`;
    return this.http.put<any>(apiUrl, data);
  }

  uploadOfficeLogo(id: number, logo: File) {
    const formData: FormData = new FormData();
    formData.append("logoImage", logo);
    const apiUrl = `${this.apiUrl}/api/OfficeBranches/logo/${id}`;
    return this.http.post<Office>(apiUrl, formData);
  }
  deleteOffice(id: number) {
    const apiUrl = `${this.apiUrl}/api/OfficeBranches/${id}`;
    return this.http.delete<Office>(apiUrl);
  }
}
