<div *ngIf="loading" class="screen_loader animate__animated absolute inset-0 z-[35] grid place-content-center bg-[#fafafa] opacity-65 dark:bg-[#060818]">
  <img class="ml-[5px] h-auto w-20 flex-none" src="/assets/images/loader.svg" alt="" />
</div>
<div class="relative">
  <input
    [(ngModel)]="search"
    (input)="onSearchInput()"
    (keydown.enter)="onSearchChange(search)"
    type="text"
    class="form-input pr-10"
    placeholder="{{ 'search_hint' | translate }}"
    [appLimitCharacterInput]="60"
  />
  <span class="absolute inset-y-0 right-0 flex items-start pr-3 pt-2">
    <ng-container *ngIf="search && showClearIcon; else searchIcon">
      <div class="cursor-pointer" (click)="clearSearch()">
        <icon-x class="text-gray-500"></icon-x>
      </div>
    </ng-container>
    <ng-template #searchIcon>
      <icon-search class="text-gray-500"></icon-search>
    </ng-template>
  </span>
</div>
