<div class="space-y-8">
  <div class="panel pb-0">
    <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
      <app-search-input (searchChanged)="onSearchChange($event)"></app-search-input>
      <div class="flex items-center gap-5 ltr:ml-auto rtl:mr-auto">
        <button type="button" class="btn btn-outline-success flex" routerLink="/dashboard/group-travels/create">
          <icon-plus class="ltr:mr-2 rtl:ml-2" />
          {{ "add_group_travel" | translate }}
        </button>

        <div hlMenu class="dropdown">
          <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
            <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
            <icon-caret-down class="h-5 w-5" />
          </button>
          <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
            <ng-container *ngFor="let col of cols; index as i">
              <li>
                <div class="flex items-center px-4 py-1">
                  <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                    <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                    <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                  </label>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <app-export-table
          [exportApi]="'api/GroupTravels/export'"
          [filterType]="filterType"
          [sortColumn]="sortColumn"
          [sortDirection]="sortDirection"
          [search]="search"
          [columns]="exportCols"
          [filename]="'Group Travel Table Export'"
        ></app-export-table>
      </div>
    </div>

    <div class="datatable">
      <ng-datatable
        [rows]="items"
        [stickyHeader]="true"
        [height]="'670px'"
        [columns]="cols"
        [sortable]="true"
        [loading]="loading"
        [isServerMode]="true"
        [page]="pageNumber"
        [search]="search"
        [pageSize]="pageSize"
        [totalRows]="totalRows"
        (changeServer)="changeServer($event)"
        skin="whitespace-nowrap table-hover"
        [paginationInfo]="'paginationInfoLabel' | translate"
        [noDataContent]="'empty_tables' | translate"
        [showNumbersCount]="3"
      >
        <ng-template slot="actions" let-value="data">
          <div class="dropdown inline-flex">
            <button
              type="button"
              [ngxTippy]="tooltipTemplate"
              [tippyName]="value.id"
              [tippyContext]="{ data: value }"
              [tippyProps]="{ placement: 'right', interactive: true }"
              [routerLink]="'/dashboard/group-travels/' + '/' + value.id"
              class="btn btn-outline-secondary rounded-circle"
              (mouseenter)="onMouseEnter(value.id)"
            >
              <div class="flex h-6 w-6 items-center gap-2">
                <icon-menu-group-travel></icon-menu-group-travel>
              </div>
            </button>
          </div>
        </ng-template>
        <ng-template slot="createdAt" let-value="data">
          {{ value?.createdAt | date : "dd.MM.yyyy" }}
        </ng-template>
        <ng-template slot="updatedAt" let-value="data">
          {{ value?.updatedAt | date : "dd.MM.yyyy" }}
        </ng-template>
        <ng-template slot="passengerCount" let-value="data">
          {{ value?.passengerCount }}
        </ng-template>
        <ng-template slot="bruto" let-value="data">
          {{ value?.bruto | currency : "EUR" }}
        </ng-template>

        <ng-template slot="neto" let-value="data">
          {{ value?.neto | currency : "EUR" }}
        </ng-template>
        <ng-template slot="details" let-value="data">
          <div class="flex w-max items-center justify-between">
            <div hlMenu class="dropdown">
              <a hlMenuButton href="javascript:;">
                <icon-horizontal-dots class="rotate-90 opacity-70" />
              </a>
              <div #dropdownPlaceholder *hlMenuItems>
                <app-message-portal #messagePortalComponent @toggleAnimation (portalAttached)="onPortalAttached()">
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" [routerLink]="'/dashboard/group-travels/' + '/' + value.id">
                      <icon-info-circle class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "details_quick_label" | translate }}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" [routerLink]="'/dashboard/group-travels/' + '/edit/' + value.id" [queryParams]="{ returnFlag: true }">
                      <icon-pencil-paper class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "edit_quick_label" | translate }}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" (click)="createDuplicate(value)">
                      <icon-archive class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "group-travels_info.clone" | translate }}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" [routerLink]="'/dashboard/group-travels/' + value.id + '/plan'" *hlMenuItem="let menuItem">
                      <icon-user-plus class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "group-travels_info.add_passanger" | translate }}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" (click)="deleteGroupTravel(value.id)">
                      <icon-trash-lines class="shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "delete_quick_label" | translate }}
                    </a>
                  </li>
                </app-message-portal>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-datatable>
    </div>
  </div>
  <ng-template #tooltipTemplate let-name let-data="data">
    <ng-container *ngIf="currentId === data.id">
      <app-group-travel-quick-view [id]="data.id"></app-group-travel-quick-view>
    </ng-container>
  </ng-template>
</div>
