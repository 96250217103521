import { Store } from "@ngrx/store";
import { User } from "src/app/auth/model/user";
import { Office } from "../../models/office-branch";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { PopupType } from "src/app/shared/enums/popup-types";
import { updateUser } from "src/app/store/auth/auth.actions";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { selectFeatureUser } from "src/app/store/auth/auth.selector";
import { slideDownUp, toggleAnimation } from "src/app/shared/animations";
import { OfficeBranchService } from "../../services/office-branch.service";
import { ETouristService } from "src/app/shared/services/etourist.service";
import { catchError, filter, Observable, of, switchMap, take, tap } from "rxjs";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ETouristCity, ETouristMunicipality, ETouristStreet } from "src/app/shared/models/etourist";

@Component({
  selector: "app-office-branch-eturist-info-form",
  templateUrl: "./office-branch-eturist-info-form.component.html",
  animations: [toggleAnimation, slideDownUp],
})
export class OfficeBranchETuristInfoFormComponent implements OnInit, OnChanges {
  public user!: User;
  public form!: FormGroup;
  @Input() office!: Office;
  isLoading: boolean = false;
  public guidForm!: FormGroup;
  isValidated: boolean = false;
  private btnFlag: boolean = false;

  user$!: Observable<User | undefined>;
  public optionsCities$!: Observable<ETouristCity[]>;
  public optionsStreets$!: Observable<ETouristStreet[]>;
  public optionsMunicipality$!: Observable<ETouristMunicipality[]>;
  private hasInitialized: boolean = false;

  constructor(private officeBranchService: OfficeBranchService, public translate: TranslateService, public fb: FormBuilder, public store: Store<AppState>, public eTouristService: ETouristService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["office"] && this.office && !this.hasInitialized) {
      this.initForm(this.office);
      const guid = this.office.guid ?? "";
      this.guidForm.get("guid")?.setValue(guid);
      this.hasInitialized = true;
    }
  }

  ngOnInit(): void {
    this.guidForm = this.fb.group({
      guid: ["", [Validators.required]],
      jmbg: ["", [Validators.required]],
    });

    this.user$ = this.store.select(selectFeatureUser);
    this.user$
      .pipe(
        take(1),
        tap((res) => {
          if (res) this.user = res;
          this.guidForm.get("jmbg")?.setValue(this.user.uniqueMasterCitizenNumber);
        })
      )
      .subscribe();
  }

  reset() {
    if (this.btnFlag) this.btnFlag = false;
    else this.isValidated = false;
  }

  initForm(office: Office): void {
    const { municipality, municipalityId, city, cityId, street, streetId } = office || ({} as Office);

    this.form = this.fb.group({
      municipality: [{ nameLatin: municipality, identificationNumber: municipalityId }, Validators.required],
      city: [{ nameLatin: city, identificationNumber: cityId }, Validators.required],
      street: [{ nameLatin: street, identificationNumber: streetId }, Validators.required],
    });

    this.setupValueChanges();
  }

  validateGUID(): void {
    if (!this.guidForm.valid) {
      this.guidForm.markAllAsTouched();
      return;
    }

    const jmbg = this.guidForm.value.jmbg;
    this.isLoading = true;
    const subscription = this.officeBranchService.updateOfficeGuid(this.office.id, this.guidForm.getRawValue()).subscribe({
      next: () => {
        subscription.unsubscribe();
        this.btnFlag = true;
        this.isValidated = true;
        this.office = { ...this.office, ...this.guidForm.getRawValue() };
        this.isLoading = false;
        this.fetchOptions();

        const data = {
          name: this.user.name,
          surname: this.user.surname,
          settings: this.user.settings,
          uniqueMasterCitizenNumber: jmbg,
        };
        this.store.dispatch(updateUser({ user: data }));
      },
      error: (error) => {
        this.isLoading = false;
        subscription.unsubscribe();
        showMessage(PopupType.Danger, error.error.toString());
      },
    });
  }

  async submitForm(): Promise<boolean> {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    if (!this.guidForm.valid) {
      this.guidForm.markAllAsTouched();
      return false;
    }

    return new Promise((resolve) => {
      if (this.office) {
        const officeLocation = this.fetchOffice();
        const updateSubscription = this.officeBranchService.updateOffice(this.office.id, officeLocation).subscribe({
          next: () => {
            updateSubscription.unsubscribe();
            resolve(true);
            showMessage(PopupType.Success, this.translate.instant("office-branch.eturista_confirmation_message"));
            this.initForm({} as Office);
          },
          error: (error) => {
            resolve(false);
            updateSubscription.unsubscribe();
            showMessage(PopupType.Danger, error.toString());
          },
        });
      }
    });
  }

  fetchOptions() {
    this.optionsMunicipality$ = this.eTouristService.getMunicipality().pipe(
      catchError((error) => {
        showMessage(PopupType.Danger, error.error);
        return of([]);
      })
    );
  }

  setupValueChanges() {
    this.form
      .get("municipality")
      ?.valueChanges.pipe(
        filter((value) => !!value),
        switchMap((municipality: ETouristMunicipality) => {
          this.form.get("city")?.reset();
          this.form.get("city")?.enable();
          this.form.get("street")?.reset();
          this.form.get("street")?.disable();
          return this.eTouristService.getCities(municipality.identificationNumber).pipe(
            catchError((error) => {
              showMessage(PopupType.Danger, error.error);
              return of([]);
            })
          );
        })
      )
      .subscribe((cities) => {
        this.optionsCities$ = of(cities);
      });

    this.form
      .get("city")
      ?.valueChanges.pipe(
        filter((value) => !!value),
        switchMap((city: ETouristCity) => {
          this.form.get("street")?.reset();
          this.form.get("street")?.enable();
          return this.eTouristService.getStreets(city.identificationNumber).pipe(
            catchError((error) => {
              showMessage(PopupType.Danger, error.error);
              return of([]);
            })
          );
        })
      )
      .subscribe((streets) => {
        this.optionsStreets$ = of(streets);
      });
  }

  fetchOffice(): Office {
    const { municipality, city, street } = this.form.getRawValue();

    return {
      ...this.office,
      municipality: municipality.nameLatin,
      city: city.nameLatin,
      street: street.nameLatin,
      municipalityId: municipality.identificationNumber,
      cityId: city.identificationNumber,
      streetId: street.identificationNumber,
    } as Office;
  }
}
