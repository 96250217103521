<div *ngIf="isLoading" class="screen_loader animate__animated absolute inset-0 z-[35] grid place-content-center bg-[#fafafa] opacity-65 dark:bg-[#060818]">
  <img class="ml-[5px] h-auto w-20 flex-none" src="/assets/images/loader.svg" alt="" />
</div>

<div class="mb-3 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
  <button (click)="openModalAddingOfficeLocation(id)" class="btn btn-primary flex items-center justify-center">
    <img src="assets/images/eturista-logo.png" class="h-full max-h-6" />
  </button>
  <button (click)="openEmailModal()" class="btn btn-primary">
    <icon-mail class="h-6 w-4.5 shrink-0" />
  </button>
  <button type="button" class="btn btn-primary gap-2" *ngIf="office">
    <a class="flex items-center justify-center" href="javascript:;" [routerLink]="'/dashboard/workers/office-branch/edit/' + office.id" [queryParams]="{ returnId: office.id }">
      <icon-pencil-paper class="h-6 w-4.5 shrink-0" />
    </a>
  </button>
</div>

<div *ngIf="office">
  <div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "office-branch.basic_information" | translate }}
          </div>
        </div>

        <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "office-branch.name" | translate }}
          </div>
          <div class="text-xl">
            {{ office.name }}
          </div>
        </div>

        <div class="col-span-2 flex flex-col pb-4 sm:col-span-1">
          <div class="custom-file-container">
            <div class="image-wrapper w-full rounded bg-gray-200 p-2"><img [src]="office.logoImage ? office.logoImage.url : ''" alt="" class="h-auto max-h-64 w-full rounded object-contain" /></div>
          </div>
        </div>

        <div class="col-span-1 ml-0 flex flex-col sm:col-span-1 sm:ml-3">
          <div class="flex flex-col">
            <div class="font-bold">{{ "office-branch.bankAccount" | translate }}:</div>
            <div>{{ office.bankAccount }}</div>
          </div>
          <div class="mt-2 flex flex-col">
            <div class="font-bold">{{ "office-branch.PIB" | translate }}:</div>
            <div>{{ office.pib }}</div>
          </div>
          <div class="mt-2 flex flex-col">
            <div class="font-bold">{{ "office-branch.MB" | translate }}:</div>
            <div>{{ office.mb }}</div>
          </div>
        </div>

        <div class="col-span-1 flex flex-col pl-0 sm:col-span-1 sm:pl-4">
          <div class="flex flex-col">
            <div class="font-bold">{{ "office-branch.licence" | translate }}:</div>
            <div>{{ office.licence }}</div>
          </div>
          <div class="mt-2 flex flex-col">
            <div class="font-bold">{{ "office-branch.phoneNumber" | translate }}:</div>
            <div>{{ office.phoneNumber }}</div>
          </div>
          <div class="mt-2 flex flex-col">
            <div class="font-bold">{{ "office-branch.email" | translate }}:</div>
            <div>{{ office.email }}</div>
          </div>
        </div>

        <div class="col-span-1 mt-2 flex flex-col pl-0 sm:col-span-1 sm:mt-0 sm:pl-4">
          <div class="flex flex-col">
            <div class="font-bold">{{ "office-branch.siteUrl" | translate }}:</div>
            <a [href]="'https://www.' + office.siteURL" class="underline hover:text-blue-800" target="_blank"> https://www.{{ office.siteURL }} </a>
          </div>
          <div class="mt-2 flex flex-col">
            <div class="font-bold">{{ "office-branch.full_address" | translate }}:</div>
            <div>{{ office.fullAddress }}</div>
          </div>
        </div>
      </div>

      <div class="relative col-span-4 flex items-center pt-5">
        <div class="flex-grow border-t border-gray-400"></div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 flex grid grid-cols-2 flex-col pb-4 sm:col-span-2">
          <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
            <div class="text-xl font-bold">
              {{ "office-branch.eturist_information" | translate }}
            </div>
          </div>

          <div class="col-span-1 flex flex-col sm:col-span-1">
            <div class="flex flex-col">
              <div class="font-bold">{{ "office-branch.city" | translate }}</div>
              <div>{{ office.city ? office.city : "-" }}</div>
            </div>
            <div class="mt-2 flex flex-col">
              <div class="font-bold">{{ "office-branch.street" | translate }}</div>
              <div>{{ office.street ? office.street : "-" }}</div>
            </div>
          </div>

          <div class="col-span-1 flex flex-col sm:col-span-1">
            <div class="mt-2 flex flex-col sm:mt-0">
              <div class="font-bold">{{ "office-branch.municipality" | translate }}</div>
              <div>{{ office.municipality ? office.municipality : "-" }}</div>
            </div>
            <div class="mt-2 flex flex-col">
              <div class="font-bold">{{ "office-branch.guid" | translate }}</div>
              <div>{{ office.guid }}</div>
            </div>
          </div>
        </div>

        <div class="col-span-2 flex grid grid-cols-2 flex-col pb-4 pl-0 sm:col-span-2 sm:pl-4">
          <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
            <div class="text-xl font-bold">
              {{ "office-branch.social_networks" | translate }}
            </div>
          </div>

          <div class="col-span-1 flex flex-col sm:col-span-1">
            <div class="flex flex-col">
              <div class="font-bold">Instagram:</div>
              <a *ngIf="office.instagramURL; else noInstagram" [href]="office.instagramURL" class="underline hover:text-blue-800" target="_blank">
                {{ office.instagramURL }}
              </a>
              <ng-template #noInstagram>-</ng-template>
            </div>
            <div class="flex flex-col">
              <div class="font-bold">Facebook:</div>
              <a *ngIf="office.facebookURL; else noFacebook" [href]="office.facebookURL" class="underline hover:text-blue-800" target="_blank">
                {{ office.facebookURL }}
              </a>
              <ng-template #noFacebook>-</ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="relative col-span-4 flex items-center pt-5">
        <div class="flex-grow border-t border-gray-400"></div>
      </div>

      <div class="col-span-2 flex grid grid-cols-1 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-1 flex flex-col pb-4 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "office-branch.email_information" | translate }}
          </div>
        </div>

        <div class="col-span-1 flex flex-col sm:col-span-1">
          <div class="flex flex-col">
            <div class="font-bold">{{ "office-branch.senderEmail" | translate }}</div>
            <div>{{ office.senderEmail }}</div>
          </div>
          <div class="mt-2 flex flex-col">
            <div class="font-bold">{{ "office-branch.senderName" | translate }}</div>
            <div>{{ office.senderName }}</div>
          </div>
        </div>

        <div class="col-span-1 mt-2 flex flex-col sm:col-span-1 sm:mt-0">
          <div class="flex flex-col">
            <div class="font-bold">{{ "office-branch.adminEmail" | translate }}</div>
            <div>{{ office.adminEmail }}</div>
          </div>
          <div class="mt-2 flex flex-col">
            <div class="font-bold">{{ "office-branch.password" | translate }}</div>
            <div *ngIf="office.password; else noPassword">
              <div class="flex items-center">
                <button class="mr-2 focus:outline-none" (click)="togglePasswordVisibility()">
                  <icon-eye-closed *ngIf="!isPasswordHidden; else hideIcon" class="dark:text-white" />
                  <ng-template #hideIcon>
                    <icon-eye-open class="dark:text-white" />
                  </ng-template>
                </button>

                <span>
                  {{ isPasswordHidden ? "•".repeat(office.password.length || 0) : office.password }}
                </span>
              </div>
              <!-- Toggle visibility button -->
            </div>
            <ng-template #noPassword>-</ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<modal #emailConfigModal class="modal-center auth-modal" [closeOnOutsideClick]="false">
  <ng-template #modalHeader>
    <div class="!py-5">
      {{ "office-branch.email_settings" | translate }}
    </div>
  </ng-template>
  <ng-template #modalBody>
    <div class="my-auto">
      <app-email-configuration-modal [office]="office" (close)="closeEmailModal()"></app-email-configuration-modal>
    </div>
  </ng-template>
  <ng-template #modalFooter></ng-template>
</modal>

<modal #addOfficeLocationModal class="modal-center" [closeOnOutsideClick]="false">
  <ng-template #modalHeader
    ><div class="!py-5">{{ "office-branch.eturist_information" | translate }}</div></ng-template
  >
  <ng-template #modalBody>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-1" *ngIf="id">
      <app-office-branch-location-form [id]="id" (submissionDone)="closeLocationsModal()"></app-office-branch-location-form>
    </div>
  </ng-template>
  <ng-template #modalFooter></ng-template>
</modal>
