<div class="datatable" *ngIf="groupTravelPassangers">
  <ng-datatable
    #datatable
    [rows]="groupTravelPassangers"
    [columns]="cols"
    [sortable]="true"
    [hasCheckbox]="true"
    [pageSize]="pageSize"
    [totalRows]="totalRows"
    skin="whitespace-nowrap table-hover"
    [paginationInfo]="'paginationInfoLabel' | translate"
    [noDataContent]="'empty_tables' | translate"
  >
    <ng-template slot="id" let-value="data">
      <span>{{ value.planId }}</span>
    </ng-template>
    <ng-template slot="serialNumber" let-value="data">
      <td>{{ value.serialNumber }}</td>
    </ng-template>
    <ng-template slot="confirmationNumber" let-value="data">
      <td>{{ selectedPlan?.name }}</td>
    </ng-template>
    <ng-template slot="transportType" let-value="data">
      <td>{{ selectedTransport?.name }}</td>
    </ng-template>
    <ng-template slot="accommodationType" let-value="data">
      <td>{{ selectedService?.name }}</td>
    </ng-template>

    <ng-template slot="numberPassangers" let-value="data">
      <button *ngIf="value.passengers.length > 0; else noPassengers" type="button" (click)="toggleTreeview('passengers-' + value.planId)" class="active btn btn-outline-primary">
        <icon-caret-down [class]="'w-5 h-5 text-primary inline relative -top-1 ltr:mr-2 rtl:ml-2 ' + [treeview1.includes('passengers-' + value.planId) ? 'rotate-180' : '']" />
        <span> +{{ value.passengers.length }}</span>
      </button>

      <ng-template #noPassengers>
        <button [ngxTippy]="tooltipMessage | translate" tooltipClass="custom-tooltip " type="button" class="d-flex justify-content-center align-items-center ml-5">
          <icon-info-circle class="cursor-pointer text-danger"></icon-info-circle>
        </button>
      </ng-template>
    </ng-template>

    <ng-template slot="name" let-value="data">
      {{ value?.mainCustomer.name }}
      <div [@slideDownUp]="!treeview1.includes('passengers-' + value.planId)" class="accordion-content">
        <ul>
          <ng-container *ngFor="let passenger of value.passengers">
            <li class="py-[5px]">
              {{ passenger.customer?.name }}
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-template>

    <ng-template slot="surname" let-value="data">
      {{ value?.mainCustomer.surname }}
      <div [@slideDownUp]="!treeview1.includes('passengers-' + value.planId)" class="accordion-content">
        <ul>
          <ng-container *ngFor="let passenger of value.passengers">
            <li class="py-[5px]">
              {{ passenger.customer?.surname }}
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-template>
    <ng-template slot="dateOfBirth" let-value="data">
      {{ value?.mainCustomer.dateOfBirth | date : "dd.MM.yyyy." }}
      <div [@slideDownUp]="!treeview1.includes('passengers-' + value.planId)" class="accordion-content">
        <ul>
          <ng-container *ngFor="let passenger of value.passengers">
            <li class="py-[5px]">
              {{ passenger.customer?.dateOfBirth | date : "dd.MM.yyyy." }}
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-template>
    <ng-template slot="address" let-value="data">
      {{ value?.mainCustomer.address }}
      <div [@slideDownUp]="!treeview1.includes('passengers-' + value.planId)" class="accordion-content">
        <ul>
          <ng-container *ngFor="let passenger of value.passengers">
            <li class="py-[5px]">
              {{ passenger.customer?.address }}
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-template>
    <ng-template slot="phoneNumber" let-value="data">
      {{ value?.mainCustomer.phoneNumber }}
      <div [@slideDownUp]="!treeview1.includes('passengers-' + value.planId)" class="accordion-content">
        <ul>
          <ng-container *ngFor="let passenger of value.passengers">
            <li class="py-[5px]">
              {{ passenger.customer?.phoneNumber }}
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-template>
    <ng-template slot="totalPrice" let-value="data">
      <span>{{ value?.totalPrice | currency : "EUR" }}</span>
    </ng-template>
    <ng-template slot="status" let-value="data">
      <span class="badge bg-success" [ngClass]="{ 'bg-danger': !value.planStatus, 'bg-success': value.planStatus }">
        <span *ngIf="value.planStatus; else unpaidTemplate">{{ "paid" | translate }}</span>
        <ng-template #unpaidTemplate>
          <span>{{ "not_paid" | translate }}</span>
        </ng-template>
      </span>
    </ng-template>
    <ng-template slot="avans" let-value="data">
      <span>{{ value?.avans | currency : "EUR" }}</span>
    </ng-template>
    <ng-template slot="paymentType" let-value="data">
      <span>{{ value?.paymentType }}</span>
    </ng-template>
    <ng-template slot="groupNumber" let-value="data">
      <span>{{ value?.groupNumber }}</span>
    </ng-template>
  </ng-datatable>
</div>
<div class="flex items-center justify-end">
  <button type="submit" class="btn btn-primary" [disabled]="loading" (click)="save()">
    <ng-container *ngIf="!loading; else loadingTemplate">{{ "account_data.save" | translate }} </ng-container>
    <ng-template #loadingTemplate>
      <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle ltr:mr-4 rtl:ml-4"></span>{{ "loading" | translate }}
    </ng-template>
  </button>
</div>
