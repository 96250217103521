<!-- <div class="font-large flex items-center justify-center pb-10 text-base text-[#1f2937] dark:text-dark">
  <h1 class="text-start">{{ title }}</h1>
</div> -->
<div class="d-flex align-items-center">
  <p class="mb-0 me-3">{{ "languages.documentLanguageSelection" | translate }}</p>
</div>
<div style="display: flex; gap: 10px; align-items: center">
  <label>
    <input type="radio" name="language" value="sr-RS" [(ngModel)]="selectedLanguage" />
    {{ "languages.serbian" | translate }}
  </label>
  <label>
    <input type="radio" name="language" value="en-US" [(ngModel)]="selectedLanguage" />
    {{ "languages.english" | translate }}
  </label>
</div>

<div class="space-y-5">
  <div class="mb-4">
    <ng-content></ng-content>
    <div class="mb-4">
      <tag-input
        [(ngModel)]="emails"
        [modelAsStrings]="true"
        [placeholder]="'contractsendemail.email_to_send' | translate"
        [secondaryPlaceholder]="'contractsendemail.email_to_send' | translate"
        [maxItems]="4"
        [blinkIfDupe]="true"
        [validators]="validators"
        [trimTags]="true"
        [errorMessages]="errorMessages"
      ></tag-input>
      <p>{{ "contractsendemail.email_hint" | translate }}</p>
    </div>
  </div>
  <form [formGroup]="form" (submit)="sendDocument()" class="flex items-center justify-center gap-5">
    <button type="submit" class="btn btn-primary w-30 h-10" [disabled]="isLoading">
      <ng-container *ngIf="isLoading; else alternateTranslation">
        <span class="inline-block h-3 w-3 animate-ping rounded-full bg-white ltr:mr-4 rtl:ml-4"></span>
        {{ "loading" | translate }}
      </ng-container>
      <ng-template #alternateTranslation>
        {{ "send" | translate }}
      </ng-template>
    </button>
  </form>
</div>
