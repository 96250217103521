import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { TravelsService } from "../../travels/services/travels.service";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { ETuristFinalUpdate } from "src/app/shared/models/etourist";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Sale } from "../../sales/models/sale";
import { GroupTravelsService } from "../../group-travels/services/group-travels.service";

@Component({
  selector: "app-travel-e-turist-form-update",
  templateUrl: "./travel-e-turist-form-update.component.html",
  providers: [DatePipe],
})
export class TravelETuristFormUpdateComponent implements OnInit {
  @Input() id!: number;
  @Input() plan!: Sale;
  @Output() eventClose = new EventEmitter<null>();
  @Input() IsGroupETourist!: boolean;

  loading = false;
  form!: FormGroup;

  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  constructor(private travelService: TravelsService, public translate: TranslateService, public fb: FormBuilder, private datePipe: DatePipe, public groupTravelService: GroupTravelsService) {}

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    const today = this.datePipe.transform(new Date(), "dd.MM.yyyy");
    this.form = this.fb.group({
      recipeNumber: [null, Validators.required],
      finalAmount: [null, Validators.required],
      dateOfRecipeIssue: [today, Validators.required],
    });

    this.form.get("finalAmount")?.setValue(this.plan.bruto * this.plan.exchangeRate);
  }

  getAccountData() {
    const { recipeNumber, finalAmount, dateOfRecipeIssue } = this.form.value as ETuristFinalUpdate;

    const [day, month, year] = dateOfRecipeIssue.split(".");
    const formattedDate = `${year}-${month}-${day}`;
    return {
      recipeNumber,
      finalAmount,
      dateOfRecipeIssue: formattedDate,
    };
  }

  createAccountData() {
    this.loading = true;
    const accountData = this.getAccountData();
    if (this.IsGroupETourist) {
      const subscription = this.groupTravelService.updateGroupTravelPlanTuristData(this.id, accountData).subscribe({
        next: (res) => {
          this.loading = false;
          subscription.unsubscribe();
          if (res.item2) {
            showMessage(PopupType.Danger, res.item2);
          } else {
            showMessage(PopupType.Success, this.translate.instant("popup.accounting_data_created_successfully"));
            this.eventClose.emit();
          }
        },
        error: () => {
          this.loading = false;
          subscription.unsubscribe();
          showMessage(PopupType.Danger, this.translate.instant("popup.error_while_creating_accounting_data"));
        },
      });
    } else {
      const subscription = this.travelService.updateEvidentionToETurist(this.id, accountData).subscribe({
        next: (res) => {
          this.loading = false;
          subscription.unsubscribe();
          if (res.item2) {
            showMessage(PopupType.Danger, res.item2);
          } else {
            showMessage(PopupType.Success, this.translate.instant("popup.accounting_data_created_successfully"));
            this.eventClose.emit();
          }
        },
        error: () => {
          this.loading = false;
          subscription.unsubscribe();
          showMessage(PopupType.Danger, this.translate.instant("popup.error_while_creating_accounting_data"));
        },
      });
    }
  }

  save() {
    if (this.form.invalid) {
      showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
      this.form.markAllAsTouched();
      return;
    }
    this.createAccountData();
  }

  public toFixed = (value: string | number | undefined | null): string => {
    if (value === null || value === undefined || value === "") {
      return "";
    }

    const formattedValue = String(value).split(" ").join("");

    if (formattedValue.includes(".") && formattedValue.split(".").length === 2) {
      const decimalLength = formattedValue.split(".")[1]?.length;
      if (decimalLength && decimalLength > 2) {
        return parseFloat(formattedValue).toFixed(2);
      }
    }

    return formattedValue;
  };
}
