import { Store } from "@ngrx/store";
import { finalize, first } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Customer, PassangerTypes } from "../models/customer";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { CustomersService } from "../services/customers.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { safeParseSimpleDate } from "src/app/shared/utils/datetime.formatter";
import { dateFormatValidator, transformDateForFetch } from "src/app/shared/validators/date.validator";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AutocompleteRemoteComponent } from "src/app/shared/components/autocomplete-remote/autocomplete-remote.component";

@Component({
  selector: "app-customer-create",
  templateUrl: "./customer.create.component.html",
  animations: [toggleAnimation],
})
export class CustomerCreateComponent implements OnInit, AfterViewInit {
  EntityPicklistType = EntityPicklistType;
  @Input() passanger = false;
  @Input() withReservation = false;
  @Input() defaultCustomer: Customer = {} as Customer;
  @Output() submitCustomer: EventEmitter<Customer> = new EventEmitter<Customer>();
  @Output() validForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() customerId: number | undefined;
  @ViewChild("nameInput") nameInput!: ElementRef;
  @ViewChild("customerComplete") customerComplete!: AutocompleteRemoteComponent;
	@ViewChild('reservationNumberInput') reservationNumberInput!: ElementRef;
  @ViewChild('phoneNumberInput') phoneNumberInput!: ElementRef;

  public hiddenDetails = true;
  public form!: FormGroup;
  public isSubmitForm = false;
  public id!: number;
  public hideNameAndSurname = false;
  public isMainCustomer!: boolean;
  public isChild = false;

  constructor(private customersService: CustomersService, public fb: FormBuilder, public store: Store<AppState>, public translate: TranslateService) {}

  initForm(customer: Customer, hideName = true): void {
    const {
      id = this.id,
      name = this.form ? this.form.controls["name"].value : null,
      surname = this.form ? this.form.controls["surname"].value : null,
      dateOfBirth = this.form ? this.form.controls["dateOfBirth"].value : null,
      phoneNumber = this.form ? this.form.controls["phoneNumber"].value : null,
      imbg = this.form ? this.form.controls["imbg"].value : null,
      address = this.form ? this.form.controls["address"].value : null,
      profession = this.form ? this.form.controls["profession"].value : null,
      passport = this.form ? this.form.controls["passport"].value : null,
      email = this.form ? this.form.controls["email"].value : "",
      passportExpirationDate = this.form ? this.form.controls["passportExpirationDate"].value : null,
      passportIssuingDate = this.form ? this.form.controls["passportIssuingDate"].value : null,
      reservationNumber = this.form ? this.form.controls["reservationNumber"].value : null,
      isMainCustomer = this.form ? this.form.controls["isMainCustomer"].value : false,
      mainCustomer,
    } = customer || {};

    this.id = id;
    this.defaultCustomer;
    if (this.defaultCustomer.lifeStage === "children" || this.defaultCustomer.lifeStage === "infants") this.isChild = true;

    this.isMainCustomer = mainCustomer === undefined ? this.isMainCustomer : mainCustomer;

    this.form = this.fb.group({
      name: [name, Validators.required],
      surname: [surname, Validators.required],
      phoneNumber: [phoneNumber, this.isMainCustomer === undefined ? [] : this.isChild ? [] : Validators.required],
      dateOfBirth: [transformDateForFetch(dateOfBirth), !this.hiddenDetails ? { validators: [dateFormatValidator()], updateOn: "blur" } : []],
      imbg: [imbg, []],
      address: [address, []],
      profession: [profession, []],
      passport: [passport, []],
      email: [email, this.isChild ? [] : !this.hiddenDetails ? [Validators.email] : []],
      passportExpirationDate: [transformDateForFetch(passportExpirationDate), !this.hiddenDetails ? { validators: [dateFormatValidator()], updateOn: "blur" } : []],
      passportIssuingDate: [transformDateForFetch(passportIssuingDate), !this.hiddenDetails ? { validators: [dateFormatValidator()], updateOn: "blur" } : []],
      reservationNumber: [reservationNumber, this.isMainCustomer && this.withReservation ? Validators.required : []],
      isMainCustomer: [isMainCustomer],
    });
    this.form.get("isMainCustomer")?.valueChanges.subscribe((value) => {
      this.handleMainCustomerChange(value);
    });
    this.updatePhoneNumberValidators();

		if (hideName) {
			this.hideNameAndSurname = true;
			setTimeout(() => {
				this.focusAppropriateField();
			}, 0);
		}
		
    if (hideName) this.hideNameAndSurname = true;
    this.form.valueChanges.subscribe(() => {
      this.validForm.emit(this.checkIfValid());
    });
    this.form.markAsTouched();
    this.form.updateValueAndValidity();
  }

  updatePhoneNumberValidators(): void {
    const phoneNumberControl = this.form.get("phoneNumber");
    if (this.defaultCustomer && this.defaultCustomer.lifeStage === PassangerTypes.adults && this.isMainCustomer) {
      phoneNumberControl?.setValidators(Validators.required);
    } else {
      phoneNumberControl?.clearValidators();
    }
    phoneNumberControl?.updateValueAndValidity();
  }

  handleMainCustomerChange(isMainCustomer: boolean): void {
    const reservationNumberControl = this.form.get("reservationNumber");
    if (isMainCustomer && this.withReservation) {
      reservationNumberControl?.setValidators(Validators.required);
    } else {
      reservationNumberControl?.clearValidators();
    }
    reservationNumberControl?.updateValueAndValidity();
  }

  setCustomerNameSurname(val: string) {
    const [name, surname] = this.splitAtFirstOccurrence(val, " ");
    this.form.get("name")?.setValue(name);
    this.form.get("surname")?.setValue(surname);
    this.hideNameAndSurname = true;

		setTimeout(() => {
			this.focusAppropriateField();
		}, 0);
  }

  clearCustomer() {
    this.hideNameAndSurname = false;
    this.form.reset();
  }

	private focusAppropriateField(): void {
		if (this.withReservation && this.isMainCustomer) {
			this.reservationNumberInput?.nativeElement.focus();
		} else if (!this.isChild) {
			this.phoneNumberInput?.nativeElement.focus();
		}
	}
	
  toggleHiddenDetails() {
    this.hiddenDetails = !this.hiddenDetails;
    this.initForm(this.defaultCustomer);
  }

  ngOnInit(): void {
    this.initForm(this.defaultCustomer, false);
    this.trackFieldStatus();
    if (this.form.controls["name"].value && this.form.controls["surname"].value) {
      this.hideNameAndSurname = true;
    }
    this.form.markAsTouched();
    this.form.updateValueAndValidity();

    setTimeout(() => {
      this.focusOnInput();
    }, 0);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.focusOnInput();
    }, 0);
  }

  focusOnInput(): void {
    const inputElement = this.hideNameAndSurname ? this.nameInput.nativeElement : this.customerComplete.autocomplete;
    inputElement.focus();
  }

  checkIfValid(): boolean {
    this.form.valueChanges.subscribe(() => {
      this.validForm.emit(this.form.valid);
    });
    return this.form.valid;
  }

  submitForm(): void {
    if (!this.checkIfValid()) {
      this.form.markAllAsTouched();
      showMessage(PopupType.Danger, this.translate.instant("forms_invalid_ticket_sale_checkout"));
      return;
    }
    this.isSubmitForm = true;
    const customer = this.form.getRawValue();
    customer.dateOfBirth = safeParseSimpleDate(customer.dateOfBirth);
    customer.passportExpirationDate = safeParseSimpleDate(customer.passportExpirationDate);
    customer.passportIssuingDate = safeParseSimpleDate(customer.passportIssuingDate);
    this.saveEditCustomer(customer, () => {
      this.submitCustomer.emit({ ...customer, id: this.id });
    });
  }

  trackFieldStatus() {
    if (this.form) {
      Object.keys(this.form.controls).forEach((key) => {
        this.form.get(key)?.statusChanges.subscribe(() => {
          this.validForm.emit(this.form.valid);
        });
      });
    }
  }

  getCustomer(): Customer {
    const customer = this.form.getRawValue();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.saveEditCustomer(customer, () => {}); // No need for callback here
    return { ...customer, id: this.id };
  }

  private saveEditCustomer(customer: Customer, callback: (c: Customer) => void): void {
    this.form.updateValueAndValidity();
    if (!this.form.valid) return;

    if (!this.id) {
      this.createCustomer(customer, callback);
      return;
    }

    if (this.form.untouched) return;

    this.customersService
      .editCustomer(this.id, customer)
      .pipe(
        first(),
        finalize(() => this.form.markAsUntouched())
      )
      .subscribe({
        next: (response) => {
          callback({ ...response });
        },
        error: (error) => showMessage(PopupType.Danger, error.message),
      });
  }

  discard() {
    this.submitCustomer.emit({ ...this.defaultCustomer });
  }

  private createCustomer(customer: Customer, callback: (c: Customer) => void): void {
    this.customersService
      .createCustomer(customer)
      .pipe(first())
      .subscribe({
        next: (response) => {
          this.id = response.id;
          this.form.markAsUntouched();
          callback({ ...response });
        },
        error: (error) => showMessage(PopupType.Danger, error.message),
      });
  }

  splitAtFirstOccurrence(str: string, separator: string) {
    const index = str.indexOf(separator);

    if (index === -1) {
      return [str, ""];
    } else {
      const firstPart = str.slice(0, index);
      const secondPart = str.slice(index + separator.length);
      return [firstPart, secondPart];
    }
  }
}