import { Component, OnInit } from "@angular/core";
import { slideDownUp, toggleAnimation } from "src/app/shared/animations";
import { CustomFile, Office } from "../models/office-branch";
import { TranslateService } from "@ngx-translate/core";
import { OfficeBranchService } from "../services/office-branch.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FileUploadWithPreview } from "file-upload-with-preview";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";

@Component({
  selector: "app-office-branch-edit",
  templateUrl: "./office-branch-edit.component.html",
  animations: [toggleAnimation, slideDownUp],
})
export class OfficeBranchEditComponent implements OnInit {
  public id!: number;
  public form!: FormGroup;
  public office!: Office;
  isLoading: boolean = false;
  public imageUpload!: FileUploadWithPreview;
  protected existingLogo!: CustomFile | undefined;
  returnId!: number;
  returnFlag!: boolean;

  constructor(private route: ActivatedRoute, private router: Router, private officeBranchService: OfficeBranchService, public translate: TranslateService, public fb: FormBuilder) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = parseInt(params["id"]);
      this.returnId = +this.route.snapshot.queryParams["returnId"] || 0;
      this.route.queryParams.subscribe((queryParams) => {
        this.returnFlag = queryParams["returnFlag"] === "true";
      });
      if (this.id > 0) {
        this.fetchBranchData();
      }
    });
  }

  fetchBranchData() {
    this.isLoading = true;
    const subscription = this.officeBranchService.getOfficeById(this.id).subscribe((response) => {
      subscription.unsubscribe();
      this.isLoading = false;
      this.office = response;
      this.initForm(this.office);
    });
  }

  initForm(office: Office): void {
    this.office = office;
    const { name, email, phoneNumber, logoImage, siteURL: siteUrl, bankAccount, pib, fullAddress, instagramURL, facebookURL, mb, licence } = office || ({} as Office);
    this.existingLogo = logoImage;
    this.form = this.fb.group({
      name: [name, Validators.required],
      bankAccount: [bankAccount, Validators.required],
      PIB: [pib, Validators.required],
      MB: [mb, Validators.required],
      phoneNumber: [phoneNumber, Validators.required],
      email: [email, [Validators.email, Validators.required]],
      siteUrl: [siteUrl, Validators.required],
      licence: [licence, Validators.required],
      fullAddress: [fullAddress, Validators.required],
      instagramURL: [instagramURL || ""],
      facebookURL: [facebookURL || ""],
    });

    if (!logoImage) {
      setTimeout(() => {
        this.initializeFileUploader();
      });
    }
  }

  submitForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    const updateSubscription = this.officeBranchService.updateOffice(this.id, this.fetchOffice()).subscribe({
      next: (response) => {
        updateSubscription.unsubscribe();
        showMessage(PopupType.Success, this.translate.instant("popup.office_branch_updated_successfully"));

        if (this.imageUpload && this.imageUpload.cachedFileArray.length > 0) {
          const uploadImgSubscription = this.officeBranchService.uploadOfficeLogo(response.id, this.imageUpload.cachedFileArray[0]).subscribe({
            next: () => {
              this.isLoading = false;
              uploadImgSubscription.unsubscribe();
              showMessage(PopupType.Success, this.translate.instant("popup.office_branch_image_updated_successfully"));
              if (this.returnFlag) {
                this.router.navigate(["/dashboard/workers"]);
              } else if (this.returnId) {
                this.router.navigate(["/dashboard/workers/office-branch", this.returnId]);
              } else {
                this.router.navigate(["/dashboard/settings/office-branches"]);
              }
            },
            error: () => {
              uploadImgSubscription.unsubscribe();
              showMessage(PopupType.Danger, this.translate.instant("popup.office_branch_image_updated_successfully"));
            },
          });
        } else {
          this.isLoading = false;
          if (this.returnFlag) {
            this.router.navigate(["/dashboard/workers"]);
          } else if (this.returnId) {
            this.router.navigate(["/dashboard/workers/office-branch", this.returnId]);
          } else {
            this.router.navigate(["/dashboard/settings/office-branches"]);
          }
        }
      },
      error: (error) => {
        this.isLoading = false;
        updateSubscription.unsubscribe();
        showMessage(PopupType.Danger, error.error.toString());
      },
    });
  }

  removeImage(e: Event) {
    e.preventDefault();
    this.existingLogo = undefined;

    setTimeout(() => {
      this.initializeFileUploader();
    });
  }

  uploadClick(e: Event) {
    e.stopPropagation();
    const clickedElement = e.target as HTMLElement;
    if (clickedElement.className === "image-preview") {
      this.imageUpload.emulateInputSelection();
    }
  }

  initializeFileUploader() {
    this.imageUpload = new FileUploadWithPreview("office-branch-logo", {
      maxFileCount: 1,
      presetFiles: [],

      images: {
        baseImage: "/assets/images/file-preview.svg",
      },
      accept: "image/*",
      text: {
        browse: this.translate.instant("click_to_upload_logo"),
        chooseFile: this.translate.instant("click_to_choose_logo"),
        label: this.translate.instant("click_to_upload_your_logo"),
      },
      showDeleteButtonOnImages: true,
    });
  }

  fetchOffice(): Office {
    const formValues = this.form.getRawValue();

    return {
      ...this.office,
      ...formValues,
    } as Office;
  }
}
