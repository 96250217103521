import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { ModalComponent } from "angular-custom-modal";
import { UploadedFile } from "src/app/domain/sales/models/file";
import { showMessage } from "../../utils/toast.popup";
import { PopupType } from "../../enums/popup-types";
import { FileService } from "src/app/domain/sales/services/file.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "../../services/modal.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: "app-group-travel-dropdown",
  templateUrl: "./group-travel-dropdown.component.html",
})
export class GroupTravelDropdownComponent implements OnChanges {
  constructor(private fileService: FileService, private translate: TranslateService, private modalService: ModalService, private router: Router) {}

  @ViewChild("fileInput", { static: false }) fileInput!: ElementRef;
  filesByType: { [key: string]: UploadedFile[] } = {};
  cloneConfirmationModal!: ModalComponent;
  treeview2: string[] = [];
  selectedFiles: File[] = [];
  files: UploadedFile[] = [];
  @Input() id!: number;
  @Input() entityId!: number;

  isPopupVisible = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes["id"]) {
      this.loadFiles();
    }
  }
  loadFiles() {
    if (this.id) {
      const getSubscriber = this.fileService.getDocumentsForPlan(this.id).subscribe({
        next: (files: UploadedFile[]) => {
          getSubscriber.unsubscribe();
          this.groupFilesByType(files);
        },
        error: () => {
          getSubscriber.unsubscribe();
          showMessage(PopupType.Danger, this.translate.instant("popup.error_uploading_file"));
        },
      });
    }
  }

  groupFilesByType(files: UploadedFile[]) {
    this.filesByType = files.reduce((acc, file) => {
      const fileType = this.getFileExtension(file.name);
      if (!acc[fileType]) {
        acc[fileType] = [];
      }
      acc[fileType].push(file);
      return acc;
    }, {} as { [key: string]: UploadedFile[] });
  }

  getFileExtension(fileName: string): string {
    const extension = fileName.split(".").pop();
    return extension ? extension.toLowerCase() : "other";
  }
  toggleTreeview2(name: string) {
    if (this.treeview2.includes(name)) {
      this.treeview2 = this.treeview2.filter((d: string) => d !== name);
    } else {
      this.treeview2.push(name);
    }
  }
  downloadFile(file: UploadedFile) {
    // TODO: no need for replacement
    let url = file.url;
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "https://" + url;
    }
    url = url.replace("https://", "http://");
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = file.name;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => showMessage(PopupType.Danger, this.translate.instant("popup.error_uploading_file")));
  }
  async deleteFile(file: UploadedFile) {
    const title = this.translate.instant("delete.title");
    const message = this.translate.instant("delete.message");

    const confirmed = await this.modalService.confirm({
      title: title,
      message: message,
      confirmButtonText: this.translate.instant("common.confirm"),
      cancelButtonText: this.translate.instant("common.cancel"),
    });
    if (confirmed) {
      const deleteSubscriber = this.fileService.deleteFile(file.id).subscribe({
        next: () => {
          deleteSubscriber.unsubscribe();
          showMessage(PopupType.Success, this.translate.instant("popup.file_deleted_successfully"));
          this.loadFiles();
        },
        error: (error: HttpErrorResponse) => {
          deleteSubscriber.unsubscribe();
          showMessage(PopupType.Danger, this.translate.instant("popup.error_deleting_file") + ": " + error.message);
        },
      });
    }
  }

  previewFile(file: UploadedFile) {
    window.open(file.url, "_blank");
  }
  getFileTypes(): string[] {
    return Object.keys(this.filesByType);
  }

  toggleFileUploadPopup() {
    this.isPopupVisible = !this.isPopupVisible;
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFiles = Array.from(input.files);

      this.uploadFiles();
    }
  }
  uploadFiles() {
    if (this.selectedFiles.length > 0 && this.id) {
      this.selectedFiles.forEach((file) => {
        const attachSubscriber = this.fileService.attachDocumentToPlan(this.id, file).subscribe({
          next: () => {
            attachSubscriber.unsubscribe();
            showMessage(PopupType.Success, this.translate.instant("popup.file_uploaded_successfully"));
            this.loadFiles();
          },
          error: () => {
            attachSubscriber.unsubscribe();
            showMessage(PopupType.Danger, this.translate.instant("popup.error_uploading_file"));
          },
        });
      });
    } else {
      showMessage(PopupType.Danger, this.translate.instant("popup.no_files_selected_or_invalid_id"));
    }
  }
  navigateToVoucher() {
    this.router.navigate([`/dashboard/group-travels/${this.entityId}/voucher-list`]);
  }
  navigateToContract() {
    this.router.navigate([`/dashboard/group-travels/${this.entityId}/contract`]);
  }
}
