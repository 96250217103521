import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GroupTravelsService } from "../services/group-travels.service";
import { GroupTravelDestination, GroupTravelPlan } from "../models/group-travel";
import { ETouristStateService } from "../../sales/services/etourist-state.service";
import { Subscription } from "rxjs";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Contract } from "../../documents/contract/models/contract";
import { ContractService } from "../../documents/contract/services/contract.service";
import { ModalComponent } from "angular-custom-modal";
@Component({
  selector: "app-group-travel-plan-details",
  templateUrl: "./group-travel-plan-details.component.html",
})
export class GroupTravelPlanDetailsComponent implements OnInit {
  groupTravelData!: GroupTravelPlan;
  id!: number;
  entityDetails: string | undefined;
  additionalDestinations: GroupTravelDestination[] = [];
  protected mainDestination!: GroupTravelDestination;
  isShowingSidebar = false;
  public entityname = "GroupTravelPlans";
  public checked = false;
  public subscription!: Subscription;
  contract!: Contract;
  @ViewChild("eturistModal") modal!: ModalComponent;
  public emails: string[] = [];

  constructor(
    private groupTravelPassengersService: GroupTravelsService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public etouristStateService: ETouristStateService,
    public contractService: ContractService
  ) {}

  ngOnInit(): void {
    this.id = +this.route.snapshot.params["id"];
    this.loadGroupTravelDetails();
    this.etouristStateService.checked$.subscribe((status) => {
      this.checked = status;
    });
  }

  loadGroupTravelDetails() {
    const subscription = this.groupTravelPassengersService.getGroupTravelPlans(this.id).subscribe((r) => {
      subscription.unsubscribe();
      this.groupTravelData = r;
      const mainDestination = r.groupTravel?.groupTravelDestinations?.find((d: GroupTravelDestination) => d.isMainDestination) || null;
      if (mainDestination && mainDestination.destination && mainDestination.hotel && mainDestination.start) {
        this.entityDetails =
          this.translate.instant("paymentsEntities.groupTravel") + ": " + mainDestination.destination.description + ", " + mainDestination.hotel.name + ", " + moment(mainDestination.start).format("DD.MM.YYYY");
      }
      this.mainDestination = r.groupTravel?.groupTravelDestinations.find((dest) => dest.isMainDestination) || ({} as GroupTravelDestination);
      this.additionalDestinations = r.groupTravel?.groupTravelDestinations.filter((dest) => !dest.isMainDestination);
      this.emails = this.groupTravelData.groupTravelPassangers.map((passanger) => passanger.customer.email).filter((email): email is string => email !== null);
    });
  }
  refetch() {
    this.loadGroupTravelDetails();
  }
  addRecordsToETurist() {
    if (!this.groupTravelData.contractId) {
      this.subscription = this.contractService.getContractByGroupTravel(this.id).subscribe((response) => {
        this.contract = response;
        this.groupTravelData.contractId = response.id;
      });
    }

    if (!this.groupTravelData.groupTravel.start || moment(this.groupTravelData.groupTravel.start).isBefore(moment(), "day")) {
      showMessage(PopupType.Danger, "This can't be added since the travel is in past.");
      return;
    }

    this.modal.open();
  }
  addedRecordToEturist() {
    this.refetch();
    this.modal.close();
    // this.modalPdf.close();
    // this.modalUpdate.close();
  }
}
