import * as moment from "moment";
import { Store } from "@ngrx/store";
import { OnDestroy } from "@angular/core";
import { DatePipe } from "@angular/common";
import { User } from "src/app/auth/model/user";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { DailyDebtStatistics } from "./models/dailydebt";
import { StatisticsYear } from "./models/statisticsyear";
import { StatisticsYearlyDebt } from "./models/yearlydebt";
import { MonthlyDebtStatistics } from "./models/monthlydebt";
import { StatisticsDaily } from "./models/statisticmoneydaily";
import { DailyEarningsStatistics } from "./models/dailyearnings";
import { updateSettings } from "src/app/store/auth/auth.actions";
import { StatisticsYearlyEarnings } from "./models/yearlyearning";
import { StatisticsMonthly } from "./models/statisticmoneymonthly";
import { UserService } from "../user-profile/service/user.service";
import { MonthlyEarningsStatistics } from "./models/monthlyearnings";
import { selectFeatureUser } from "src/app/store/auth/auth.selector";
import { setFromTo } from "src/app/store/datepicker/datepicker.actions";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { StatisticsMoneyService } from "./services/statisticsmoney.service";
import { YearlyPopularDestinations } from "./models/yearlypopulardestinations";
import { MonthTranslations, OverallStatistics } from "./models/overallstatistics";
import { MostPopularCountriesByMonth } from "./models/mostpopularcountriesbymonth";
import { StatisticOrganizer, StatisticOrganizerProfit } from "./models/statisticorganizer";
import { distinctUntilChanged, Observable, Subject, Subscription, switchMap, take, takeUntil } from "rxjs";

@Component({
  selector: "app-statisticsmoney",
  templateUrl: "./statisticsmoney.component.html",
  providers: [DatePipe],
})
export class StatisticsMoneyComponent implements OnInit, OnDestroy {
  constructor(private statisticsService: StatisticsMoneyService, public translate: TranslateService, public store: Store<AppState>, private userService: UserService) {}

  protected statisticsData!: StatisticsYear;
  public datepick$!: Observable<{ from: Date; to: Date | undefined }>;
  public from!: Date;
  public to!: Date | undefined;
  numberOfOrganizers?: number = 10;
  numberOfDestinations?: number = 10;
  availableYears: number[] = [];
  availableMonths: { label: string; value: number }[] = [];
  selectedYear?: number = new Date().getFullYear();
  selectedMonth: number = new Date().getMonth() + 1;
  yearlypopulardestination!: YearlyPopularDestinations | undefined;
  public year?: number;
  statisticsOrganizer: StatisticOrganizer[] = [];
  statisticsOrganizerProfit: StatisticOrganizerProfit[] = [];
  activeTab?: string = "Overall";
  activeTabs?: string = "yearly";
  overallStatistics: OverallStatistics | undefined;
  protected yearlyearning!: StatisticsYearlyEarnings;
  protected montlyearning!: MonthlyEarningsStatistics;
  protected monthlydebt!: MonthlyDebtStatistics;
  protected yearlydebt!: StatisticsYearlyDebt;
  protected dailyearnings!: DailyEarningsStatistics;
  protected dailyeardebt!: DailyDebtStatistics;
  protected monthlystatistic!: StatisticsMonthly;
  protected dailystatistic!: StatisticsDaily;
  mostPopularCountriesByMonth: MostPopularCountriesByMonth | undefined;
  tabs: Array<{ id: string; label: string }> = [];
  tabsCountryDestination: Array<{ id: string; label: string }> = [];
  private subscriptions = new Subscription();
  public isLoading = true;
  public user$!: Observable<User | undefined>;
  public user!: User;
  private destroy$ = new Subject<void>();
  public settings: any = {};

  async ngOnInit() {
    await Promise.all([this.initializeTabs(), this.initializeSelectors(), this.initializeAllData()]);

    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }

  initializeTabs(): void {
    this.tabs = [
      { id: "Overall", label: "tabs_statistics.overall" },
      { id: "Monthlystatistics", label: "tabs_statistics.monthlystatistics" },
      { id: "Dailystatistics", label: "tabs_statistics.dailystatistics" },
    ];
    this.tabsCountryDestination = [
      { id: "yearly", label: "tabsCountryDestination.yearly" },
      { id: "monthly", label: "tabsCountryDestination.monthly" },
    ];
  }

  protected initializeSelectors() {
    this.user$ = this.store.select(selectFeatureUser);
    this.datepick$ = this.store.select(selectFromTo);

    this.user$
      .pipe(
        takeUntil(this.destroy$),
        take(1),
        switchMap((user) => {
          if (user) {
            this.user = user;
            this.settings = user.settings ? JSON.parse(user.settings) : {};
            this.restoreState(this.settings);
          }
          return this.datepick$;
        }),
        distinctUntilChanged((prev, curr) => moment(prev.from).isSame(moment(curr.from), "day") && (prev.to ? moment(prev.to).isSame(moment(curr.to), "day") : prev.to === curr.to)),
        takeUntil(this.destroy$)
      )
      .subscribe((datepick) => {
        if (datepick) {
          this.isLoading = true;
          this.from = datepick.from;
          this.to = datepick.to;
          if (this.from) {
            this.getOverallStatisticsData();
            this.saveStatisticsSettings();
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        }
      });
  }

  private restoreState(settings: any) {
    if (settings?.statisticsSettings) {
      const { from, to } = settings.statisticsSettings;
      this.from = from ? new Date(from) : this.from;
      this.to = to ? new Date(to) : undefined;
      this.store.dispatch(setFromTo({ from: this.from, to: this.to }));
    }
  }

  private saveStatisticsSettings() {
    const statisticsSettings = {
      from: this.from?.toISOString() || null,
      to: this.to?.toISOString() || null,
    };
    const updatedSettings = { ...this.settings, statisticsSettings };

    if (this.user) {
      const userUpdates = { ...this.user, settings: JSON.stringify(updatedSettings) };
      this.store.dispatch(updateSettings({ settings: JSON.stringify(updatedSettings) }));
      this.userService.updateUser(this.user.id, userUpdates);
    }
  }

  initializeAllData() {
    this.getOverallStatisticsData(),
      this.initializeMonths(),
      this.getStatistics(),
      this.getYearlyPopularDestinations(),
      this.getStatisticsOrganizer(),
      this.getStatisticsOrganizerProfit(),
      this.getyearlyearning(),
      this.getyearlydebt(),
      this.getMoneyMonthly(),
      this.getMonthlYearning(),
      this.getMonthlyDebt(),
      this.getDailyEarning(),
      this.getDailyDept(),
      this.getDailyMoney(),
      this.getMostPopularCountriesByMonth();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getOverallStatisticsData() {
    const today = new Date();
    if (this.from) {
      const fromDate = new Date(this.from);
      fromDate.setMinutes(fromDate.getMinutes() - fromDate.getTimezoneOffset());
      const toDate = this.to ? new Date(this.to) : today;
      toDate.setMinutes(toDate.getMinutes() - toDate.getTimezoneOffset());
      const subscription = this.statisticsService.getOverallStatistics(fromDate.toISOString(), toDate.toISOString()).subscribe({
        next: (response) => {
          this.overallStatistics = response;
          subscription.unsubscribe();
        },
        error: () => {
          subscription.unsubscribe();
        },
      });
    }
  }

  getStatistics() {
    const subscription = this.statisticsService.getStatistics().subscribe({
      next: (response) => {
        this.statisticsData = response;
        subscription.unsubscribe();
        this.initializeYears();
      },
      error: () => {
        subscription.unsubscribe();
      },
    });
  }

  getyearlyearning() {
    const subscription = this.statisticsService.getYearlyEarningStatistics().subscribe({
      next: (response) => {
        this.yearlyearning = response;
        subscription.unsubscribe();
      },
      error: () => {
        subscription.unsubscribe();
      },
    });
  }

  getMonthlYearning(): void {
    const subscription = this.statisticsService.getEarningsMonthStatistics(this.selectedYear).subscribe({
      next: (response) => {
        this.montlyearning = response;
        subscription.unsubscribe();
      },
      error: () => {
        subscription.unsubscribe();
      },
    });
  }

  getMoneyMonthly() {
    const subscription = this.statisticsService.getMoneyMonthStatistics(this.selectedYear).subscribe({
      next: (response) => {
        this.monthlystatistic = response;
        subscription.unsubscribe();
      },
      error: () => {
        subscription.unsubscribe();
      },
    });
  }

  getDailyEarning() {
    const subscription = this.statisticsService.getEarningsDailyStatistics(this.selectedYear, this.selectedMonth).subscribe({
      next: (response) => {
        this.dailyearnings = response;
        subscription.unsubscribe();
      },
      error: () => {
        subscription.unsubscribe();
      },
    });
  }

  getDailyMoney() {
    const subscription = this.statisticsService.getMoneyDailyStatistics(this.selectedYear, this.selectedMonth).subscribe({
      next: (response) => {
        this.dailystatistic = response;
        subscription.unsubscribe();
      },
      error: () => {
        subscription.unsubscribe();
      },
    });
  }

  getMonthlyDebt() {
    const subscription = this.statisticsService.getDebtMonthStatistics(this.selectedYear).subscribe({
      next: (response) => {
        this.monthlydebt = response;
        subscription.unsubscribe();
      },
      error: () => {
        subscription.unsubscribe();
      },
    });
  }

  getDailyDept() {
    const subscription = this.statisticsService.getDebtDailyStatistics(this.selectedYear, this.selectedMonth).subscribe({
      next: (response) => {
        this.dailyeardebt = response;
        subscription.unsubscribe();
      },
      error: () => {
        subscription.unsubscribe();
      },
    });
  }

  getyearlydebt() {
    const subscription = this.statisticsService.getYearlyDebtStatistics().subscribe({
      next: (response) => {
        this.yearlydebt = response;
        subscription.unsubscribe();
      },
      error: () => {
        subscription.unsubscribe();
      },
    });
  }

  getStatisticsOrganizer(): void {
    const subscription = this.statisticsService.getOrganizerStatistics(this.selectedYear, this.numberOfOrganizers).subscribe({
      next: (response) => {
        this.statisticsOrganizer = response;
        subscription.unsubscribe();
      },
      error: () => {
        subscription.unsubscribe();
      },
    });
  }

  getStatisticsOrganizerProfit(): void {
    const subscription = this.statisticsService.getStatisticsOrganizerProfit(this.selectedYear, this.numberOfOrganizers).subscribe({
      next: (response) => {
        this.statisticsOrganizerProfit = response;
        subscription.unsubscribe();
      },
      error: () => {
        subscription.unsubscribe();
      },
    });
  }

  onTabChange(tab: string) {
    this.activeTab = tab;
  }
  onTabChanges(tab: string) {
    this.activeTabs = tab;
  }

  getYearlyPopularDestinations() {
    const subscription = this.statisticsService.getYearlyPopularDestinations(this.selectedYear, this.numberOfDestinations).subscribe({
      next: (response) => {
        this.yearlypopulardestination = response;
        subscription.unsubscribe();
      },
      error: () => {
        subscription.unsubscribe();
      },
    });
  }

  getMostPopularCountriesByMonth() {
    const subscription = this.statisticsService.getMostPopularCountriesByMonth(this.selectedYear, this.numberOfDestinations).subscribe({
      next: (response) => {
        this.mostPopularCountriesByMonth = response;
        subscription.unsubscribe();
      },
      error: () => {
        subscription.unsubscribe();
      },
    });
  }

  onNumberOfOrganizersChange(number: number): void {
    this.numberOfOrganizers = number;
    this.getStatisticsOrganizer();
    this.getStatisticsOrganizerProfit();
  }

  selectYear(year: number) {
    this.selectedYear = year ? year : this.availableYears[this.availableYears.length - 1];
    this.year = this.selectedYear;
    this.from = new Date(`${this.selectedYear}-01-01`);
    this.to = new Date(`${this.selectedYear}-12-31`);
    this.getYearlyPopularDestinations();
    this.getMoneyMonthly();
    this.getMonthlyDebt();
    this.getMonthlYearning();
    this.getDailyDept();
    this.getDailyMoney();
    this.getDailyEarning();
    this.getMostPopularCountriesByMonth();
    this.getStatisticsOrganizer();
    this.getStatisticsOrganizerProfit();
  }

  selectMonth(month: number) {
    this.selectedMonth = month ? month : this.availableMonths[this.availableMonths.length - 1].value;
    this.getDailyDept(), this.getDailyMoney(), this.getDailyEarning();
  }

  selectNumberOfDestinations(number: number) {
    this.numberOfDestinations = number;
    this.getStatistics();
    this.getYearlyPopularDestinations();
    this.getMostPopularCountriesByMonth();
  }

  onYearChange(year: number) {
    this.selectedYear = year;
    this.getStatistics();
    this.getYearlyPopularDestinations();
    this.getMostPopularCountriesByMonth();
    this.getMoneyMonthly();
    this.getMonthlyDebt();
    this.getMonthlYearning();
    this.getDailyDept();
    this.getDailyMoney();
    this.getDailyEarning();
    this.getStatisticsOrganizer();
    this.getStatisticsOrganizerProfit();
  }

  onMonthChange(month: number) {
    this.selectedMonth = month;
    this.getDailyDept(), this.getDailyMoney(), this.getDailyEarning();
  }

  onNumberOfDestinationsChange(number: number) {
    this.numberOfDestinations = number;
    this.getStatistics();
    this.getYearlyPopularDestinations();
  }

  initializeYears() {
    if (this.statisticsData && this.statisticsData.yearlyServiceStats) {
      const years = this.statisticsData.yearlyServiceStats.map((item) => item.year);
      this.availableYears = Array.from(new Set(years));
      this.selectedYear = this.availableYears[this.availableYears.length - 1];
    }
  }

  initializeMonths() {
    this.translate.get("months").subscribe((translations: MonthTranslations) => {
      this.availableMonths = Object.keys(translations).map((key) => ({
        label: translations[key],
        value: +key,
      }));
      const currentMonth = new Date().getMonth() + 1;
      this.selectedMonth = this.availableMonths.find((month) => month.value === currentMonth)?.value || 1;
    });
  }
}
