import { Store } from "@ngrx/store";
import { Customer } from "../models/customer";
import { TranslateService } from "@ngx-translate/core";
import { CustomerPlan } from "../models/customer-plan";
import { AppState } from "src/app/store/index.selector";
import { CustomerStats } from "../models/customer-stat";
import { ActivatedRoute, Router } from "@angular/router";
import { MessagePortalComponent } from "src/app/portal/portal";
import { CustomersService } from "../services/customers.service";
import { TableService } from "src/app/shared/services/table.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { RouterLinkHelper } from "src/app/shared/utils/router-link-helper";
import { toggleAnimation } from "src/app/shared/animations";
import { UserService } from "../../user-profile/service/user.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { from, tap } from "rxjs";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";

@Component({
  selector: "app-customer-stats",
  templateUrl: "./customer.stats.component.html",
  animations: [toggleAnimation],
})
export class CustomerStatsComponent extends BaseTableComponent<Customer> implements OnInit {
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  isShowingSidebar = false;
  protected id!: number;
  protected customer!: CustomerStats;
  fromDate!: string;
  toDate!: string;
  public entityname = "customers";
  public customerpaginated: CustomerPlan[] = [];
  public exportApiUrl!: string;
  public additionalInfo!: string;
	public currentId!: number;

  constructor(
    private customerStatsService: CustomersService,
    private route: ActivatedRoute,
    private router: Router,
    public override translate: TranslateService,
    private positioningService: PositioningService,
    public override store: Store<AppState>,
    public override tableService: TableService,
    private modalService: ModalService,
    public override userService: UserService
  ) {
    super(store, tableService, "", userService, translate);
  }

  override ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = parseInt(params["id"]);
      if (this.id > 0) {
        this.fetchCustomerStats();
        this.apiUrl = `Statistics/customers/${this.id}/paginated`;
        this.exportApiUrl = `api/Customers/customer-plans/export/${this.id}`;
        super.ngOnInit();
      }
    });
  }

  fetchCustomerStats() {
    const customerStatsSubscription = this.customerStatsService.getCustomerStats(this.id).subscribe((data: CustomerStats) => {
      customerStatsSubscription.unsubscribe();
      this.customer = data;
      this.additionalInfo = [data.name, data.surname, data.email].filter((value) => value).join(" ");
      customerStatsSubscription.unsubscribe();
    });
  }

  getIconAndTooltip(entityName: string, entityType?: string) {
			return RouterLinkHelper.getIconAndTooltip(entityName, entityType);
		}
		getRouterLink(value: any): string | undefined {
			return RouterLinkHelper.getRouterLink(value);
		}

  override getColumns(): void {
    this.cols = [
			{ field: "entityId", title: this.translate.instant("workerinfo.entity"), hide: false } as DatatableColumn,
      { field: "customerFullName", title: this.translate.instant("workerinfo.customerFullName"), hide: false } as DatatableColumn,
      { field: "company.name", title: this.translate.instant("workerinfo.company"), hide: false } as DatatableColumn,
      { field: "destination.description", title: this.translate.instant("workerinfo.destination"), hide: false } as DatatableColumn,
      { field: "departure.description", title: this.translate.instant("workerinfo.departure"), hide: false } as DatatableColumn,
			{ field: "avans", title: this.translate.instant("workerinfo.avans"), hide: false } as DatatableColumn,
			{ field: "entityName", title: this.translate.instant("workerinfo.entity_name"), hide: false } as DatatableColumn,
      { field: "neto", title: this.translate.instant("workerinfo.neto"), hide: false } as DatatableColumn,
      { field: "bruto", title: this.translate.instant("workerinfo.bruto"), hide: false } as DatatableColumn,
      { field: "status", title: this.translate.instant("workerinfo.status"), hide: false } as DatatableColumn,
			{ field: "depth", title: this.translate.instant("workerinfo.depth"), hide: false } as DatatableColumn,
    ];
  }

  exportCols = [
    { field: "customerFullName", title: this.translate.instant("customer_plans.customerNameAndSurname"), hide: false, sort: false } as DatatableColumn,
    { field: "companyName", title: this.translate.instant("customer_plans.companyName"), hide: false } as DatatableColumn,
    { field: "destination", title: this.translate.instant("customer_plans.destination"), hide: false } as DatatableColumn,
    { field: "departure", title: this.translate.instant("customer_plans.departure"), hide: false } as DatatableColumn,
    { field: "payedInPayments", title: this.translate.instant("customer_plans.payedInPayments"), hide: false } as DatatableColumn,
    { field: "entityName", title: this.translate.instant("customer_plans.entityName"), hide: false, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("customer_plans.neto"), hide: false, sort: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("customer_plans.bruto"), hide: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("customer_plans.status"), hide: false } as DatatableColumn,
  ];
  openEditCustomerModal(customer: Customer) {
    from(this.modalService.openCustomerModal(customer.id))
      .pipe(
        tap((result) => {
          if (result) {
            this.fetchItems(this.getPaginationParams());
            showMessage(PopupType.Success, this.translate.instant("customer_data.updated_customer_successfully"));
          }
        })
      )
      .subscribe();
  }

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }
	onMouseEnter(id: number): void {
    this.currentId = id;
  }
}
