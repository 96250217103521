<button type="button" class="btn btn-primary gap-2" (click)="toggleFileUploadPopup()">
  <icon-down></icon-down>
  <icon-folder></icon-folder>
</button>

<div class="mr-50 flex flex-col items-center">
  <div *ngIf="isPopupVisible" class="absolute z-50 mt-3 w-auto transform flex-col rounded-lg border border-gray-200 bg-white pt-5 opacity-95 shadow-lg transition-opacity">
    <ul>
      <ng-container *ngIf="getFileTypes().length > 0; else noFilesContent">
        <ng-container *ngFor="let type of getFileTypes()">
          <li class="rounded-md py-2 text-blue-600 hover:bg-gray-100 ltr:pl-4 rtl:pr-4">
            <button type="button" class="flex w-full items-center gap-2 rounded-md p-2 text-blue-600 transition-colors hover:bg-gray-50" (click)="toggleTreeview2(type)">
              <icon-down class="text-blue-500"></icon-down>
              <icon-folder class="text-blue-500"></icon-folder>
              <span>{{ type }}</span>
            </button>
            <ng-container *ngIf="treeview2.includes(type)">
              <ul class="ml-6 mt-2 space-y-2">
                <li *ngFor="let file of filesByType[type]" class="flex items-center justify-between rounded-lg bg-gray-100 px-4 py-2 shadow-sm transition-all hover:bg-gray-200">
                  <span class="text-blue-600">{{ file.name }}</span>
                  <div class="flex gap-3">
                    <button (click)="downloadFile(file)" class="text-green-500 transition-colors hover:text-green-700" aria-label="Download file">
                      <icon-download class="text-xl"></icon-download>
                    </button>
                    <button (click)="previewFile(file)" class="text-yellow-500 transition-colors hover:text-yellow-700" aria-label="Preview file">
                      <icon-eye class="text-xl"></icon-eye>
                    </button>
                    <button (click)="deleteFile(file)" class="text-red-500 transition-colors hover:text-red-700" aria-label="Delete file">
                      <icon-trash class="text-xl"></icon-trash>
                    </button>
                  </div>
                </li>
              </ul>
            </ng-container>
          </li>
        </ng-container>
      </ng-container>

      <ng-template #noFilesContent>
        <p class="p-2 text-center font-bold grayscale">{{ "no_files_uploaded" | translate }}</p>
      </ng-template>

      <div class="space-y-3 px-2 py-2 text-blue-600">
        <button type="button" class="btn btn-primary flex w-full transform items-center gap-2 rounded-lg p-2 text-white shadow-md transition-transform hover:scale-105" (click)="triggerFileInput()">
          <icon-upload></icon-upload>
          <span>{{ "add_file" | translate }}</span>
        </button>
        <button
          *ngIf="entityId"
          type="button"
          (click)="navigateToVoucher()"
          class="btn btn-primary flex w-full transform items-center gap-2 rounded-lg p-2 text-white shadow-md transition-transform hover:scale-105"
        >
          <icon-voucher></icon-voucher>
          {{ "travel_details.voucher" | translate }}
        </button>

        <button
          *ngIf="entityId"
          type="button"
          (click)="navigateToContract()"
          class="btn btn-primary flex w-full transform items-center gap-2 rounded-lg p-2 text-white shadow-md transition-transform hover:scale-105"
        >
          <icon-contract></icon-contract>
          {{ "travel_details.contract" | translate }}
        </button>
        <button
          *ngIf="entityId"
          type="button"
          (click)="navigateToInvoice()"
          class="btn btn-primary flex w-full transform items-center gap-2 rounded-lg p-2 text-white shadow-md transition-transform hover:scale-105"
        >
          <icon-menu-invoice></icon-menu-invoice>
          {{ "travel_details.facture" | translate }}
        </button>
        <input type="file" #fileInput class="hidden" (change)="onFileSelected($event)" multiple />
      </div>
    </ul>
  </div>
</div>
