<div class="mb-3 flex flex-wrap items-center justify-center gap-4 lg:justify-end" *ngIf="id">
  <button class="btn btn-primary gap-2 p-0 px-1" ngxTippy="{{ 'account_data.record_sale' | translate }}" (click)="openAddEntryModal()">
    <icon-accounting-data></icon-accounting-data>
    {{ "account_data.accounting_data" | translate }}
    <img src="assets/images/eturista-logo.png" class="w-18 h-9" />
  </button>
  <app-details-dropdown [id]="id"></app-details-dropdown>
  <app-group-travel-add-passanger [groupTravelId]="id"></app-group-travel-add-passanger>
  <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"> </app-file-history>
  <button *ngIf="group_travel && group_travel.id" type="button" class="btn btn-primary gap-2">
    <a class="flex items-center justify-center" href="javascript:;" [routerLink]="'/dashboard/group-travels/edit/' + group_travel.id" [queryParams]="{ returnId: id }">
      <icon-pencil-paper class="h-4.5 w-4.5 shrink-0" />
    </a>
  </button>
</div>
<div *ngIf="group_travel">
  <div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
      <div class="col-span-2 flex grid grid-cols-2 rounded-lg p-4 sm:col-span-4 sm:grid-cols-4 print:hidden">
        <div class="col-span-2 grid grid-cols-2 sm:col-span-4">
          <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
            <div class="text-xl font-bold">
              {{ "group_travels_details_label" | translate }}
            </div>
          </div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2 print:hidden">
          <div class="text-xl italic text-white-dark">
            {{ "group_travel_details.name" | translate }}
          </div>
          <div class="text-xl">{{ group_travel.name }}</div>
        </div>

        <div class="col-span-1 flex flex-col sm:col-span-2 print:hidden">
          <div class="mt-2 flex flex-col">
            <div class="ltr:mr-2 rtl:ml-2">{{ "group_travel_details.status" | translate }}:</div>
            <div *ngIf="group_travel.active; else notActive" class="badge mt-1 inline-flex w-auto self-start bg-success">
              {{ "group_travel_details.active" | translate }}
            </div>
            <ng-template #notActive>
              <div class="badge mt-1 inline-flex w-auto self-start bg-slate-200 text-white">
                {{ "group_travel_details.deleted" | translate }}
              </div>
            </ng-template>
          </div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details.departure_from" | translate }} :</div>
          <div>{{ group_travel.departure }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.hotel" | translate }}:</div>
          <div>{{ mainDestination.hotel?.name }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details.transportation" | translate }} :</div>
          <div *ngIf="group_travel.type; else showTransport">
            {{ group_travel.type }}
          </div>
          <ng-template #showTransport>
            <div>
              {{ group_travel.eTuristTransportLabel }}
            </div>
          </ng-template>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1 sm:row-span-3">
          <div>{{ "travel_details.additional_destinations" | translate }} :</div>
          <div *ngFor="let dest of additionalDestinations">
            <app-travel-accordion [content]="dest"></app-travel-accordion>
          </div>
        </div>

        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details.departure_date" | translate }}:</div>
          <div>{{ group_travel.start | date : "dd.MM.yyyy." }}</div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details.return_date" | translate }}:</div>
          <div>{{ group_travel.end | date : "dd.MM.yyyy." }}</div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details.days_nights" | translate }}:</div>
          <div>{{ group_travel.days }}/{{ group_travel.nights }}</div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.room_description" | translate }}:</div>
          <div>{{ mainDestination.roomDescription }}</div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.service_type" | translate }}:</div>
          <div *ngIf="mainDestination">
            <div *ngIf="mainDestination.service?.title; else showService">
              {{ mainDestination.service?.title }}
            </div>
            <ng-template #showService>
              <div>
                {{ mainDestination.eTuristAccommodationLabel }}
              </div>
            </ng-template>
          </div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.notes" | translate }}:</div>
          <div>{{ mainDestination.notes }}</div>
        </div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "paymentssendemail.payments" | translate }}
          </div>
        </div>
        <div class="col-span-1 sm:col-span-2">
          <div class="flex-col">
            <div>{{ "group_travel_details.bruto" | translate }}:</div>
            <div class="text-dark">
              {{ group_travel.bruto | currency : "EUR" }}
            </div>
          </div>
        </div>

        <div class="col-span-1 sm:col-span-2">
          <div class="flex-col">
            <div>{{ "group_travel_details.neto" | translate }}:</div>
            <div class="text-dark">
              <p *ngIf="isVisible">{{ group_travel.neto | currency : "EUR" }}</p>
              <button *ngIf="!isVisible" [ngxTippy]="group_travel.neto | currency : 'EUR'" type="button" class="flex items-start">
                <icon-eye-open class="dark:text-white" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="small text-secondary">{{ group_travel.createdBy }}, {{ group_travel.createdAt | date : "dd.MM.yyyy." }}</div>
    </div>
  </div>

  <app-group-travel-passangers-table [groupTravelId]="id"></app-group-travel-passangers-table>
</div>

<modal #addEntryInEturist class="modal-top extra-large-modal" [closeOnOutsideClick]="false">
  <ng-template #modalHeader>
    <div class="!py-5">{{ "account_data.record_sale" | translate }}</div>
  </ng-template>
  <ng-template #modalBody *ngIf="id">
    <app-group-travel-eturist [groupTravelId]="id" (recordUpdated)="refetch()"></app-group-travel-eturist>
  </ng-template>
</modal>
