import { Invoice } from "./models/invoice";
import { TranslateService } from "@ngx-translate/core";
import { ModalComponent } from "angular-custom-modal";
import { ActivatedRoute, Router } from "@angular/router";
import { InvoiceService } from "./services/invoice.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ContractService } from "../contract/services/contract.service";
import { PrintPreviewService } from "src/app/shared/services/print-preview.service";

@Component({
  selector: "app-invoice-document",
  templateUrl: "./invoice-document.component.html",
})
export class InvoiceDocumentComponent implements OnInit {
  factureId!: number;
  invoice!: Invoice;
  editUrl!: string;
  isShowingSidebar = false;
  public entityname = "invoice";
  public id!: number;
  isLoading = false;
  @ViewChild("modal") modal!: ModalComponent;
  today: string = new Date().toLocaleDateString("sr-RS");
  qrCodeImage: string | ArrayBuffer | null = null;

  constructor(
    public route: ActivatedRoute,
    public contractService: ContractService,
    public router: Router,
    public translate: TranslateService,
    public invoiceService: InvoiceService,
    public printPreviewService: PrintPreviewService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.factureId = parseInt(params["id"]);
      this.fetchContract();
    });
  }
  fetchContract() {
    this.invoiceService.getTravelInvoice(this.factureId).subscribe((response) => {
      this.invoice = response;
      this.editUrl = `/dashboard/travels/${this.factureId}/invoice/${response.id}`;
    });
  }
  editContract() {
    this.router.navigate([this.editUrl]);
  }

  sendContract() {
    this.modal.open();
  }
  closeModal() {
    this.modal.close();
  }
  finishSending() {
    this.modal.close();
  }

  printContract() {
    this.isLoading = true;
    const subscription = this.invoiceService.generateInvoiceHtml(this.invoice.id).subscribe({
      next: (htmlContent) => {
        this.isLoading = false;
        this.printPreviewService.printFunctionality(htmlContent);
        subscription.unsubscribe();
      },
      complete: () => {
        subscription.unsubscribe();
        this.isLoading = false;
      },
    });
  }
}
