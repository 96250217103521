import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OfficeBranchService } from "../services/office-branch.service";
import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Office, OfficeBranchEmailConfiguration } from "../models/office-branch";

@Component({
  selector: "app-email-configuration-modal",
  templateUrl: "./email-configuration-modal.component.html",
})
export class EmailConfigurationModalComponent implements OnInit {
  @Input() office!: Office;
  @Output() close: EventEmitter<null> = new EventEmitter();
  public form!: FormGroup;
  loading: boolean = false;
  passwordVisible = false;

  constructor(public fb: FormBuilder, public officeBranchService: OfficeBranchService, public translate: TranslateService) {}

  ngOnInit(): void {
    const { senderEmail, senderName, adminEmail, password } = this.office || ({} as Office);
    this.form = this.fb.group({
      senderEmail: [senderEmail, [Validators.email, Validators.required]],
      senderName: [senderName, Validators.required],
      adminEmail: [adminEmail, [Validators.email, Validators.required]],
      password: [password, Validators.required],
    });
  }

  submitForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.loading = true;
    const emailConfig = this.getData();
    const subscription = this.officeBranchService.updateOfficeEmailConfiguration(this.office.id, emailConfig).subscribe({
      next: () => {
        subscription.unsubscribe();
        this.loading = false;
        showMessage(PopupType.Success, this.translate.instant("popup.office_branch_updated_successfully"));
        this.close.emit(null);
      },
      error: (error) => {
        subscription.unsubscribe();
        this.loading = false;
        showMessage(PopupType.Danger, error.error.toString());
      },
    });
  }

  getData(): OfficeBranchEmailConfiguration {
    const { senderEmail, senderName, adminEmail, password } = this.form.getRawValue();
    return { senderEmail, senderName, adminEmail, password };
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  closeModal(): void {
    this.close.emit(null);
  }
}
