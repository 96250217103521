import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef } from "@angular/core";

@Component({
  selector: "app-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
})
export class ConfirmationModalComponent implements AfterViewInit {
  @Input() confirmationNumber!: string;
  @Output() confirmationNumberChange = new EventEmitter<string>();
  @Output() confirmationSubmit: EventEmitter<string> = new EventEmitter();

  @ViewChild("confirmationInput") confirmationInput!: ElementRef;

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.confirmationInput) {
        this.confirmationInput.nativeElement.focus();
      }
    }, 0);
  }

  onInputChange(value: string) {
    this.confirmationNumber = value;
    this.confirmationNumberChange.emit(value);
  }

  submitConfirmationNumber() {
    if (this.confirmationNumber) {
      this.confirmationSubmit.emit(this.confirmationNumber);
    }
  }
}
