import { Subject, takeUntil } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TravelsService } from "../../travels/services/travels.service";
import { TravelQuickView } from "../../travels/models/travel-quick-view";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";

@Component({
  selector: "app-travel-quick-view-by-eturist",
  templateUrl: "./travel-quick-view-by-eturist.component.html",
})
export class TravelQuickViewByEturistComponent implements OnInit, OnDestroy {
  public data!: TravelQuickView;
  @Input() eTuristRecordId!: string;
  private destroy$ = new Subject<void>();

  constructor(public translate: TranslateService, private travelService: TravelsService) {}
  ngOnInit(): void {
    if (this.eTuristRecordId) {
      this.fetchTravelQuickView(this.eTuristRecordId);
    }
  }
  fetchTravelQuickView(eTuristRecordId: string) {
    this.travelService
      .getTravelQuickViewByETuristRecordId(eTuristRecordId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (travel) => {
          this.data = travel;
        },
        error: (error) => {
          showMessage(PopupType.Danger, error.error);
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
