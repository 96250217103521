<form [formGroup]="form" (ngSubmit)="save()">
  <div class="relative mb-4" [ngClass]="form.controls['recipeNumber'].touched ? (form.controls['recipeNumber'].errors ? 'has-error' : 'has-success') : ''">
    <label for="recipeNumber">{{ "account_data.recipe_number" | translate }}:</label>
    <input type="text" placeholder="{{ 'account_data.recipe_number' | translate }}" class="form-input rtl:pr-10" id="recipeNumber" formControlName="recipeNumber" />
    <ng-container *ngIf="form.controls['recipeNumber'].touched && form.controls['recipeNumber'].errors">
      <small class="mt-1 text-danger">{{ "account_data.please_recipe_number" | translate }}</small>
    </ng-container>
  </div>
  <div class="relative mb-4" [ngClass]="form.controls['finalAmount'].touched ? (form.controls['finalAmount'].errors ? 'has-error' : 'has-success') : ''">
    <label for="finalAmount">{{ "account_data.recipe_amount" | translate }}:</label>
    <div class="flex">
      <div class="flex items-center justify-center border border-[#e0e6ed] bg-[#eee] px-3 font-semibold dark:border-[#17263c] dark:bg-[#1b2e4b] ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md rtl:border-l-0">
        RSD
      </div>
      <input
        type="text"
        placeholder="{{ 'account_data.recipe_amount' | translate }}"
        mask="separator.3"
        [outputTransformFn]="toFixed"
        class="form-input ltr:rounded-l-none rtl:pr-10"
        id="finalAmount"
        formControlName="finalAmount"
      />
    </div>
    <ng-container *ngIf="form.controls['finalAmount'].touched && form.controls['finalAmount'].errors">
      <small class="mt-1 text-danger">{{ "account_data.please_recipe_amount" | translate }}</small>
    </ng-container>
  </div>
  <div class="relative mb-4" [ngClass]="form.controls['dateOfRecipeIssue'].touched ? (form.controls['dateOfRecipeIssue'].errors ? 'has-error' : 'has-success') : ''">
    <label for="dateOfRecipeIssue">{{ "account_data.date_recipe_issueance" | translate }}:</label>
    <input type="text" class="form-input rtl:pr-10" id="dateOfRecipeIssue" formControlName="dateOfRecipeIssue" placeholder="__.__.____" mask="d0.M0.0000" [showMaskTyped]="true" />
    <ng-container *ngIf="form.controls['dateOfRecipeIssue'].touched && form.controls['dateOfRecipeIssue'].errors">
      <small class="mt-1 text-danger">{{ "account_data.please_date_recipe_issueance" | translate }}</small>
    </ng-container>
  </div>
  <button type="submit" class="btn btn-primary w-full" [disabled]="loading">
    <ng-container *ngIf="!loading; else loadingTemplate">{{ "account_data.save" | translate }} </ng-container>
    <ng-template #loadingTemplate>
      <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle ltr:mr-4 rtl:ml-4"></span>{{ "loading" | translate }}
    </ng-template>
  </button>
</form>
