import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";

@Component({
  selector: "app-confirmation-dialog",
  styleUrls: ["./confirmation-dialog.component.scss"],
  templateUrl: "./confirmation-dialog.component.html",
})
export class ConfirmationDialogComponent {
  @Input() title = "Confirmation";
  @Input() message = "Are you sure you want to proceed?";
  @Input() confirmButtonText = "Confirm";
  @Input() cancelButtonText = "Cancel";
  @ViewChild('cancelBtn') cancelBtn!: ElementRef;
  @ViewChild('confirmBtn') confirmBtn!: ElementRef;

  @Output() confirmed = new EventEmitter<boolean>();

 
	ngAfterViewInit() {
    setTimeout(() => {
      if (this.cancelBtn?.nativeElement) {
        this.cancelBtn.nativeElement.focus();
      }
    }, 10);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft':
      case 'ArrowRight':
        this.toggleFocus();
        break;
    }
  }
	private toggleFocus() {
    if (document.activeElement === this.cancelBtn?.nativeElement) {
      this.confirmBtn?.nativeElement?.focus();
    } else {
      this.cancelBtn?.nativeElement?.focus();
    }
  }

  onCancel(): void {
    this.confirmed.emit(false);
  }

  onConfirm(): void {
    this.confirmed.emit(true);
  }
}
