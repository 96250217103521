import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { GroupTravelQuickView } from "../models/group-travel-quick";
import { BaseHttpService } from "src/app/service/base.http.service";
import { TravelDestination } from "../../travels/models/travel-destination";
import { GroupTravelPlanQuickView } from "../models/group-travel-plan-quick";
import { GroupTravel, GroupTravelPlan, GroupTravelPlanToAddWithEntities, GroupTravelPreview } from "../models/group-travel";
import { ETuristFinalUpdate, ETuristSaleRecord } from "src/app/shared/models/etourist";
import { PassengerDTO } from "../models/passangers";

@Injectable({
  providedIn: "root",
})
export class GroupTravelsService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getGroupTravelData(id: number) {
    return this.http.get<GroupTravelPreview>(`${this.apiUrl}/api/GroupTravels/${id}`);
  }
  getGroupTravelForEdit(id: number) {
    return this.http.get<GroupTravel>(`${this.apiUrl}/api/GroupTravels/edit/${id}`);
  }

  getGroupTravelPlansForQuickView(id: number) {
    return this.http.get<GroupTravelPlan>(`${this.apiUrl}/api/GroupTravelPlans/${id}/quickview`);
  }

  getGroupTravelPlans(id: number) {
    return this.http.get<GroupTravelPlan>(`${this.apiUrl}/api/GroupTravelPlans/${id}`);
  }

  createGroupTravel(groupTravel: GroupTravel) {
    return this.http.post<GroupTravel>(`${this.apiUrl}/api/GroupTravels`, groupTravel);
  }

  updateGroupTravel(id: number, groupTravel: GroupTravel) {
    return this.http.put<GroupTravel>(`${this.apiUrl}/api/GroupTravels/${id}`, groupTravel);
  }

  createGroupTravelClone(id: number) {
    return this.http.post<GroupTravel>(`${this.apiUrl}/api/GroupTravels/clone/${id}`, {});
  }

  addGroupTravelPassanger(data: any) {
    return this.http.post<any>(`${this.apiUrl}/api/GroupTravelPlans/passangers-plan`, data);
  }
  updateGroupTravelPassanger(planId: number, data: GroupTravelPlanToAddWithEntities) {
    return this.http.put<any>(`${this.apiUrl}/api/GroupTravelPlans/plan/${planId}`, data);
  }

  getGroupTravels(): Observable<GroupTravel[]> {
    return this.http.get<GroupTravel[]>(`${this.apiUrl}/api/GroupTravels`);
  }
  deleteGroupTravel(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/GroupTravels/${id}`, { responseType: "text" });
  }
  getGroupTravelDestinations(id: number) {
    return this.http.get<any>(`${this.apiUrl}/api/GroupTravelDestinations/${id}`);
  }
  updateTravelDestination(id: number, travelDestination: TravelDestination): Observable<TravelDestination> {
    return this.http.put<TravelDestination>(`${this.apiUrl}/api/GroupTravelDestinations/${id}`, travelDestination);
  }
  deleteGroupTravelDestination(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/GroupTravelDestinations/${id}`, { responseType: "text" });
  }
  createTravelDestination(travelDestination: TravelDestination): Observable<TravelDestination> {
    return this.http.post<TravelDestination>(`${this.apiUrl}/api/GroupTravelDestinations`, travelDestination);
  }
  getGroupTravelsQuickView(id: number | undefined): Observable<GroupTravelQuickView> {
    return this.http.get<GroupTravelQuickView>(`${this.apiUrl}/api/GroupTravels/quick-view/${id}`);
  }
  getGroupTravelsPlanQuickView(id: number | undefined): Observable<GroupTravelPlanQuickView> {
    return this.http.get<GroupTravelPlanQuickView>(`${this.apiUrl}/api/GroupTravelPlans/quick-view/${id}`);
  }
  getGroupTravelPassangersByPlan(groupTravelPlanId?: number): Observable<GroupTravelPlan[]> {
    return this.http.get<GroupTravelPlan[]>(`${this.apiUrl}/api/GroupTravelPlans/passangers-by-plan/${groupTravelPlanId}`);
  }
  deleteGroupTravelPassanger(id: number | undefined) {
    return this.http.delete(`${this.apiUrl}/api/GroupTravelPlans/${id}`);
  }
  addEvidentionToETurist(jid: number, id: number, nbs: number, accountingData: ETuristSaleRecord): Observable<any> {
    const url = `${this.apiUrl}/api/GroupTravelPlans/e-turist/${jid}/${id}`;
    return this.http.post(
      url,
      {
        jIDNumber: accountingData.jIDNumber.uniqueIdentifier,
        amount: accountingData.amount,
        nBSCourse: nbs,
        currencyTypeId: accountingData.currencyTypeId.code,
        transportTypeId: accountingData.transportTypeId.code,
        accomodationTypeId: accountingData.accomodationTypeId.code,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  }
  updateGroupTravelPlanTuristData(id: number, accountingData: ETuristFinalUpdate): Observable<any> {
    const url = `${this.apiUrl}/api/GroupTravelPlans/e-turist/${id}/update`;
    return this.http.post(url, {
      ...accountingData,
    });
  }

  addGroupTravelSaleToETurist(jid: number, travelSales: { id: number; exchangeRate: string; accountingData: ETuristSaleRecord }[]): Observable<any> {
    const url = `${this.apiUrl}/api/GroupTravelPlans/e-turist/add-travel-sale/${jid}`;
    const requestBody = {
      travelSales: travelSales.map((sale) => ({
        id: sale.id,
        accountingData: {
          jIDNumber: sale.accountingData.jIDNumber.uniqueIdentifier,
          amount: sale.accountingData.amount,
          nBSCourse: sale.exchangeRate,
          currencyTypeId: sale.accountingData.currencyTypeId.code,
          transportTypeId: sale.accountingData.transportTypeId.code,
          accomodationTypeId: sale.accountingData.accomodationTypeId.code,
        },
      })),
    };

    return this.http.post(url, requestBody, {
      headers: { "Content-Type": "application/json" },
    });
  }
  checkExistingPassengers(id: number, passengers: PassengerDTO[]): Observable<PassengerDTO[]> {
    return this.http.post<PassengerDTO[]>(`${this.apiUrl}/api/GroupTravels/check-existing-passenger/${id}`, passengers);
  }
}
